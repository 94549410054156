import { Modal } from '@mantine/core';

export default function ModalDetailFotoTimesheet({ showImage, setShowImage, image }) {
  return (
    <Modal
      centered
      opened={showImage}
      onClose={() => setShowImage(false)}
      className="overflow-auto bg-transparent">
      <img
        src={image}
        alt=""
        className="w-full h-96 object-contain rounded-t-lg flex items-center justify-center"
      />
    </Modal>
  );
}
