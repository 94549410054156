import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import moment from 'moment';
import { Modal } from '@mantine/core';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';

export default function ModalDetailIzin({
  kode,
  getDataIzin,
  openModalDetail,
  setOpenModalDetail
}) {
  const [dataIzin, setDataIzin] = useState({});
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDetailIzin = async () => {
      await api.izinDetail(kode).then((res) => {
        setDataIzin(res.data.data[0]);
      });
    };
    getDetailIzin(kode);
  }, [kode, status]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      satpamizinkode: kode,
      satpamizinstatus: status
    };

    await api
      .updateIzin(formData)
      .then((res) => {
        setStatus('');
        Swal.fire('Good job!', 'Status berhasil diubah!', 'success');
        setLoading(false);
        getDataIzin();
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.message
        });
      });
  };

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Izin</div>
      <div className="flex justify-center">
        {dataIzin?.satpamizinfoto?.split('/data-izin/')[1]?.length > 0 ? (
          <img
            src={dataIzin.satpamizinfoto}
            alt=""
            className="w-full h-56 object-contain mb-5 rounded-xl"
          />
        ) : (
          <img
            src={require('../../images/logo.png')}
            alt=""
            className="w-full h-56 object-contain mb-5 rounded-xl"
          />
        )}
      </div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Jenis</div>
            <div className="w-full">{dataIzin?.satpamizinjenis}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Personil</div>
            <div className="w-full">{dataIzin?.satpamnamalengkap}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Pengajuan</div>
            <div className="w-full">
              {moment(dataIzin?.satpamizintanggal).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Jadwal</div>
            <div className="w-full">
              {moment(dataIzin?.satpamjadwalmasuktanggal).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Keterangan</div>
            <div className="w-full">{dataIzin?.satpamizinketerangan || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Status</div>
            <div className="w-full flex flex-wrap md:flex-nowrap gap-2">
              {dataIzin?.satpamizinstatus === '0' ? (
                <div className="px-4 border-secondary border-2 text-sm text-secondary btnStatus rounded flex justify-center items-center md:w-auto w-full">
                  Belum disetujui
                </div>
              ) : dataIzin?.satpamizinstatus === '1' ? (
                <div className="px-4 border-primary text-sm border-2 text-primary btnStatus rounded flex justify-center items-center">
                  Disetujui
                </div>
              ) : (
                <div className="px-4 border-errorText text-sm border-2 text-errorText btnStatus rounded flex justify-center items-center">
                  Ditolak
                </div>
              )}
              <form
                onSubmit={handleSubmit}
                className="w-full md:w-auto flex flex-wrap md:flex-nowrap gap-2">
                <select
                  className="w-full inputForm py-1"
                  onChange={(e) => setStatus(e.target.value)}>
                  <option value="">Ubah status</option>
                  {dataIzin?.satpamizinstatus === '0' ? (
                    <>
                      <option value="1">Setujui</option>
                      <option value="2">Tolak</option>
                    </>
                  ) : dataIzin?.satpamizinstatus === '1' ? (
                    <option value="2">Tolak</option>
                  ) : (
                    <option value="1">Setujui</option>
                  )}
                </select>
                {status !== '' && (
                  <button
                    type="submit"
                    className="w-full py-1 px-5 rounded-md text-sm text-gray-50 bg-dark hover:opacity-80">
                    {loading ? (
                      <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
                    ) : (
                      'Ubah'
                    )}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
