import moment from 'moment';
import { Modal } from '@mantine/core';
import { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import LoadingModal from '../general/LoadingModal';

export default function ModalKeadaanDetail({ kode, openModalDetail, setOpenModalDetail }) {
  const [dataKeadaan, setDataKeadaan] = useState({});
  const [dataSatpam, setDataSatpam] = useState([]);

  useEffect(() => {
    if (kode !== '') {
      const getKeadaanByKode = async () => {
        await api.keadaanDetail(kode).then((res) => {
          setDataKeadaan(res.data.keadaan[0]);
          setDataSatpam(res.data.satpam);
        });
      };
      getKeadaanByKode();
    }
    return setDataKeadaan({});
  }, [kode]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Keadaan</div>
      {dataKeadaan?.satpamkeadaantanggal ? (
        <div>
          <div className="text-dark scrollbar-thin px-5">
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Tanggal</div>
              <div className="w-full">
                {dataKeadaan?.satpamkeadaantanggal !== '' &&
                  dataKeadaan?.satpamkeadaantanggal !== null &&
                  moment(dataKeadaan?.satpamkeadaantanggal).format('DD MMM YYYY HH:mm')}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Pesan</div>
              <div className="w-full">{dataKeadaan?.satpamkeadaanpesan || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Latitude</div>
              <div className="w-full">{dataKeadaan?.satpamkeadaanlatitude || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Longitude</div>
              <div className="w-full">{dataKeadaan?.satpamkeadaanlongitude || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Alamat</div>
              <div className="w-full">{dataKeadaan?.satpamkeadaanalamat || '-'}</div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Dikirim ke</div>
              <div className="w-full">
                <div className="flex flex-col">
                  {dataSatpam?.length > 0 && dataSatpam.map((personil) => <div key={personil.satpamkode}>- {personil.satpamnamalengkap}</div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingModal />
      )}
    </Modal>
  );
}
