import React, { memo, useState } from 'react';
import ModalTimeSheetDetail from './ModalTimeSheetDetail';
import Select from 'react-select';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import ModalDetailFotoTimesheet from './ModalDetailFotoTimesheet';

const TableTimesheet = React.lazy(() => import('./TableTimesheet'));

const TimesheetSatpam = memo(
  ({
    loading,
    dataJadwal,
    headerDate,
    date,
    setDate,
    satpamDataOptions,
    satpamKode,
    setSatpamKode
  }) => {
    const [openModalDetail, setOpenModalDetail] = useState(false);
    const [image, setImage] = useState('second');
    const [showImage, setShowImage] = useState(false);
    const [kode, setKode] = useState('');
    const [formValue, setFormValue] = useState({
      startDate: date.startDate || moment().format('YYYY-MM-DD'),
      endDate: date.endDate || moment().format('YYYY-MM-DD')
    });

    const handleChangeDate = (e) => {
      const { name, value } = e.target;
      name === 'startDate' &&
        setFormValue({
          startDate: moment(value).format('YYYY-MM-DD'),
          endDate: moment(value).endOf('month').format('YYYY-MM-DD')
        });

      name === 'endDate' &&
        setFormValue((prev) => ({ ...prev, endDate: moment(value).format('YYYY-MM-DD') }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      setDate({
        startDate: formValue.startDate,
        endDate: formValue.endDate
      });
    };

    const handleOpenModalDetail = (kodejadwal) => {
      setKode(kodejadwal);
      setOpenModalDetail(true);
    };

    return (
      <div className="p-5 flex flex-col max-h-screen">
        <div className="mb-2 flex flex-wrap justify-center items-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold">M : </div>
            <div className="text-sm font-semibold text-gray-700">Masuk</div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold">I : </div>
            <div className="text-sm font-semibold text-gray-700">Izin</div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold">S : </div>
            <div className="text-sm font-semibold text-gray-700">Sakit</div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold">O : </div>
            <div className="text-sm font-semibold text-gray-700">OFF</div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold">A : </div>
            <div className="text-sm font-semibold text-gray-700">Alfa</div>
          </div>
        </div>
        <div className="mb-4 flex flex-wrap justify-center items-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="w-5">
              <div className="bg-emerald-600 rounded p-1 w-5 h-5"></div>
            </div>
            <div className="text-sm font-semibold text-gray-700">Belum Absen</div>
          </div>
          <div className="flex gap-2">
            <div className="w-5">
              <div className="bg-cyan-800 rounded p-1 w-5 h-5"></div>
            </div>
            <div className="text-sm font-semibold text-gray-700">Sudah Absen</div>
          </div>
          <div className="flex gap-2">
            <div className="w-5">
              <div className="bg-red-900 rounded p-1 w-5 h-5"></div>
            </div>
            <div className="text-sm font-semibold text-gray-700">Alfa</div>
          </div>
          <div className="flex gap-2">
            <div className="w-5">
              <div className="bg-yellow-600 rounded p-1 w-5 h-5"></div>
            </div>
            <div className="text-sm font-semibold text-gray-700">Izin</div>
          </div>
          <div className="flex gap-2">
            <div className="w-5">
              <div className="bg-red-500 rounded p-1 w-5 h-5"></div>
            </div>
            <div className="text-sm font-semibold text-gray-700">Sakit</div>
          </div>
          <div className="flex gap-2">
            <div className="w-5">
              <div className="bg-slate-400 rounded p-1 w-5 h-5"></div>
            </div>
            <div className="text-sm font-semibold text-gray-700">OFF</div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2">
          <div className="flex md:flex-row flex-col items-center md:mt-0 mt-2 gap-2">
            <div className="w-full md:w-1/2">
              <div className="font-semibold text-xs mb-2">Filter Personil</div>
              <Select
                options={satpamDataOptions}
                className="w-full text-xs cursor-pointer z-20"
                placeholder="Pilih personil"
                isSearchable={true}
                value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                defaultValue={satpamKode}
                required
                onChange={(e) => setSatpamKode(e.value)}
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="font-semibold text-xs mt-2">Filter Tanggal</div>
              <form
                onSubmit={handleSubmit}
                className="flex md:flex-row flex-col justify-center items-center">
                <div className="w-full flex flex-col justify-center items-center">
                  <input
                    type="date"
                    id="startDate"
                    className="text-xs cursor-pointer inputForm w-full"
                    name="startDate"
                    value={formValue.startDate}
                    onChange={handleChangeDate}
                  />
                </div>
                <div className="mx-2 my-1 text-xs text-center">to</div>
                <div className="w-full flex flex-col justify-center items-center">
                  <input
                    type="date"
                    id="endDate"
                    className="text-xs cursor-pointer inputForm w-full"
                    name="endDate"
                    value={formValue.endDate}
                    min={formValue.startDate}
                    max={moment(formValue.startDate).add(30, 'day').format('YYYY-MM-DD')}
                    onChange={handleChangeDate}
                  />
                </div>
                <button
                  type="submit"
                  className={`md:ml-2 ml-0 btnAksi ${
                    moment(formValue.startDate).diff(moment(formValue.endDate)) > 0 && 'bg-gray-400'
                  } w-full flex justify-center text-xs py-2 px-5 rounded`}
                  disabled={
                    moment(formValue.startDate).diff(moment(formValue.endDate)) > 0 ? true : false
                  }>
                  Filter
                </button>
              </form>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="flex min-h-screen-75 justify-center items-center">
            <ClipLoader color="black" loading size={40} speedMultiplier={0.7} />
          </div>
        ) : (
          <>
            <React.Suspense
              fallback={
                <div className="flex min-h-screen-75 justify-center items-center">
                  <ClipLoader color="black" loading size={40} speedMultiplier={0.7} />
                </div>
              }>
              <div className="overflow-auto scrollbar-thin scrollbar-thumb-customGray scrollbar-track-gray-100">
                <div className="w-full h-[75vh]">
                  <TableTimesheet
                    headerDate={headerDate}
                    dataJadwal={dataJadwal}
                    handleOpenModalDetail={handleOpenModalDetail}
                    setImage={setImage}
                    setShowImage={setShowImage}
                  />
                </div>
              </div>
            </React.Suspense>

            <ModalTimeSheetDetail
              kode={kode}
              openModalDetail={openModalDetail}
              setOpenModalDetail={setOpenModalDetail}
            />

            <ModalDetailFotoTimesheet
              showImage={showImage}
              setShowImage={setShowImage}
              image={image}
            />
          </>
        )}
      </div>
    );
  }
);

export default TimesheetSatpam;
