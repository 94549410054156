import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import useImagePreview from '../../hooks/useImagePreview';
import { yupResolver } from '@hookform/resolvers/yup';
import { temuanUpdateSchema } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import { Collapse, Switch } from '@mantine/core';

export default function TemuanUpdate() {
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [kategoriOptions, setKategoriOptions] = useState([]);
  const [showInputAnalisis, setShowInputAnalisis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [satpamKode, setSatpamKode] = useState('');
  const [kategoriKode, setKategoriKode] = useState('');
  const [tanggalMelaporkan, setTanggalMelaporkan] = useState(new Date());
  const [tanggalAktifitas, setTanggalAktifitas] = useState(new Date());
  const [defaultValues, setDefaultValues] = useState({});

  const navigate = useNavigate();
  const { kode } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(temuanUpdateSchema),
    defaultValues: defaultValues
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getTemuanByKode = async () => {
      await api.temuanByKode({ satpamtemuankode: kode }).then((res) => {
        if (res.data.Data.length > 0) {
          const defaultValue = {
            satpamtemuanuraian: res.data.Data[0].satpamtemuanuraian,
            satpamtemuantindakan: res.data.Data[0].satpamtemuantindakan,
            satpamtemuanhasil: res.data.Data[0].satpamtemuanhasil,
            satpamtemuanpotensi:
              res.data.Data[0].satpamtemuanpotensi !== 'null' &&
              res.data.Data[0].satpamtemuanpotensi,
            satpamtemuananalisis:
              res.data.Data[0].satpamtemuananalisis !== 'null' &&
              res.data.Data[0].satpamtemuananalisis,
            satpamtemuankesimpulan:
              res.data.Data[0].satpamtemuankesimpulan !== 'null' &&
              res.data.Data[0].satpamtemuankesimpulan,
            satpamtemuanrekomendasi:
              res.data.Data[0].satpamtemuanrekomendasi !== 'null' &&
              res.data.Data[0].satpamtemuanrekomendasi,
            satpamtemuanalamat: res.data.Data[0].satpamtemuanalamat,
            satpamtemuanstatus: res.data.Data[0].satpamtemuanstatus,
            satpamkegiatlatitude: res.data.Data[0].satpamkegiatlatitude,
            satpamkegiatlongitude: res.data.Data[0].satpamkegiatlongitude,
            satpamtemuanalamatpatokan: res.data.Data[0].satpamtemuanalamatpatokan,
            satpamtemuanketeranganfotosatu:
              res.data.Data[0].satpamtemuanketeranganfotosatu !== 'null'
                ? res.data.Data[0].satpamtemuanketeranganfotosatu
                : '-',
            satpamtemuanketeranganfotodua:
              res.data.Data[0].satpamtemuanketeranganfotodua !== 'null'
                ? res.data.Data[0].satpamtemuanketeranganfotodua
                : '-',
            satpamtemuanketeranganfototiga:
              res.data.Data[0].satpamtemuanketeranganfototiga !== 'null'
                ? res.data.Data[0].satpamtemuanketeranganfototiga
                : '-',
            satpamtemuanketeranganfotoempat:
              res.data.Data[0].satpamtemuanketeranganfotoempat !== 'null'
                ? res.data.Data[0].satpamtemuanketeranganfotoempat
                : '-',
            satpamtemuanketeranganfotolima:
              res.data.Data[0].satpamtemuanketeranganfotolima !== 'null'
                ? res.data.Data[0].satpamtemuanketeranganfotolima
                : '-',
            satpamtemuanfotosatu:
              res.data.Data[0].satpamtemuanfotosatu.split('/data-temuan/')[1].length > 0 &&
              res.data.Data[0].satpamtemuanfotosatu,
            satpamtemuanfotodua:
              res.data.Data[0].satpamtemuanfotodua.split('/data-temuan/')[1].length > 0 &&
              res.data.Data[0].satpamtemuanfotodua,
            satpamtemuanfototiga:
              res.data.Data[0].satpamtemuanfototiga.split('/data-temuan/')[1].length > 0 &&
              res.data.Data[0].satpamtemuanfototiga,
            satpamtemuanfotoempat:
              res.data.Data[0].satpamtemuanfotoempat.split('/data-temuan/')[1].length > 0 &&
              res.data.Data[0].satpamtemuanfotoempat,
            satpamtemuanfotolima:
              res.data.Data[0].satpamtemuanfotolima.split('/data-temuan/')[1].length > 0 &&
              res.data.Data[0].satpamtemuanfotolima
          };
          setDefaultValues(defaultValue);
          reset(defaultValue);
          setSatpamKode(res.data.Data[0].satpamtemuansatpamkode);
          setKategoriKode(res.data.Data[0].satpamtemuankategorikode);
          setTanggalMelaporkan(new Date(res.data.Data[0].satpamtemuantanggalmelaporkan));
          setTanggalAktifitas(new Date(res.data.Data[0].satpamtemuantanggalaktifitas));
        } else {
          setDefaultValues({});
        }
      });
    };
    getTemuanByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const [fileDataURL] = useImagePreview(watch('satpamtemuanfotosatu'));
  const [fileDataURL2] = useImagePreview(watch('satpamtemuanfotodua'));
  const [fileDataURL3] = useImagePreview(watch('satpamtemuanfototiga'));
  const [fileDataURL4] = useImagePreview(watch('satpamtemuanfotoempat'));
  const [fileDataURL5] = useImagePreview(watch('satpamtemuanfotolima'));

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((response) => {
        response.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [
            ...prev,
            { value: data.satpamkode, label: data.satpamnamalengkap }
          ]);
        });
      });
    };
    const getDataKategori = async () => {
      await api.temuanKategori().then((res) => {
        res.data.Data.map((data) => {
          return setKategoriOptions((prev) => [
            ...prev,
            {
              value: data.satpamtemuankategorikode,
              label: data.satpamtemuankategorinama
            }
          ]);
        });
      });
    };
    getDataSatpam();
    getDataKategori();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('satpamtemuankode', kode);
    formData.append('satpamtemuansatpamkode', satpamKode);
    formData.append('satpamtemuankategori', kategoriKode);
    formData.append(
      'satpamtemuantanggalmelaporkan',
      moment(tanggalMelaporkan).format('YYYY-MM-DDTHH:mm')
    );
    formData.append(
      'satpamtemuantanggalaktifitas',
      moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm')
    );

    if (data.satpamtemuanfotosatu.length > 0 && typeof data.satpamtemuanfotosatu !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotosatu[0], compressOptions);
      formData.append('satpamtemuanfotosatu', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfotodua.length > 0 && typeof data.satpamtemuanfotodua !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotodua[0], compressOptions);
      formData.append('satpamtemuanfotodua', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfototiga.length > 0 && typeof data.satpamtemuanfototiga !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfototiga[0], compressOptions);
      formData.append('satpamtemuanfototiga', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfotoempat.length > 0 && typeof data.satpamtemuanfotoempat !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotoempat[0], compressOptions);
      formData.append('satpamtemuanfotoempat', compressedFile, compressedFile.name);
    }

    if (data.satpamtemuanfotolima.length > 0 && typeof data.satpamtemuanfotolima !== 'string') {
      const compressedFile = await imageCompression(data.satpamtemuanfotolima[0], compressOptions);
      formData.append('satpamtemuanfotolima', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .temuanUpdate(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/temuan');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">
          Edit temuan
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      name="satpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Uraian temuan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      className={`w-full ${
                        errors.satpamtemuanuraian ? 'invalidForm' : 'inputForm'
                      }`}
                      rows={4}
                      {...register('satpamtemuanuraian')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamtemuanuraian?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Uraian tindakan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      className={`w-full ${
                        errors.satpamtemuantindakan ? 'invalidForm' : 'inputForm'
                      }`}
                      rows={3}
                      {...register('satpamtemuantindakan')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamtemuantindakan?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Hasil tindakan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      className={`w-full ${errors.satpamtemuanhasil ? 'invalidForm' : 'inputForm'}`}
                      rows={4}
                      {...register('satpamtemuanhasil')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamtemuanhasil?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Kategori</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={kategoriOptions}
                      className="w-full"
                      placeholder="Pilih kategori"
                      isSearchable={true}
                      value={kategoriOptions.filter((option) => option.value === kategoriKode)}
                      onChange={(e) => setKategoriKode(e.value)}
                      name="kategorikode"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Patokan lokasi</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className="w-full inputForm"
                      {...register('satpamtemuanalamatpatokan')}
                    />
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${
                        errors.satpamtemuanalamat ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamtemuanalamat')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamtemuanalamat?.message}</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block font-semibold mb-1 md:mb-0">
                      Tanggal temuan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalAktifitas}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAktifitas(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Tanggal melaporkan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMelaporkan}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMelaporkan(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Status temuan</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full flex flex-col font-normal ${
                        errors.satpamtemuanstatus ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamtemuanstatus')}>
                      <option disabled value="">
                        Pilih status temuan
                      </option>
                      <option value={0}>Open</option>
                      <option value={1}>Close</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.satpamtemuanstatus?.message}</p>
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Keterangan</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full text-sm ${
                        errors.satpamprosesketerangan ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamprosesketerangan')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpamprosesketerangan?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-gray-50 p-5 rounded-lg shadowAll">
            <div className="mx-auto">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('satpamtemuanfotosatu')}
                            accept="image/*"
                          />
                        </label>
                        <p className="text-errorText text-xs">
                          {errors.satpamtemuanfotosatu?.message}
                        </p>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 1</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('satpamtemuanketeranganfotosatu')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL2 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL2}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('satpamtemuanfotodua')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 2</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('satpamtemuanketeranganfotodua')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL3 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL3}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('satpamtemuanfototiga')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 3</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('satpamtemuanketeranganfototiga')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL4 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL4}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('satpamtemuanfotoempat')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 4</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('satpamtemuanketeranganfotoempat')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL5 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL5}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('satpamtemuanfotolima')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 5</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('satpamtemuanketeranganfotolima')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-gray-50 p-5 rounded-lg shadowAll">
            <div className="flex md:flex-nowrap flex-wrap justify-between items-center mb-4">
              <div className="font-semibold">Masukkan analisis temuan</div>
              <div className="-mt-3">
                <Switch
                  className="cursor-pointer"
                  color="blue"
                  label="Tampilkan"
                  labelPosition="left"
                  onClick={() => setShowInputAnalisis((prev) => !prev)}
                />
              </div>
            </div>
            <Collapse in={showInputAnalisis}>
              <div className="flex md:flex-row flex-col gap-4">
                <div className="md:w-1/2 w-full">
                  <div className="md:flex mb-3">
                    <div className="md:w-2/5">
                      <label className="block font-semibold mb-1 md:mb-0">Potensi temuan</label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea
                        className={`w-full ${
                          errors.satpamtemuanpotensi ? 'invalidForm' : 'inputForm'
                        }`}
                        rows={2}
                        {...register('satpamtemuanpotensi')}
                      />
                      <p className="text-errorText text-xs">
                        {errors.satpamtemuanpotensi?.message}
                      </p>
                    </div>
                  </div>
                  <div className="md:flex mb-3">
                    <div className="md:w-2/5">
                      <label className="block font-semibold mb-1 md:mb-0">Analisis temuan</label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea
                        className={`w-full ${
                          errors.satpamtemuananalisis ? 'invalidForm' : 'inputForm'
                        }`}
                        rows={2}
                        {...register('satpamtemuananalisis')}
                      />
                      <p className="text-errorText text-xs">
                        {errors.satpamtemuananalisis?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:w-1/2 w-full">
                  <div className="md:flex mb-3">
                    <div className="md:w-2/5">
                      <label className="block font-semibold mb-1 md:mb-0">Kesimpulan temuan</label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea
                        className={`w-full ${
                          errors.satpamtemuankesimpulan ? 'invalidForm' : 'inputForm'
                        }`}
                        rows={2}
                        {...register('satpamtemuankesimpulan')}
                      />
                      <p className="text-errorText text-xs">
                        {errors.satpamtemuankesimpulan?.message}
                      </p>
                    </div>
                  </div>
                  <div className="md:flex">
                    <div className="md:w-2/5">
                      <label className="block font-semibold mb-1 md:mb-0">
                        Saran dan Rekomendasi
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      <textarea
                        className={`w-full ${
                          errors.satpamtemuanrekomendasi ? 'invalidForm' : 'inputForm'
                        }`}
                        rows={2}
                        {...register('satpamtemuanrekomendasi')}
                      />
                      <p className="text-errorText text-xs">
                        {errors.satpamtemuanrekomendasi?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/temuan">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
