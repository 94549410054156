import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';

export default function InputMultipleMail({ emails, setEmails }) {
  const [error, setError] = useState('');

  const addEmail = (emailValue) => {
    const trimmedEmail = emailValue.trim();
    if (trimmedEmail !== '' && !emails.includes(trimmedEmail)) {
      setEmails([...emails, trimmedEmail]);
    }
  };
  // Add or remove emails by using the key
  const handleEmails = (event) => {
    const emailValue = event.target.value.trim(); // Always trim the input value

    // Handle when 'Enter' or 'Space' is pressed and input is not empty
    if (event.keyCode === 32 && emailValue !== '') {
      addEmail(emailValue);
      event.target.value = ''; // Clear the input field after adding
    }
    // Handle backspace (delete last email)
    else if (event.keyCode === 8 && emails.length && event.target.value === '') {
      const emailsCopy = [...emails];
      emailsCopy.pop();
      event.preventDefault();
      setEmails(emailsCopy);
    }
    // Set error if there are no emails to delete
    else if (emails.length < 1 && event.keyCode === 8) {
      setError('There are no emails to delete.');
    }
  };
  // Remove emails by clicking the cross sign
  const removeEmails = (index) => {
    setEmails(emails.filter((_, i) => i !== index)); // Filter out the selected email
  };

  //To update the error after user changes something
  const handleError = () => {
    setError('');
  };

  return (
    <>
      <div className="w-full flex flex-wrap overflow-hidden border border-gray-300 rounded-md">
        {emails.map((email, index) => (
          <div className="list-none flex items-center px-2 mt-2 ml-2 border bg-primary rounded whitespace-nowrap text-gray-300" key={index}>
            <span className="pr-2">{email}</span>
            <i onClick={() => removeEmails(index)}>
              <MdClose className="cursor-pointer" />
            </i>
          </div>
        ))}
        <input
          type="text"
          className="w-full outline-none mx-2 m-2 px-2"
          onKeyDown={(event) => handleEmails(event)}
          onBlur={(event) => {
            const emailValue = event.target.value.trim();
            if (emailValue !== '') {
              addEmail(emailValue);
              event.target.value = ''; // Clear the input field
            }
          }}
          onChange={handleError}
          placeholder="Masukkan email dan tekan enter / spasi"
        />
      </div>
      <div className="text-errorText">{error}</div>
    </>
  );
}
