import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import moment from 'moment';
import Swal from 'sweetalert2';

export default function ModalListNotifikasiByPersonil({
  personil,
  openModalDetail,
  setOpenModalDetail
}) {
  const [dataNotifikasi, setDataNotifikasi] = useState([]);

  const getDataNotifikasiByPersonil = async (kodepersonil) => {
    await api
      .notifikasiData(kodepersonil)
      .then((res) => setDataNotifikasi(res.data.Data))
      .catch();
  };

  useEffect(() => {
    if (personil.satpamkode !== '') {
      getDataNotifikasiByPersonil(personil.satpamkode);
    }
  }, [personil]);

  const handleDelete = (kode) => {
    Swal.fire({
      text: 'Do you want to delete this notifikasi?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .notifikasiDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getDataNotifikasiByPersonil(personil.satpamkode);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  return (
    <Modal
      opened={openModalDetail}
      size="lg"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="text-center text-lg font-bold mb-1">Daftar Notifikasi</div>
        <div className="text-center text-md font-semibold mb-5">
          Personil : {personil?.satpamnamalengkap} - {personil?.satpamjabatan}
        </div>
        <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          <div className="block w-full max-h-[50vh]">
            <table className="table-fixed w-full text-sm text-dark">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th
                    scope="col"
                    className="w-[100px] py-2 px-2 border text-center whitespace-nowrap">
                    Action
                  </th>
                  <th
                    scope="col"
                    className="w-[150px] py-2 px-2 border text-center whitespace-nowrap">
                    Status
                  </th>
                  <th
                    scope="col"
                    className="w-[200px] py-2 px-2 border text-center whitespace-nowrap">
                    Pengirim
                  </th>
                  <th
                    scope="col"
                    className="w-[200px] py-2 px-2 border text-center whitespace-nowrap">
                    Judul
                  </th>
                  <th
                    scope="col"
                    className="w-[300px] py-2 px-2 border text-center whitespace-nowrap">
                    Isi
                  </th>
                  <th
                    scope="col"
                    className="w-[200px] py-2 px-2 border text-center whitespace-nowrap">
                    Header
                  </th>
                  <th
                    scope="col"
                    className="w-[150px] py-2 px-2 border text-center whitespace-nowrap">
                    Tanggal
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataNotifikasi?.length > 0 ? (
                  dataNotifikasi.map((notifikasi) => (
                    <tr key={notifikasi.SatpamNotifikasikode}>
                      <td className="py-2 px-2 border text-center whitespace-nowrap">
                        <div className="flex justify-center items-center gap-1">
                          <button
                            className="border-errorText border-2 text-errorText btnStatus px-2 rounded text-center"
                            onClick={() => handleDelete(notifikasi.SatpamNotifikasikode)}>
                            <i className="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                      <td className="py-2 px-2 border align-middle">
                        {notifikasi.satpamnotifikasistatus === '1' ? (
                          <div className="border-primary border-2 text-primary btnStatus block w-full rounded-full text-center">
                            Sudah dibaca
                          </div>
                        ) : (
                          <div className="border-secondary border-2 text-secondary btnStatus block w-full rounded-full text-center">
                            Belum dibaca
                          </div>
                        )}
                      </td>
                      <td className="py-2 px-2 border">
                        {notifikasi.satpamnotifikasipengirimnama || '-'}
                      </td>
                      <td className="py-2 px-2 border">
                        {notifikasi.satpamnotifikasijudul || '-'}
                      </td>
                      <td className="py-2 px-2 border">{notifikasi.satpamnotifikasiisi || '-'}</td>
                      <td className="py-2 px-2 border">
                        {notifikasi.satpamnotifikasiheader || '-'}
                      </td>
                      <td className="py-2 px-2 border">
                        {notifikasi.satpamnotifikasitanggal !== '' &&
                        notifikasi.satpamnotifikasitanggal !== null
                          ? moment(notifikasi.satpamnotifikasitanggal).format('DD MMM YYYY HH:mm')
                          : '-'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="py-2 px-2 border text-center">
                      Belum ada notifikasi
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
}
