import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
// Import files
import * as api from '../../redux/api';
import BtnAdd from '../../components/general/BtnAdd';
import Cards from '../../components/general/Cards';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import ModalKejadianDetail from '../../components/kejadian/ModalKejadianDetail';
import ReadMore from '../../components/general/ReadMore';
import CardDashboard from '../../components/dashboard/CardDashboard';
import LoadingPage from '../../components/general/LoadingPage';
import ShowModalImage from '../../components/general/ShowModalImage';
import ShowTable from '../../components/general/ShowTable';

export default function Kejadian({ isAdmin }) {
  // Get Profile
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [datas, setDatas] = useState(['']);
  const [showImage, setShowImage] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [kejadianDetailKode, setKejadianDetailKode] = useState('');
  const [kategori, setKategori] = useState('');
  const [subKategori, setSubKategori] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [tipeLaporan, setTipeLaporan] = useState('');
  const [kategoriCount, setKategoriCount] = useState(['']);
  const location = useLocation();
  const { perusahaanKode } = useSelector((state) => state.perusahaan);
  const [loading, setLoading] = useState(false);

  // Date
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  // Get data
  const getData = async (formDate) => {
    await api.kejadian(formDate).then((res) => {
      setData(res.data.Data);
      setDatas(res.data);
      setLoading(false);
    });
  };

  const getCount = async (formData) => {
    await api.countKejadian(formData).then((res) => {
      setKategoriCount(res.data.data);
    });
  };

  useEffect(() => {
    if (location?.state?.date) {
      setDate(location?.state?.date);
      setKategori(location?.state?.kategori);
      setTipeLaporan(location?.state?.tipeLaporan);
    }
  }, [location?.state]);

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori,
      kategori_detail: subKategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    setLoading(true);

    getData(formDate);
    getCount(formDate);
  }, [date, kategori, perusahaanKode, subKategori]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(
          ({
            satpamkejadiankode,
            satpamkejadiantanggalmelaporkan,
            satpamkejadiananalisis,
            satpamnamalengkap,
            satpamkejadianuraian,
            satpamkejadiantindakan,
            satpamkejadianhasil,
            satpamkejadianpelaku,
            satpamkejadiankorban,
            satpamkejadiansaksi,
            satpamkejadianpatokan,
            satpamkejadianalamat
          }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpamkejadiankode} ${satpamkejadiantanggalmelaporkan} ${satpamkejadiananalisis} ${satpamnamalengkap} ${satpamkejadianuraian} ${satpamkejadiantindakan} ${satpamkejadianhasil} ${satpamkejadianhasil} ${satpamkejadianpelaku} ${satpamkejadiankorban} ${satpamkejadiansaksi} ${satpamkejadianpatokan} ${satpamkejadianalamat}`
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          }
        )
      );
    }
  }, [data, debouncedQuery]);

  const handleImageData = ({
    satpamkejadianfotosatu,
    satpamkejadianketeranganfotosatu,
    satpamkejadianfotodua,
    satpamkejadianketeranganfotodua,
    satpamkejadianfototiga,
    satpamkejadianketeranganfototiga,
    satpamkejadianfotoempat,
    satpamkejadianketeranganfotoempat,
    satpamkejadianfotolima,
    satpamkejadianketeranganfotolima
  }) => {
    setImageData([
      {
        image: satpamkejadianfotosatu?.split('/data-kejadian/')[1] && satpamkejadianfotosatu,
        keterangan: satpamkejadianketeranganfotosatu
      },
      {
        image: satpamkejadianfotodua?.split('/data-kejadian/')[1] && satpamkejadianfotodua,
        keterangan: satpamkejadianketeranganfotodua
      },
      {
        image: satpamkejadianfototiga?.split('/data-kejadian/')[1] && satpamkejadianfototiga,
        keterangan: satpamkejadianketeranganfototiga
      },
      {
        image: satpamkejadianfotoempat?.split('/data-kejadian/')[1] && satpamkejadianfotoempat,
        keterangan: satpamkejadianketeranganfotoempat
      },
      {
        image: satpamkejadianfotolima?.split('/data-kejadian/')[1] && satpamkejadianfotolima,
        keterangan: satpamkejadianketeranganfotolima
      }
    ]);
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (kejadiankode) => {
    setKejadianDetailKode(kejadiankode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori,
      kategori_detail: subKategori
    };

    setShowPopover('');

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    Swal.fire({
      text: 'Do you want to delete this kejadian?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .kejadianDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData(formDate);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkejadiankode',
      width: 100,
      render: ({ satpamkejadiankode }) => (
        <div className="flex items-center justify-center gap-1">
          {/* Button Aksi */}
          <Popover
            opened={showPopover === satpamkejadiankode}
            onClick={() => setShowPopover(showPopover ? '' : satpamkejadiankode)}
            width={110}
            position="right"
            withArrow
            shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div className="popOverItem" onClick={() => handleOpenModalDetail(satpamkejadiankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/kejadian-printperkej/${satpamkejadiankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
              {adminType === 'Admin Project' && (
                <>
                  <Link to={`/kejadian/update/${satpamkejadiankode}`}>
                    <div className="popOverItem">
                      <i className="fa-solid fa-pen"></i> Edit
                    </div>
                  </Link>
                  <div className="popOverItem" onClick={() => handleDelete({ kode: satpamkejadiankode })}>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                </>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto kejadian',
      accessor: 'satpamkejadianstatus',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({
        satpamkejadianfotosatu,
        satpamkejadianketeranganfotosatu,
        satpamkejadianfotodua,
        satpamkejadianketeranganfotodua,
        satpamkejadianfototiga,
        satpamkejadianketeranganfototiga,
        satpamkejadianfotoempat,
        satpamkejadianketeranganfotoempat,
        satpamkejadianfotolima,
        satpamkejadianketeranganfotolima
      }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() =>
            handleImageData({
              satpamkejadianfotosatu,
              satpamkejadianketeranganfotosatu,
              satpamkejadianfotodua,
              satpamkejadianketeranganfotodua,
              satpamkejadianfototiga,
              satpamkejadianketeranganfototiga,
              satpamkejadianfotoempat,
              satpamkejadianketeranganfotoempat,
              satpamkejadianfotolima,
              satpamkejadianketeranganfotolima
            })
          }>
          <img
            src={
              satpamkejadianfotosatu?.split('/data-kejadian/')[1]
                ? satpamkejadianfotosatu
                : satpamkejadianfotodua?.split('/data-kejadian/')[1]
                ? satpamkejadianfotodua
                : satpamkejadianfototiga?.split('/data-kejadian/')[1]
                ? satpamkejadianfototiga
                : satpamkejadianfotoempat?.split('/data-kejadian/')[1]
                ? satpamkejadianfotoempat
                : satpamkejadianfotolima?.split('/data-kejadian/')[1]
                ? satpamkejadianfotolima
                : ''
            }
            alt=""
            className="h-full object-contain rounded-lg"
          />
        </div>
      )
    },
    {
      title: 'Status, Kode, dan Tanggal',
      accessor: 'satpamkejadiantanggalmelaporkan',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadiankode, satpamkejadiantanggalmelaporkan, satpamkejadianstatus }) => (
        <div className="py-0 my-0">
          <div className="py-0 my-0">{satpamkejadiankode} &nbsp;</div>
          <div className="py-0 my-0">{satpamkejadiantanggalmelaporkan !== undefined && moment(satpamkejadiantanggalmelaporkan).format('DD MMM YYYY HH:mm')}</div>
          {Number(satpamkejadianstatus) === 0 ? (
            <div className="border-green-700 border-2 text-sm text-green-700 btnStatus block w-full rounded-full text-center mb-2">Open</div>
          ) : (
            <div className="border-red-700 text-sm border-2 text-red-700 btnStatus block w-full rounded-full text-center mb-2">Closed</div>
          )}
        </div>
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamkejadiankategori',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadiankategori }) => <div className="py-0 my-0">{satpamkejadiankategori !== null ? satpamkejadiankategori : 'Belum ada kategori'}</div>
    },
    {
      title: 'Analisis',
      accessor: 'satpamkejadiananalisis',
      width: 300,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadiananalisis }) => <div className="py-0 my-0">{satpamkejadiananalisis !== null ? satpamkejadiananalisis : 'Belum ada analisis'}</div>
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Uraian Kejadian',
      accessor: 'satpamkejadianuraian',
      width: 400,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadianuraian }) => <>{satpamkejadianuraian !== '' && <ReadMore>{satpamkejadianuraian}</ReadMore>}</>
    },
    {
      title: 'Uraian Tindakan',
      accessor: 'satpamkejadiantindakan',
      width: 400,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadiantindakan }) => <>{satpamkejadiantindakan !== '' && <ReadMore>{satpamkejadiantindakan}</ReadMore>}</>
    },
    {
      title: 'Hasil Tindakan',
      accessor: 'satpamkejadianhasil',
      width: 400,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadianhasil }) => <>{satpamkejadianhasil !== '' && <ReadMore>{satpamkejadianhasil}</ReadMore>}</>
    },
    {
      title: 'Pelaku',
      accessor: 'satpamkejadianpelaku',
      width: 125,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Korban',
      accessor: 'satpamkejadiankorban',
      width: 125,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Saksi',
      accessor: 'satpamkejadiansaksi',
      width: 125,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Patokan lokasi',
      accessor: 'satpamkejadianalamatpatokan',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Alamat',
      accessor: 'satpamkejadianalamat',
      width: 300,
      cellsStyle: {
        verticalAlign: 'top'
      }
    }
  ];

  if ((data && data[0] === '') || (kategoriCount && kategoriCount[0] === '')) {
    return <LoadingPage />;
  }

  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title="Statistik Kejadian"
        desc="Berikut ini adalah jumlah kejadian yang terjadi"
        card1={{ title: 'Open', value: datas?.Total_Open || 0 }}
        card2={{ title: 'Close', value: datas?.Total_Close || 0 }}
        card3={{ title: 'Total', value: datas?.Total_Data || 0 }}
        loading={loading}
      />

      {/* Cards */}
      <div className="flex flex-wrap gap-5 text-white">
        {kategoriCount.map((data) => (
          <CardDashboard data={data} loading={loading} />
        ))}
      </div>

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            {!isAdmin && <FilterDate date={date} setDate={setDate} tipeLaporan={tipeLaporan} />}
            <FilterKategori kategori={kategori} setKategori={setKategori} subKategori={subKategori} setSubKategori={setSubKategori} page="kejadian" />
          </div>
          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-fit">
              <BtnAdd path="/kejadian/create" />

              <Link to={`/kejadian-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Show Table */}
        <ShowTable loading={loading} datas={datas} filterData={filterData} columnData={columnData} setQuery={setQuery} />
      </div>

      <div className="flex justify-center mt-5">
        <Link to="/">
          <button className="btnAksi bg-primary py-2 px-20">Dashboard</button>
        </Link>
      </div>

      {/* Modal Detail Kejadian */}
      <ModalKejadianDetail kode={kejadianDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

      {/* Modal Image */}
      <ShowModalImage showImage={showImage} setShowImage={setShowImage} imageData={imageData} />
    </div>
  );
}
