export function distanceFormat(number) {
  number = parseFloat(number);
  if (isNaN(number)) {
    return 'Invalid number';
  }

  if (number >= 1000) {
    return Math.round(number / 1000.0) + ' km';
  } else if (number >= 100) {
    return Math.round(number) + ' m';
  } else {
    return number.toFixed(1) + ' m';
  }
}
