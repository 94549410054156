import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api';

export const login = createAsyncThunk('auth/login', async ({ formValue, navigate }, { rejectWithValue }) => {
  try {
    const res = await api.login(formValue);
    navigate('/');
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data.error);
  }
});

export const logout = createAsyncThunk('auth/login', async ({ formValue, navigate }, { rejectWithValue }) => {
  try {
    const res = await api.logout(formValue);
    navigate('/login');

    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data.error);
  }
});

export const register = createAsyncThunk('auth/register', async ({ formValue, navigate }, { rejectWithValue }) => {
  try {
    const res = await api.register(formValue);
    navigate('/');
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    error: false,
    loading: false,
    errorMessage: ''
  },
  reducers: {
    setLogout: (state, action) => {
      localStorage.removeItem('profile');
      localStorage.removeItem('menuData');
      state.user = null;
      state.error = false;
      window.location.href = '/auth/login';
    }
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
      state.user = action.payload;
      state.error = false;
      state.loading = false;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    [register.pending]: (state, action) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
    },
    [register.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    }
  }
});

export const { setLogout } = authSlice.actions;
export default authSlice.reducer;
