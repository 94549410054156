import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
// Import files
import * as api from '../../redux/api';
import BtnAction from '../../components/general/BtnAction';
import BtnAdd from '../../components/general/BtnAdd';
import Cards from '../../components/general/Cards';
import FilterDate from '../../components/general/FilterDate';
import LoadingPage from '../../components/general/LoadingPage';
import ModalLaluLintasDetail from '../../components/lalu-lintas/ModalLaluLintasDetail';
import PhotoLaporan from '../../components/general/PhotoLaporan';
import ReadMore from '../../components/general/ReadMore';
import StatusLaporan from '../../components/general/StatusLaporan';
import ShowModalImage from '../../components/general/ShowModalImage';
import ShowTable from '../../components/general/ShowTable';

export default function LaluLintas({ isAdmin }) {
  // Data
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState(['']);
  const [imageData, setImageData] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [kegiatanDetailKode, setKegiatanDetailKode] = useState('');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [tipeLaporan, setTipeLaporan] = useState('');
  const location = useLocation();
  const { perusahaanKode } = useSelector((state) => state.perusahaan);

  // Date
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  // Get data
  const getData = async (formData) => {
    await api.laluLintas(formData).then((res) => {
      setData(res.data.data);
      setDatas(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (location?.state?.date) {
      setDate(location?.state?.date);
      setTipeLaporan(location?.state?.tipeLaporan);
    }
  }, [location?.state]);

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate
      // kategorikode: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    setLoading(true);
    getData(formDate);
  }, [date, perusahaanKode]);

  useEffect(() => {
    setFilterData(
      data.filter(({ tanggalmelaporkan, kode, satpamnamalengkap, uraian, keterangan, alamat }) => {
        if (
          debouncedQuery !== '' &&
          !`${tanggalmelaporkan} ${kode} ${satpamnamalengkap} ${uraian} ${keterangan} ${alamat}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (kegiatanKode) => {
    setKegiatanDetailKode(kegiatanKode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate
    };

    setShowPopover('');

    Swal.fire({
      text: 'Apakah anda yakin ingin menghapus laporan ini?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .laluLintasDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData(formDate);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'kode',
      width: 100,
      render: ({ kode, id }) => (
        <BtnAction
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          handleOpenModalDetail={handleOpenModalDetail}
          handleDelete={handleDelete}
          data={kode}
          id={id}
          page="/lalu-lintas"
        />
      )
    },
    {
      title: 'Foto Kegiatan',
      accessor: 'fotosatu',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ fotosatu, keteranganfotosatu, fotodua, keteranganfotodua, fototiga, keteranganfototiga, fotoempat, keteranganfotoempat, fotolima, keteranganfotolima }) => (
        <PhotoLaporan
          page="/data-lalulintas/"
          foto1={fotosatu}
          foto2={fotodua}
          foto3={fototiga}
          foto4={fotoempat}
          foto5={fotolima}
          ket1={keteranganfotosatu}
          ket2={keteranganfotodua}
          ket3={keteranganfototiga}
          ket4={keteranganfotoempat}
          ket5={keteranganfotolima}
          showImage={showImage}
          setShowImage={setShowImage}
          setImageData={setImageData}
        />
      )
    },
    {
      title: 'Tanggal, Kode, dan Status',
      accessor: 'tanggalmelaporkan,',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ tanggalmelaporkan, kode, status }) => <StatusLaporan kode={kode} tanggal={tanggalmelaporkan} status={status} />
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Uraian',
      accessor: 'uraian',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ uraian }) => <>{uraian !== null && <ReadMore>{uraian}</ReadMore>}</>
    },
    {
      title: 'Keterangan',
      accessor: 'keterangan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ keterangan }) => <>{keterangan !== null && <ReadMore>{keterangan}</ReadMore>}</>
    },
    {
      title: 'Alamat',
      accessor: 'alamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`} onClick={() => showPopover && setShowPopover('')}>
      {/* Cards */}
      <Cards
        title="Statistik Laporan"
        desc="Berikut ini adalah jumlah laporan yang telah dilakukan"
        card1={{ title: 'Verifikasi', value: datas?.sudah_verifikasi || 0 }}
        card2={{
          title: 'Belum Verifikasi',
          value: datas?.belum_verifikasi || 0
        }}
        card3={{ title: 'Total Data', value: datas?.jumlah_data || 0 }}
        loading={loading}
      />

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            {!isAdmin && <FilterDate date={date} setDate={setDate} tipeLaporan={tipeLaporan} />}
          </div>

          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-full">
              <BtnAdd path="/lalu-lintas/create" />

              <Tooltip label="Print laporan">
                <Link to={`/lalu-lintas-print`} state={{ data: data, date: date }}>
                  <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                    <HiOutlinePrinter size={24} />
                  </div>
                </Link>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Show Table */}
        <ShowTable loading={loading} datas={datas} filterData={filterData} columnData={columnData} setQuery={setQuery} />
      </div>

      <div className="flex justify-center mt-5">
        <Link to="/">
          <button className="btnAksi bg-primary py-2 px-20">Dashboard</button>
        </Link>
      </div>

      {/* Modal Detail Kegiatan */}
      <ModalLaluLintasDetail kode={kegiatanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

      {/* Modal Image */}
      <ShowModalImage showImage={showImage} setShowImage={setShowImage} imageData={imageData} />
    </div>
  );
}
