import React from 'react';
import { BsGearFill } from 'react-icons/bs';
import { Popover } from '@mantine/core';
import { Link } from 'react-router-dom';

export default function BtnAction({ showPopover, setShowPopover, data, id, handleOpenModalDetail, handleDelete, handleReset, reset, page, noPrint, noDetail }) {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  return (
    <div className="flex items-center justify-center gap-1">
      {/* Button Aksi */}
      <Popover opened={showPopover === data} onClick={() => setShowPopover(showPopover ? '' : data)} width={110} position="right" withArrow shadow="xl">
        <Popover.Target>
          <div className="btnAksi my-1 py-1 px-2">
            <BsGearFill className="mb-[2px]" /> &nbsp; Aksi
          </div>
        </Popover.Target>
        <Popover.Dropdown className="popOverDropdown">
          {!noDetail && (
            <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(data)}>
              <i className="fa-solid fa-circle-info"></i> Detail
            </div>
          )}
          {adminType === 'Admin Project' && (
            <>
              <Link to={`${page}/update/${data}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Ubah
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(id)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Hapus
              </div>
            </>
          )}
          {!noPrint && (
            <Link to={`${page}-printperkeg/${data}`}>
              <div className="popOverItem">
                <i className="fa-solid fa-print"></i> Cetak
              </div>
            </Link>
          )}
          {reset && (
            <div className="popOverItem" onClick={() => handleReset(id)}>
              <i className="fa-solid fa-power-off"></i> Reset
            </div>
          )}
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
