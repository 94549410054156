import QRCode from 'react-qr-code';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Modal, Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
// Import files
import * as api from '../../redux/api';
import ModalCheckpointDetail from '../../components/pos-checkpoint/ModalCheckpointDetail';
import ModalCheckpointQR from '../../components/pos-checkpoint/ModalCheckpointQR';
import Table from '../../components/general/Table';
import { distanceFormat } from '../../utils/distanceFormat';

export default function CheckpointData() {
  const [data, setData] = useState([]);
  const [detailKode, setDetailKode] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalQR, setOpenModalQR] = useState(false);
  const [qrDetail, setQrDetail] = useState({});
  const [query, setQuery] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.checkpointData().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({ satpamtitikkode, satpamtitikstatus, satpamtitiknama, satpamposnama, satpamtitikketerangan, satpamtitikradius, satpamposlatitude, satpamposlongitudde }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamtitikkode} ${satpamtitikstatus} ${satpamtitiknama} ${satpamposnama} ${satpamtitikketerangan} ${satpamtitikradius} ${satpamposlatitude} ${satpamposlongitudde}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (kodetitik) => {
    setDetailKode(kodetitik);
    setOpenModalDetail(true);
  };

  const handleOpenModalQR = ({ kode, nama }) => {
    setQrDetail({ kode, nama });
    setOpenModalQR(true);
  };

  const handleImageData = ({ satpamtitikfoto }) => {
    satpamtitikfoto?.split('/data-titik/')[1] && setImageData(satpamtitikfoto);
    setShowImage(!showImage);
  };

  const handleDelete = (kodetitik) => {
    Swal.fire({
      text: 'Do you want to delete this Checkpoint?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .checkpointDelete(kodetitik)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamtitikkode',
      width: 100,
      render: ({ satpamtitikkode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 px-2 py-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div className="popOverItem" onClick={() => handleOpenModalDetail({ satpamtitikkode })}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>

              <Link to={`/checkpoint/update/${satpamtitikkode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete({ satpamtitikkode })}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Checkpoint',
      accessor: 'satpamtitikfoto',
      width: 150,
      render: ({ satpamtitikfoto }) => (
        <div className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80" onClick={() => handleImageData({ satpamtitikfoto })}>
          <img src={satpamtitikfoto?.split('/data-titik/')[1] ? satpamtitikfoto : ''} alt="" className="h-full object-contain rounded-lg" />
        </div>
      )
    },
    {
      title: 'Kode, Status',
      accessor: 'satpamtitikstatus',
      wrapText: true,
      width: 180,
      render: ({ satpamtitikkode, satpamtitiknama, satpamtitikstatus }) => (
        <div className="flex flex-col justify-center items-center py-0 my-0">
          <div
            className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer"
            onClick={() =>
              handleOpenModalQR({
                kode: satpamtitikkode,
                nama: satpamtitiknama
              })
            }>
            <QRCode size={128} value={satpamtitikkode} />
          </div>
          {satpamtitikstatus === 'Aktif' ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">Aktif</div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">Tidak Aktif</div>
          )}
        </div>
      )
    },
    {
      title: 'Nama Checkpoint',
      accessor: 'satpamtitiknama',
      width: 200
    },
    {
      title: 'Nama POS',
      accessor: 'satpamposnama',
      width: 200
    },
    {
      title: 'Keterangan',
      accessor: 'satpamtitikketerangan',
      width: 300
    },
    {
      title: 'Radius',
      accessor: 'satpamtitikradius',
      width: 100,
      render: ({ satpamtitikradius }) => <div className="py-0 my-0">{satpamtitikradius !== null && distanceFormat(parseInt(satpamtitikradius))}</div>
    },
    {
      title: 'Latitude',
      accessor: 'satpamtitiklatitude',
      width: 120
    },
    {
      title: 'Longtitude',
      accessor: 'satpamtitiklongitude',
      width: 120
    }
  ];

  return (
    <div className="relative px-4 md:px-10 pb-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex justify-end items-center">
          {/* <h4 className="text-md">Data POS</h4> */}
          <div className="my-3 px-5 flex">
            <div className="flex gap-4 w-full">
              <Link to="/checkpoint/create">
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <AiOutlineFileAdd size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Component */}
        {data.length === 0 ? (
          <div className="text-center p-5">
            <div className="mt-10">Tidak ada data checkpoint.</div>
            <div className="mb-72">Silahkan tambahkan checkpoint!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table data={filterData} columnData={columnData} setQuery={setQuery} />
          </div>
        )}
      </div>
      {/* Modal Detail */}
      <ModalCheckpointDetail kode={detailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

      {/* Modal Detail QR */}
      <ModalCheckpointQR qrDetail={qrDetail} openModalQR={openModalQR} setOpenModalQR={setOpenModalQR} />

      {/* Modal Image */}
      <Modal centered size="xl" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
        {/* Content image */}
        {imageData && (
          <div className="w-full">
            <img src={imageData} alt="" className="w-full object-contain rounded-t-lg flex items-center justify-center" />
          </div>
        )}
      </Modal>
    </div>
  );
}
