import React from 'react';
import { Modal, Popover, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretDown } from 'react-icons/ai';
import { HiOutlinePrinter } from 'react-icons/hi';

const ModalSatpamCetak = () => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Tooltip label="Cetak pdf">
        <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700" onClick={() => setOpened(!opened)}>
          <HiOutlinePrinter size={24} />
        </div>
      </Tooltip>
      <Modal opened={opened} centered onClose={() => setOpened(false)}>
        <div className="text-center text-lg font-bold mb-5">Print Personil Data</div>
        <div className="flex justify-center gap-5 mb-10">
          <Link to="/personil/cetak-all">
            <button className="btnAksi text-xs py-2">Print all</button>
          </Link>
          {/* <Popover width={150} position="bottom" withArrow shadow="lg">
            <Popover.Target>
              <div className="btnAksi text-xs">
                By tipe personil &nbsp; <AiFillCaretDown />
              </div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <Link to="/personil/cetak-utama">
                <div className="popOverItem text-xs rounded py-[2px]">Personil utama</div>
              </Link>
              <Link to="/personil/cetak-cadangan">
                <div className="popOverItem text-xs rounded py-[2px]">Personil cadangan</div>
              </Link>
            </Popover.Dropdown>
          </Popover> */}
          {/* <Popover width={150} position="bottom" withArrow shadow="lg">
            <Popover.Target>
              <div className="btnAksi text-xs">
                By pendidikan &nbsp; <AiFillCaretDown />
              </div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <Link to="#">
                <div className="popOverItem text-xs rounded py-[2px]">Gada utama</div>
              </Link>
              <Link to="#">
                <div className="popOverItem text-xs rounded py-[2px]">Gada madya</div>
              </Link>
              <Link to="#">
                <div className="popOverItem text-xs rounded py-[2px]">Gada pratama</div>
              </Link>
            </Popover.Dropdown>
          </Popover> */}
        </div>
      </Modal>
    </>
  );
};

export default ModalSatpamCetak;
