import { useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import { Link } from 'react-router-dom';

export default function ModalPrintTimeSheet({
  openModalPrint,
  setOpenModalPrint,
  satpamDataOptions,
  date
}) {
  const [jenis, setJenis] = useState('Semua');
  const [satpamSelected, setSatpamSelected] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [searchSatpam, setSearchSatpam] = useState('');

  useEffect(() => {
    setSatpamSelected(satpamDataOptions.map((personil) => ({ satpamkode: personil.value })));
  }, [satpamDataOptions]);

  useEffect(() => {
    if (satpamSelected?.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [satpamSelected]);

  const handleSatpamSelected = (e) => {
    if (e.target.checked) {
      setSatpamSelected([...satpamSelected, { satpamkode: e.target.value }]);
    } else {
      setSatpamSelected(
        satpamSelected.filter((personil) => personil.satpamkode !== e.target.value)
      );
    }
  };

  const filteredSatpamOptions = satpamDataOptions.filter((personil) => {
    return personil.label.toLowerCase().includes(searchSatpam.toLowerCase());
  });

  const selectAll = () => {
    setSatpamSelected([]);
    filteredSatpamOptions.map((option) => {
      return setSatpamSelected((prev) => [...prev, { satpamkode: option.value }]);
    });
  };

  return (
    <Modal
      centered
      opened={openModalPrint}
      onClose={() => setOpenModalPrint(false)}
      className="overflow-auto bg-transparent">
      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="text-center text-lg font-bold mb-1">Print Timesheet</div>
        <div className="w-full flex flex-col p-5">
          <div className="md:flex mb-2 items-center">
            <select
              className="w-full inputForm"
              value={jenis}
              onChange={(e) => {
                if (e.target.value === 'Beberapa') {
                  setSatpamSelected([]);
                }
                if (e.target.value === 'Semua') {
                  setSatpamSelected(
                    satpamDataOptions.map((personil) => ({ satpamkode: personil.value }))
                  );
                }
                setJenis(e.target.value);
              }}>
              <option value="">Pilih Jenis</option>
              <option value="Semua">Semua Personil</option>
              <option value="Beberapa">Beberapa Personil</option>
            </select>
          </div>
          {jenis === 'Beberapa' && (
            <div className="flex flex-col mb-2 items-center">
              <div className="w-full mb-2">
                <input
                  type="text"
                  className="w-full inputForm py-1.5"
                  placeholder="Cari nama"
                  onChange={(e) => setSearchSatpam(e.target.value)}
                />
                <div className="mt-2 flex flex-col md:flex-row gap-2">
                  <div className="w-1/2">
                    <button
                      type="button"
                      onClick={selectAll}
                      className={`block w-full text-gray-50 py-2 px-2 rounded-md text-sm bg-dark hover:opacity-80`}>
                      <i className="fas fa-check mr-2"></i> Pilih semua
                    </button>
                  </div>
                  <div className="w-1/2">
                    <button
                      type="button"
                      onClick={() => setSatpamSelected([])}
                      className={`block w-full text-gray-50 py-2 px-2 rounded-md text-sm ${
                        satpamSelected.length > 0 ? 'bg-errorText' : 'bg-customGray'
                      } hover:opacity-80`}>
                      <i className="fas fa-close mr-2"></i> Batal pilih
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full mb-2 inline-block rounded-md overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 h-96">
                <table className="table-roster table-auto mx-auto w-full z-50">
                  <thead className="z-10 divide-y-2 divide-gray-400 rounded-md">
                    <tr>
                      <th className="w-[5%] bg-gray-300 font-bold text-sm py-1 whitespace-nowrap text-center border border-gray-300 border-t-0 border-l-0 align-middle ">
                        Aksi
                      </th>
                      <th className="bg-gray-300 w-1/3 font-bold text-sm py-1 whitespace-nowrap text-center border border-gray-300 border-t-0 border-l-0 align-middle">
                        Nama Personil
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSatpamOptions.length > 0 &&
                      filteredSatpamOptions.map((personil, index) => (
                        <tr key={personil.value}>
                          <td className="px-2 py-1 border-b-2 border-gray-200 text-sm text-center">
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              id={personil.value}
                              value={personil.value}
                              checked={
                                satpamSelected.filter(
                                  (selected) => selected.satpamkode === personil.value
                                ).length > 0
                                  ? true
                                  : false
                              }
                              onChange={handleSatpamSelected}
                            />
                          </td>
                          <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-left">
                            <label htmlFor={personil.value} className="cursor-pointer">
                              {personil.label}
                            </label>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {!submitDisabled && (
            <Link
              to="/personil/timesheet/print"
              state={{
                startDate: date.startDate,
                endDate: date.endDate,
                satpamKode: satpamSelected
              }}>
              <div className="text-gray-50 py-2 px-5 rounded-md text-sm text-center bg-dark hover:opacity-80">
                Cetak
              </div>
            </Link>
          )}
        </div>
      </div>
    </Modal>
  );
}
