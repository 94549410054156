import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Import files
import * as api from '../../redux/api';
import FilterDateNew from '../../components/general/FilterDateNew';
import MapBox from '../../components/dashboard/MapBox';

export default function Dashboard({ satpamData }) {
  const [laporan, setLaporan] = useState('/kegiatan-harian');
  const [tipeLaporan, setTipeLaporan] = useState('this week');
  const [data, setData] = useState([]);
  const [date, setDate] = useState({
    startDate: moment().subtract().add(-6, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [kategoriOptions, setKategoriOptions] = useState([]);
  const [kategori, setKategori] = useState('');
  const [errorLaporan, setErrorLaporan] = useState(false);

  // Kategori laporan
  useEffect(() => {
    const getData = async () => {
      if (laporan === '/kegiatan-harian') {
        await api.kegiatanHarianKategori().then((res) => {
          if (res.data.data) setKategoriOptions([]);
          res.data.data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamkegiatankategorikode,
                label: data.satpamkegiatankategorinama
              }
            ]);
          });
        });
      }

      if (laporan === '/kejadian') {
        await api.kejadianKategori().then((res) => {
          if (res.data.data) setKategoriOptions([]);
          res.data.data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamkejadiankategorikode,
                label: data.satpamkejadiankategorinama
              }
            ]);
          });
        });
      }

      if (laporan === '/temuan') {
        await api.temuanKategori().then((res) => {
          if (res.data.Data) setKategoriOptions([]);
          res.data.Data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamtemuankategorikode,
                label: data.satpamtemuankategorinama
              }
            ]);
          });
        });
      }
    };
    getData();
  }, [laporan]);

  // Data laporan
  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpamkegiatansatpamkegiatankategorikode: kategori
    };

    const getData = async () => {
      if (laporan) {
        if (laporan === '/kegiatan-harian') {
          await api.kegiatanHarian(formDate).then((res) => {
            setData(res.data.data);
          });
        }

        laporan === '/kejadian' &&
          (await api.kejadian(formDate).then((res) => {
            setData(res.data.data);
          }));

        laporan === '/lalu-lintas' &&
          (await api.laluLintas(formDate).then((res) => {
            setData(res.data.data);
          }));

        laporan === '/keadaan-darurat' &&
          (await api.keadaanData().then((res) => {
            setData(res.data.data);
          }));

        if (laporan === '/temuan') {
          await api.temuan(formDate).then((res) => {
            setData(res.data.data);
          });
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, laporan, tipeLaporan]);

  const handleErrorLaporan = () => {
    !laporan ? setErrorLaporan(true) : setErrorLaporan(false);
  };

  const handleLaporan = (e) => {
    setLaporan(e.target.value);
  };

  return (
    <>
      <div className="px-4 md:px-10 mx-auto w-full -mt-8">
        {/* Map */}
        <div className="w-full bg-gray-50 rounded-lg shadow-black/20 shadow-lg">
          <MapBox height="50vh" data={satpamData} />
        </div>
        <div className="mt-10 pb-20 grid grid-cols-1 gap-4 justify-between w-full">
          {/* Filter Laporan */}
          <div className="mx-[15%]">
            <div className="font-bold text-xl mt-2 mb-5">Filter Laporan</div>
            <div className="flex gap-5">
              <div className="flex flex-col gap-8 w-[30%]">
                <label className="font-semibold mt-2">Jenis Laporan</label>
                {laporan !== '/lalu-lintas' && laporan !== '/laporan-bulanan' && laporan !== '/keadaan-darurat' && <label className="font-semibold">Kategori</label>}
                {laporan !== '/keadaan-darurat' && laporan !== '/laporan-bulanan' && <label className="font-semibold">Waktu Pelaporan</label>}
              </div>
              <div className="flex flex-col gap-5 w-[70%]">
                <select defaultValue="/kegiatan-harian" className="inputForm w-full" onChange={handleLaporan}>
                  <option disabled value="" className="text-white bg-gray-500">
                    Pilih jenis laporan
                  </option>
                  <option value="/kegiatan-harian">Laporan Harian</option>
                  <option value="/kejadian">Laporan Kejadian</option>
                  <option value="/temuan">Laporan Temuan</option>
                  <option value="/lalu-lintas">Laporan Lalu Lintas</option>
                  <option value="/keadaan-darurat">Laporan Keadaan Darurat</option>
                  <option value="/laporan-bulanan">Laporan Bulanan</option>
                </select>
                {laporan !== '/lalu-lintas' && laporan !== '/keadaan-darurat' && laporan !== '/laporan-bulanan' && (
                  <select defaultValue="" className="inputForm w-full" onChange={(e) => setKategori(e.target.value)}>
                    <option value="">Semua kategori</option>
                    {kategoriOptions.map((data, i) => (
                      <option value={data.value} key={i}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                )}
                {laporan !== '/keadaan-darurat' && laporan !== '/laporan-bulanan' && (
                  <FilterDateNew date={date} setDate={setDate} tipeLaporan={tipeLaporan} setTipeLaporan={setTipeLaporan} />
                )}
              </div>
            </div>
            <div className="flex justify-center gap-5 sm:gap-10 mt-10 text-xs sm:text-base">
              <Link to={laporan} state={{ date, tipeLaporan, kategori }}>
                <button className="bg-primary rounded-md text-white px-5 py-2" onClick={handleErrorLaporan}>
                  Lihat Laporan
                </button>
              </Link>
              {laporan !== '/keadaan-darurat' && laporan !== '/laporan-bulanan' && (
                <Link to={`${laporan}-print`} state={{ data, date }}>
                  <button className="bg-primary rounded-md text-white px-5 py-2" onClick={handleErrorLaporan}>
                    Simpan PDF
                  </button>
                </Link>
              )}
            </div>
            {errorLaporan && !laporan && <div className="text-red-700 mt-2 text-md">Pilih jenis laporan terlebih dahulu!</div>}
          </div>
        </div>
      </div>
    </>
  );
}
