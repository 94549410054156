import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';

const PatroliChekpoint = ({ data, loading, setSelectedRute, selectedRute }) => {
  useEffect(() => {
    setSelectedRute(data.map((option) => ({ rute: option.satpamtitikkode, target: 1 })));
  }, [data]);

  const handleInputChange = (rute, target) => {
    setSelectedRute((prev) => prev.map((item) => (item.rute === rute ? { ...item, target } : item)));
  };

  const handleCheckboxChange = (rute, isChecked) => {
    if (isChecked) {
      setSelectedRute((prev) => {
        if (!prev.some((item) => item.rute === rute)) {
          return [...prev, { rute, target: 1 }];
        }
        return prev;
      });
    } else {
      setSelectedRute((prev) => prev.filter((item) => item.rute !== rute));
    }
  };

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="bg-light p-5 rounded-lg shadowAll">
        {loading ? (
          <div className="w-full sm:h-full flex items-center md:h-40 max-h-48">
            <ClipLoader color="blue" loading={true} size={40} speedMultiplier={0.7} className="mx-auto" />
          </div>
        ) : (
          <div className="md:flex md:flex-col w-full">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="w-[10%] px-2 py-1 border-b-2 border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">Aksi</th>
                      <th className="w-[5%] px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">No</th>
                      <th className="px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">Rute</th>
                      <th className="w-[15%] px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">Target</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((option, index) => (
                        <tr key={index}>
                          <td className="px-2 py-1 border-b-2 border-gray-200 text-sm text-center tracking-wider">
                            <input
                              type="checkbox"
                              name="koderute"
                              id={option.satpamtitikkode}
                              checked={selectedRute.some((r) => r.rute === option.satpamtitikkode)}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckboxChange(option.satpamtitikkode, isChecked);
                              }}
                            />
                          </td>
                          <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-center tracking-wider">{index + 1}</td>
                          <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-left tracking-wider">{option?.satpamtitiknama}</td>
                          <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-center tracking-wider">
                            <input
                              type="number"
                              className={`w-full py-1 px-2 text-center flex flex-col inputForm`}
                              value={selectedRute.find((r) => r.rute === option.satpamtitikkode)?.target || 1}
                              onChange={(e) => handleInputChange(option.satpamtitikkode, parseInt(e.target.value) || 1)}
                              min={1}
                              disabled={!selectedRute.some((r) => r.rute === option.satpamtitikkode)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="px-2 py-1 border-b-2 border-gray-200 text-sm text-center tracking-wider">
                          Pilih pos terlebih dahulu
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatroliChekpoint;
