import React from 'react';
import TableCetakKejadianPerKej from './TableCetakKejadianPerKej';

export default function KejadianCetakPerKej({ refPropWithAnotherName, data }) {
  return (
    <div
      className="w-full bg-white text-[12px]"
      id="kegiatanHarianPrint"
      ref={refPropWithAnotherName}
    >
      <TableCetakKejadianPerKej data={data} />
    </div>
  );
}
