import React from 'react';
import TableCetakTemuanPerTem from './TableCetakTemuanPerTem';

export default function TemuanCetakPerTem({ refPropWithAnotherName, data }) {
  return (
    <div
      className="w-full bg-white text-[12px]"
      id="kegiatanHarianPrint"
      ref={refPropWithAnotherName}
    >
      <TableCetakTemuanPerTem data={data} />
    </div>
  );
}
