import React, { useEffect } from 'react';
import decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Router from './Router';
import Navbar from './components/partials/Navbar';
import Sidebar from './components/partials/Sidebar';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import withClearCache from './ClearCache';
import { setLogout } from './redux/features/authSlice';
import { mainMenu } from './redux/features/menuSlice';
import GoogleAnlytics from './components/general/GoogleAnlytics';

const idLocale = require('moment/locale/id');
moment.locale('id', idLocale);

const ClearCacheComponent = withClearCache(MainApp);

export default function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const token = profile?.access_token;
  const dispatch = useDispatch();
  const location = useLocation();

  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      dispatch(setLogout());
    }
  }

  useEffect(() => {
    if (token) {
      dispatch(mainMenu());
    }
  }, [dispatch, token]);

  const handleLogout = () => {
    Swal.fire({
      text: 'Apakah anda ingin keluar dari aplikasi?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLogout());
      }
    });
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {location.pathname !== '/auth/login' ? (
        <>
          <Sidebar handleLogout={handleLogout} />
          <div className="md:relative md:ml-64 bg-slate-100">
            <Navbar profile={profile} handleLogout={handleLogout} />
            <Router />
          </div>
          <GoogleAnlytics />
        </>
      ) : (
        <Router />
      )}
    </>
  );
}
