import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { AiFillPrinter } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { Helmet } from 'react-helmet';

export default function SatpamCetak({ satpamData }) {
  //   console.log(satpamData);
  let componentRef = useRef();

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <div className="absolute top-0 right-0 bottom-0 z-20 h-screen w-screen overflow-y-auto bg-white text-dark">
        <div className="flex gap-10 justify-center items-center">
          <div className="font-bold text-lg my-5">Preview Cetak Personil</div>
          <div className="flex gap-5">
            <ReactToPrint
              trigger={() => (
                <Tooltip label="Cetak pdf">
                  <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
                    <AiFillPrinter size={18} color="white" />
                  </button>
                </Tooltip>
              )}
              content={() => componentRef}
            />
            <Link to="/personil/data">
              <button className="btnAksi h-fit py-[7px] mt-1 text-[13px]">Kembali</button>
            </Link>
          </div>
        </div>
        <div className="w-full h-full overflow-y-auto flex justify-center item-center mx-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-300 ">
          <div className=" w-4/6 overflow-y-auto flex flex-wrap justify-center bg-white" ref={(el) => (componentRef = el)}>
            {satpamData.map((item) => (
              <div key={item.id} className="w-full px-5 md:w-1/2 flex flex-col items-center overflow-y-auto mt-5 mb-4">
                <h3 className="font-semibold text-center mb-5">Personil Detail</h3>
                <div className="flex justify-center">
                  <img src={require('../../images/satpam.jpg')} alt="" className="w-[100px] h-[100px] object-cover mb-5 rounded-xl" />
                </div>
                <div className="flex gap-10 text-xs">
                  <div className="font-semibold">
                    <p>NIP</p>
                    <p>Nama</p>
                    <p>Nomor hp</p>
                    <p>Email</p>
                    <p>Jabatan</p>
                    {/* <p>Tipe satpam</p> */}
                    <p>Jenis kelamin</p>
                    <p>Status</p>
                    {/* <p>Tanggal masuk</p> */}
                  </div>

                  <div>
                    <p>: {item.satpamnip ? item.satpamnip : '-'}</p>
                    <p>: {item.satpamnamalengkap}</p>
                    <p>: {item.satpamnohp}</p>
                    <p>: {item.satpamemail}</p>
                    <p>: {item.satpamjabatan}</p>
                    {/* <p>: {item.satpamtipe ? item.satpamtipe : '-'}</p> */}
                    <p>: {item.satpamjeniskelamin ? item.satpamjeniskelamin : '-'}</p>
                    <p>: {item.satpamstatus}</p>
                    {/* <p>: {item.satpamtanggalmasuk ? item.satpamtanggalmasuk : '-'}</p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
