import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Select from 'react-select';

export default function FilterKategori({ kategori, setKategori, subKategori, setSubKategori, page }) {
  const initialValue = { value: '', label: 'Semua' };

  const [kategoriOptions, setKategoriOptions] = useState([initialValue]);
  const [subKategoriOptions, setSubKategoriOptions] = useState([initialValue]);

  useEffect(() => {
    const getDataKategori = async () => {
      if (page === 'kegiatan') {
        await api.kegiatanHarianKategori().then((res) => {
          res.data.data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamkegiatankategorikode,
                label: data.satpamkegiatankategorinama
              }
            ]);
          });
        });
      } else if (page === 'kejadian') {
        await api.kejadianKategori().then((res) => {
          res.data.data.map((data) => {
            setSubKategori('');
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamkejadiankategorikode,
                label: data.satpamkejadiankategorinama
              }
            ]);
          });
        });
      } else if (page === 'temuan') {
        await api.temuanKategori().then((res) => {
          res.data.Data.map((data) => {
            return setKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamtemuankategorikode,
                label: data.satpamtemuankategorinama
              }
            ]);
          });
        });
      } else if (page === 'patroli') {
        setKategoriOptions([
          {
            value: 'Patroli Jalan Kaki',
            label: 'Patroli Jalan Kaki'
          },
          {
            value: 'Patroli Berkendara',
            label: 'Patroli Berkendara'
          }
        ]);
      }
    };
    getDataKategori();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (page === 'kejadian' && kategori !== '') {
      const getKejadianSubKategori = async () => {
        await api.kejadianSubKategori({ kategori_kode: kategori }).then((res) => {
          res.data.data.map((data) => {
            return setSubKategoriOptions((prev) => [
              ...prev,
              {
                value: data.satpamkejadiankategoridetailkode,
                label: data.satpamkejadiankategoridetailnama
              }
            ]);
          });
        });
      };
      getKejadianSubKategori();
    }
  }, [kategori, page]);

  const handleChangeKategori = (e) => {
    setKategori(e.value);
    if (page !== 'kegiatan' && page !== 'temuan' && page !== 'patroli') {
      setSubKategori('');
    }
    setSubKategoriOptions([initialValue]);
  };

  return (
    <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
      <div className="font-semibold text-xs mr-2 mb-1 md:mb-0">Filter kategori</div>
      <div className="md:flex md:flex-row gap-2">
        <div className="flex flex-col justify-center items-center md:mb-0 mb-2">
          <Select
            options={kategoriOptions}
            className="w-full text-xs mb-0 md:mb-0 z-50"
            placeholder="Pilih kategori"
            isSearchable={true}
            onChange={handleChangeKategori}
            name="kategorikode"
            value={kategoriOptions.filter((cat) => cat.value === kategori)}
            required
          />
        </div>
        {page === 'kejadian' && kategori !== '' && (
          <div className="flex flex-col justify-center items-center">
            <Select
              options={subKategoriOptions}
              className="w-full text-xs mb-0 md:mb-0 z-50"
              placeholder="Pilih kategori"
              defaultValue={subKategori}
              value={subKategoriOptions.filter((option) => option.value === subKategori)}
              isSearchable={true}
              onChange={(e) => setSubKategori(e.value)}
              name="subkategorikode"
              required
            />
          </div>
        )}
      </div>
    </div>
  );
}
