import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function FilterWithDate({ date, setDate, tipeLaporan, type, setType }) {
  const [formValue, setFormValue] = useState({
    startDate: moment().subtract().add(-6, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  useEffect(() => {
    setFormValue(date);
  }, [date]);

  useEffect(() => {
    if (type !== 'custom') {
      if (type === 'today') {
        setDate({
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        });
      }

      if (type === 'yesterday') {
        setDate({
          startDate: moment().subtract().add(-1, 'days').format('YYYY-MM-DD'),
          endDate: moment().subtract().add(-1, 'days').format('YYYY-MM-DD')
        });
      }

      if (type === 'this_week') {
        setDate({
          startDate: moment().subtract().add(-6, 'days').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        });
      }

      if (type === 'this_month') {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        setDate({
          startDate: moment(startOfMonth).format('YYYY-MM-DD'),
          endDate: moment(endOfMonth).format('YYYY-MM-DD')
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    if (name && value) {
      name === 'startDate' &&
        setFormValue({
          startDate: moment(value).format('YYYY-MM-DD'),
          endDate: moment(value).endOf('month').format('YYYY-MM-DD')
        });

      name === 'endDate' && setFormValue((prev) => ({ ...prev, endDate: moment(value).format('YYYY-MM-DD') }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formValue.startDate && !formValue.endDate) {
      setDate(null); // Clear date params if both are null
    } else {
      setDate({
        startDate: formValue.startDate,
        endDate: formValue.endDate
      });
    }
  };

  return (
    <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
      <div className="font-semibold text-xs mr-2 mb-1 md:mb-0">Filter date</div>
      <div className="md:flex md:flex-row gap-2">
        <div className="flex flex-col justify-center items-center">
          <select className="text-xs cursor-pointer inputForm w-full" name="type" defaultValue={type} value={type} onChange={(e) => setType(e.target.value)}>
            <option value="" disabled>
              Pilih Tanggal
            </option>
            {tipeLaporan.map((val, index) => (
              <option value={val.key} key={index}>
                {val.value}
              </option>
            ))}
          </select>
        </div>
        {type === 'custom' && (
          <form onSubmit={handleSubmit} className="flex md:flex-row flex-col justify-center items-center md:mt-0 mt-2">
            <div className="w-full flex flex-col justify-center items-center">
              <input type="date" id="startDate" className="text-xs cursor-pointer inputForm w-full" name="startDate" value={formValue.startDate} onChange={handleChangeDate} />
            </div>
            <div className="mx-2 my-1 text-xs text-center">to</div>
            <div className="w-full flex flex-col justify-center items-center">
              <input
                type="date"
                id="endDate"
                className="text-xs cursor-pointer inputForm w-full"
                name="endDate"
                value={formValue.endDate}
                min={formValue.startDate}
                max={moment(formValue.startDate).add(30, 'day').format('YYYY-MM-DD')}
                onChange={handleChangeDate}
              />
            </div>
            <button
              type="submit"
              className={`md:ml-2 ml-0 btnAksi ${
                moment(formValue.startDate).diff(moment(formValue.endDate)) > 0 && 'bg-gray-400'
              } w-full flex justify-center text-xs py-2 px-5 rounded`}
              disabled={moment(formValue.startDate).diff(moment(formValue.endDate)) > 0 ? true : false}>
              Filter
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
