import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { InputTime, InputDate } from '../../components/timesheet/InputTimeDate';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import PatroliChekpoint from './PatroliChekpoint';

export default function PatroliCreate() {
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [posOptions, setPosOptions] = useState([]);
  const [selectedPos, setSelectedPos] = useState('');
  const [ruteFields, setRuteFields] = useState([]);
  const [selectedRute, setSelectedRute] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jamJadwal, setJamJadwal] = useState({ masuk: '', keluar: '' });
  const [tanggalJadwal, setTanggalJadwal] = useState({ masuk: '', keluar: '' });
  const [errorTanggal, setErrorTanggal] = useState({ message: '' });
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  //
  useEffect(() => {
    const getDataSatpam = async () => {
      const res = await api.satpamData();
      const options = res.data?.data.map((data) => ({
        value: data.satpamkode,
        label: data.satpamnamalengkap
      }));
      setSatpamDataOptions(options);
    };

    const getDataPOS = async () => {
      const res = await api.patroliGetPOS();
      const options = res.data?.data.map((data) => ({
        value: data.satpamposkode,
        label: data.satpamposnama
      }));
      setPosOptions(options);
    };

    getDataSatpam();
    getDataPOS();
  }, []);

  useEffect(() => {
    if (selectedPos) {
      getDataRute(selectedPos);
    }
  }, [selectedPos]);

  const getDataRute = async (satpamPosKode) => {
    setLoadingData(true);
    await api.patroliGetRute(satpamPosKode).then((res) => {
      const rutes = res.data?.data;
      setRuteFields(rutes);
      setLoadingData(false);
    });
  };

  const handleTanggalMasukChange = (date) => {
    setTanggalJadwal((prevState) => ({
      ...prevState,
      masuk: date
    }));
    if (tanggalJadwal.keluar && date > tanggalJadwal.keluar) {
      setErrorTanggal({ message: 'Tanggal keluar tidak boleh kurang dari tanggal masuk' });
    } else {
      setErrorTanggal({ message: '' });
    }
  };

  const handleTanggalKeluarChange = (date) => {
    setTanggalJadwal((prevState) => ({
      ...prevState,
      keluar: date
    }));
    if (tanggalJadwal.masuk && date < tanggalJadwal.masuk) {
      setErrorTanggal({ message: 'Tanggal keluar tidak boleh kurang dari tanggal masuk' });
    } else {
      setErrorTanggal({ message: '' });
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    const mulaiTanggal = moment(tanggalJadwal.masuk).format('YYYY-MM-DD') + moment(jamJadwal.masuk).format('THH:mm');
    const selesaiTanggal = moment(tanggalJadwal.keluar).format('YYYY-MM-DD') + moment(jamJadwal.keluar).format('THH:mm');

    const dataSubmit = {
      ...data,
      satpampatrolisatpamposkode: selectedPos,
      satpampatrolimulaitanggal: mulaiTanggal,
      satpampatroliselesaitanggal: selesaiTanggal,
      satpampatrolirute: selectedRute
    };

    await api
      .createPatroli(dataSubmit)
      .then((res) => {
        setLoading(false);
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        reset({
          satpampatrolisatpamkode: '',
          satpampatrolinama: '',
          satpampatrolijenis: '',
          satpampatrolimulaitanggal: new Date(),
          satpampatroliselesaitanggal: new Date()
        });
        setRuteFields([]);
        setTanggalJadwal({});
        setJamJadwal({});
        setSelectedPos('');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full py-3 bg-light rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">
          Tambah Patroli
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-light p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full inputForm ${errors.satpampatrolisatpamkode ? 'border-red-600' : ''}`}
                      name="satpampatrolisatpamkode"
                      {...register('satpampatrolisatpamkode', { required: 'Personil is requaried' })}>
                      <option value="">Pilih Personil</option>
                      {satpamDataOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.satpampatrolisatpamkode && <p className="text-red-500 text-sm">{errors.satpampatrolisatpamkode.message}</p>}
                  </div>
                </div>
              </div>
              <div className=" bg-light p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Jenis Patroli</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full inputForm ${errors.satpampatrolijenis ? 'border-red-600' : ''}`}
                      name="satpampatrolijenis"
                      {...register('satpampatrolijenis', { required: 'Please select jenis patroli' })}>
                      <option value="">Pilih jenis patroli</option>
                      <option value="Patroli Jalan Kaki">Patroli Jalan Kaki</option>
                      <option value="Patroli Berkendara">Patroli Berkendara</option>
                    </select>
                    {errors.satpampatrolijenis && <p className="text-red-500 text-sm">{errors.satpampatrolijenis.message}</p>}
                  </div>
                </div>
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Nama Patroli</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full inputForm  ${errors.satpampatrolinama ? 'border-red-500' : ''}`}
                      name="satpampatrolinama"
                      {...register('satpampatrolinama', { required: 'This field cannot be empty' })}
                    />
                    {errors.satpampatrolinama && <p className="text-red-500 text-sm">{errors.satpampatrolinama.message}</p>}
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Pilih POS</label>
                  </div>
                  <div className="md:w-2/3">
                    <select className="w-full inputForm" name="satpampatrolisatpamposkode" onChange={(e) => setSelectedPos(e.target.value)} value={selectedPos}>
                      <option value="">Pilih POS patroli</option>
                      {posOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="bg-light p-5 rounded-lg shadowAll">
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/4">
                    <p className="block font-semibold mb-1 md:mb-0">Pilih Jam</p>
                  </div>
                  <div className=" flex gap-2 w-full">
                    <InputTime
                      label={'Jam Masuk'}
                      value={jamJadwal.masuk}
                      selected={jamJadwal.masuk}
                      interval={15}
                      onchange={(date) => {
                        setJamJadwal({ ...jamJadwal, masuk: date });
                      }}
                    />

                    <InputTime
                      label={'Jam Keluar'}
                      value={jamJadwal.keluar}
                      selected={jamJadwal.keluar}
                      interval={15}
                      onchange={(date) => {
                        setJamJadwal({ ...jamJadwal, keluar: date });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="md:flex mb-3 items-center">
                    <div className="md:w-1/4">
                      <p className="block font-semibold mb-1 md:mb-0">Pilih Tangal </p>
                    </div>
                    <div className=" flex gap-2 w-full">
                      <InputDate
                        className={` w-full text-sm cursor-pointer inputForm ${errorTanggal.message ? 'border-red-500' : ''} `}
                        label={'Tanggal Masuk'}
                        value={tanggalJadwal.masuk}
                        selected={tanggalJadwal.masuk}
                        onchange={handleTanggalMasukChange}
                      />

                      <InputDate
                        label={'Tanggal Keluar'}
                        className={` w-full text-sm cursor-pointer inputForm ${errorTanggal.message ? 'border-red-500' : ''} `}
                        value={tanggalJadwal.keluar}
                        selected={tanggalJadwal.keluar}
                        onchange={handleTanggalKeluarChange}
                      />
                    </div>
                  </div>
                  <p className="text-sm text-red-500">{errorTanggal.message}</p>
                </div>
              </div>
            </div>
            {/* show chekpoint */}
            <PatroliChekpoint data={ruteFields} loading={loadingData} selectedRute={selectedRute} setSelectedRute={setSelectedRute} />
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
            <button type="submit" className="text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" disabled={loading === true ? true : false}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <button type="button" className="btn text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" onClick={() => navigate(-1)}>
              Kembali
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
