import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';

export default function ModalKeluhanDetail({ kode, openModalDetail, setOpenModalDetail, refreshData }) {
  const [dataKeluhan, setDataKeluhan] = useState({});
  const [loading, setLoading] = useState(false);

  const getDataKeluhan = () => {
    if (kode !== '') {
      const getKeluhanByKode = async () => {
        await api.keluhanDetail(kode).then((res) => {
          setDataKeluhan(res.data.data[0]);
        });
      };

      getKeluhanByKode();
    }
  };

  const updateStatus = async () => {
    setLoading(true);
    try {
      const formData = {
        satpamkeluhankode: dataKeluhan.satpamkeluhankode,
        satpamkeluhanstatus: 'Close'
      };

      await api.updateStatusKeluhan(formData);
      refreshData();
      Swal.fire({
        icon: 'success',
        title: 'Status updated successfully'
      });

      getDataKeluhan();
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error updating status',
        text: 'An error occurred while updating the status.'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataKeluhan();
  }, [kode]);

  return (
    <Modal opened={openModalDetail} size="xl" overflow="inside" centered closeOnClickOutside onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Keluhan</div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode</div>
            <div className="w-full">{dataKeluhan?.satpamkeluhankode || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal</div>
            <div className="w-full">
              {dataKeluhan?.satpamkeluhantanggal !== '' && dataKeluhan?.satpamkeluhantanggal !== null && moment(dataKeluhan?.satpamkeluhantanggal).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Isi</div>
            <div className="w-full">{dataKeluhan?.satpamkeluhanisi || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Status</div>
            <div className="w-full">
              <div className="px-4 border-secondary text-sm border-2 text-secondary btnStatus rounded text-center">{dataKeluhan?.satpamkeluhanstatus || '-'}</div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Foto</div>
            <div className="w-full">
              <img
                src={dataKeluhan?.satpamkeluhangambar}
                alt="image"
                className="rounded-md h-full object-contain rounded-lg"
                onError={(e) => (e.target.onerror = null)((e.target.src = '/not-found.jpg'))}
              />
            </div>
          </div>
        </div>
      </div>
      {dataKeluhan?.satpamkeluhanstatus === 'Progress' && (
        <div className="flex mt-5 justify-end">
          <button className=" bg-blue-900 text-white px-1.5 py-2.5 text-xs rounded-lg" onClick={updateStatus}>
            {loading ? (
              <div className="flex gap-2">
                <p>Update Status</p>
                <ClipLoader size={10} className="text-white my-auto" color="#ffff" />
              </div>
            ) : (
              <p>Update Status</p>
            )}
          </button>
        </div>
      )}
    </Modal>
  );
}
