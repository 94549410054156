import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useImagePreview from '../../hooks/useImagePreview';
import { informasiPromo, informasiPromoUpdate } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';
import id from 'date-fns/locale/id';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment/moment';
import { formats, modules } from '../../utils/reactQuillOptions';

const CreateEditPromo = () => {
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({});
  const [tanggal, setTanggal] = useState('');
  const [isi, setIsi] = useState('');
  const [kategori, setKategori] = useState([]);

  const navigate = useNavigate();
  const { id: idPromo } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    resolver: idPromo ? yupResolver(informasiPromoUpdate) : yupResolver(informasiPromo)
  });

  const handleGetKategoriPromo = async () => {
    try {
      const { data, status } = await api.getKategoriPromo();
      if (status === 200) {
        const valueKategori = data.data.map((value) => {
          return {
            label: value.name,
            value: value.id
          };
        });
        setKategori(valueKategori);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDetailPromo = async () => {
    try {
      const { data, status } = await api.detailPromo(idPromo);
      if (status === 200) {
        setDetail(data.data);
        setTanggal(new Date(data.data.date));
        setIsi(data.data.content);
        setValue('title', data.data.title);
        setValue('kategori_id', data.data.kategori_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [fileDataURL] = useImagePreview(watch('image'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const onHandleSubmit = async (data) => {
    setLoading(true);

    data.date = moment(tanggal).format('YYYY-MM-DDTHH:mm');
    let newIsi = isi;
    const removeTagP = newIsi.replace(/<p>/g, '');
    const changeTagPtoBr = removeTagP.replace(/<\/p>/g, '<br>');

    data.content = changeTagPtoBr;

    const formData = new FormData();

    if (data.image.length > 0 && typeof data.image !== 'string') {
      const compressedFile = await imageCompression(data.image[0], compressOptions);

      formData.append('image', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    if (idPromo) {
      if (data.image.length === 0) {
        formData.delete('image');
      }

      formData.append('_method', 'PUT');

      await api
        .updatePromo(formData, detail.code)
        .then((res) => {
          Swal.fire('Good job!', 'You successfully update the form!', 'success');
          setLoading(false);
          navigate('/promo');
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message
          });
        });
    } else {
      await api
        .createPromo(formData)
        .then((res) => {
          Swal.fire('Good job!', 'You successfully submit the form!', 'success');
          setLoading(false);
          navigate('/promo');
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message
          });
        });
    }
  };

  useEffect(() => {
    handleGetKategoriPromo();

    if (idPromo) {
      handleGetDetailPromo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPromo]);

  return (
    <div className="relative w-full px-4 mx-auto -mt-8 md:px-10 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="flex items-center justify-center py-3 mb-10 text-lg font-semibold text-center rounded-lg shadow-lg bg-gray-50 shadow-black/40">
          <p>{idPromo ? 'Update Promo' : 'Buat Promo'}</p>
        </div>

        <form onSubmit={handleSubmit(onHandleSubmit)} encType="multipart/form-data" className="flex flex-col w-full gap-5">
          <div className="flex flex-col w-full gap-5">
            <div className="flex flex-col justify-around p-5 rounded-lg bg-gray-50 shadowAll">
              <div className="items-center mb-3 md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Nama Promo</label>
                </div>
                <div className="md:w-3/4">
                  <input
                    type="text"
                    defaultValue={detail.title ? detail.title : ''}
                    className={`w-full ${errors.title ? 'invalidForm' : 'inputForm'}`}
                    {...register('title', { required: true })}
                  />
                  <p className="text-xs text-errorText">{errors.title?.message}</p>
                </div>
              </div>

              <div className="items-center mb-3 md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Tanggal Promo</label>
                </div>

                <div className="md:w-3/4">
                  <div className="relative block">
                    <DatePicker
                      selected={tanggal}
                      className={`w-full text-sm cursor-pointer inputForm`}
                      onChange={(date) => setTanggal(date)}
                      locale={id}
                      showTimeInput
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      placeholderText="dd/MM/yyyy HH:mm"
                      required
                    />
                    <div className="absolute transform -translate-y-1/2 pointer-events-none top-1/2 right-3">
                      <i className="far fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="items-center md:flex">
                <div className="md:w-1/4">
                  <label className="block mb-1 font-semibold md:mb-0">Kategori Promo</label>
                </div>

                <div className="md:w-3/4">
                  <select className={`w-full ${errors.kategori_id ? 'invalidForm' : 'inputForm'}`} {...register('kategori_id', { required: true })}>
                    <option value="">Pilih Kategori</option>
                    {kategori.map((value) => (
                      <option key={value.value} value={value.value} selected={detail ? detail.kategori_id === value.value : false}>
                        {value.label}
                      </option>
                    ))}
                  </select>

                  <p className="text-xs text-errorText">{errors.kategori_id?.message}</p>
                </div>
              </div>
            </div>

            <div className="p-5 rounded-lg bg-gray-50 shadowAll">
              <div className="mb-3 md:flex">
                <div className="md:w-1/4">
                  <label htmlFor="satpamfoto" className="block mb-1 font-semibold md:mb-0">
                    Gambar Promo
                  </label>
                </div>
                <div className="md:w-3/4">
                  <div className="flex w-full">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white border border-gray-200 rounded-md shadow-md">
                        <div className="flex flex-col w-full">
                          <label className="flex flex-col items-center justify-center w-full p-2 border-4 border-dashed h-36 hover:bg-gray-100 hover:border-gray-300">
                            {fileDataURL ? (
                              <div className="flex flex-col items-center justify-center">
                                <img src={fileDataURL} className="object-contain w-full h-32" alt="preview" />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                {detail.image ? (
                                  <img src={detail.image} className="object-contain w-full h-32" alt="preview" />
                                ) : (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                      viewBox="0 0 20 20"
                                      fill="currentColor">
                                      <path
                                        fillRule="evenodd"
                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                                  </>
                                )}
                              </div>
                            )}
                            <input type="file" className="opacity-0" {...register('image', { required: true })} accept="image/*" />
                          </label>
                        </div>
                        <p className="text-xs text-errorText">{errors.image?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-5 rounded-lg bg-gray-50 shadowAll">
              <div className="mb-3 md:flex">
                <div className="md:w-1/4">
                  <label htmlFor="isi" className="block mb-1 font-semibold md:mb-0">
                    Isi Promo
                  </label>
                </div>
                <div className="md:w-3/4">
                  <ReactQuill theme="snow" modules={modules} formats={formats} value={isi} onChange={setIsi} className="h-[350px] mb-10" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full px-10 py-5 rounded-lg shadowAll">
            <button type="submit" className="px-5 py-2 text-sm rounded-md text-gray-50 bg-dark hover:opacity-80">
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Simpan'}
            </button>

            <Link to="/promo">
              <button className="px-5 py-2 text-sm rounded-md btn text-gray-50 bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEditPromo;
