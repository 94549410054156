import moment from 'moment';
import React from 'react';
import Headerlaporan from '../general/HeaderLaporan';
import TimelineSatpamProses from '../general/TimelineSatpamProses';

const TableCetakTemuanPerTem = ({ data }) => {
  return (
    <div className="w-full">
      <div>
        <div className="flex w-full">
          <Headerlaporan />
          <div className="w-full px-5 bg-black flex flex-col items-end justify-center text-white">
            <div className="text-[18px] font-bold">LAPORAN TEMUAN</div>
            <div className="text-[14px]">{data.satpamperusahaannama}</div>
          </div>
        </div>
        <div className="m-5">
          <table className="border-gray-50" id="kegiatanHarianPrint">
            <thead className="bg-primary font-bold text-white text-[12px]">
              <tr>
                <th colSpan="7" className="border border-gray-300 py-2 px-2 text-left">
                  <p className="font-semibold text-[18px]">#{data?.satpamtemuankode}</p>
                </th>
              </tr>
            </thead>
            <tbody className="text-black">
              <tr>
                <td colSpan="2" className="w-[30%] border border-gray-300 py-2 px-2 text-left text-primary font-semibold" height="5px">
                  {moment(data?.satpamtemuantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
                </td>
                <td colSpan="4" rowSpan="4" className="border border-gray-300 py-2 px-2 text-left align-top">
                  <h4 className="font-semibold">DETAIL TEMUAN</h4>
                  <p className="mb-2">{data?.satpamtemuanuraian}</p>
                  <h4 className="font-semibold">TINDAKAN</h4>
                  <p className="mb-2">{data?.satpamtemuantindakan}</p>
                  <h4 className="font-semibold">HASIL TINDAKAN</h4>
                  <p>{data?.satpamtemuanhasil}</p>
                </td>
                <td rowSpan="5" className="w-[20%] border border-gray-300 py-2 px-2 text-left align-top">
                  <h4 className="font-semibold mb-2">DETAIL PROSES</h4>
                  <TimelineSatpamProses data={data?.satpam_proses ? data?.satpam_proses : ''} />
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="border border-gray-300 py-2 px-2 text-left">
                  <span className="font-semibold">PATOKAN </span>: {data?.satpamtemuanalamatpatokan}
                  <br />
                  <span className="font-semibold">ALAMAT LENGKAP</span> : {data?.satpamtemuanalamat}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 py-2 px-2 text-left w-[25%] align-top">
                  <p className="font-semibold">KATEGORI :</p>
                  <p>{data?.satpamtemuankategorinama}</p>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 py-2 px-2 text-left">
                  <h4 className="text-md font-bold">PELAPOR : {data?.satpamnamalengkap}</h4>
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="border border-gray-300 p-5">
                  <div className="mx-auto">
                    <div className="flex flex-wrap -m-1 md:-m-2">
                      {data?.satpamtemuanfotosatu?.split('/data-temuan/')[1] && (
                        <div className="flex flex-wrap w-1/5">
                          <div className="w-full p-1 md:p-2">
                            <div className="h-52 min-h-min max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                              <img className="rounded-t-md h-36 object-cover w-full" src={data?.satpamtemuanfotosatu} alt="" />
                              <div className="p-3">
                                <p className="mb-2">{data?.satpamtemuanketeranganfotosatu}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.satpamtemuanfotodua?.split('/data-temuan/')[1] && (
                        <div className="flex flex-wrap w-1/5">
                          <div className="w-full p-1 md:p-2">
                            <div className="h-52 min-h-min max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                              <img className="rounded-t-md h-36 object-cover w-full" src={data?.satpamtemuanfotodua} alt="" />
                              <div className="p-3">
                                <p className="mb-2">{data?.satpamtemuanketeranganfotodua}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.satpamtemuanfototiga?.split('/data-temuan/')[1] && (
                        <div className="flex flex-wrap w-1/5">
                          <div className="w-full p-1 md:p-2">
                            <div className="h-52 min-h-min max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                              <img className="rounded-t-md h-36 object-cover w-full" src={data?.satpamtemuanfototiga} alt="" />
                              <div className="p-3">
                                <p className="mb-2">{data?.satpamtemuanketeranganfototiga}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.satpamtemuanfotoempat?.split('/data-temuan/')[1] && (
                        <div className="flex flex-wrap w-1/5">
                          <div className="w-full p-1 md:p-2">
                            <div className="h-52 min-h-min max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                              <img className="rounded-t-md h-36 object-cover w-full" src={data?.satpamtemuanfotoempat} alt="" />
                              <div className="p-3">
                                <p className="mb-2">{data?.satpamtemuanketeranganfotoempat}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.satpamtemuanfotolima?.split('/data-temuan/')[1] && (
                        <div className="flex flex-wrap w-1/5">
                          <div className="w-full p-1 md:p-2">
                            <div className="h-52 min-h-min max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                              <img className="rounded-t-md h-36 object-cover w-full" src={data?.satpamtemuanfotolima} alt="" />
                              <div className="p-3">
                                <p className="mb-2">{data?.satpamtemuanketeranganfotolima}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="w-full">
                <td colSpan="3" className="border border-gray-300 p-2 text-left align-top">
                  <p className="font-semibold">POTENSI TEMUAN :</p>
                  <p>{data?.satpamtemuanpotensi !== null ? data?.satpamtemuanpotensi : 'Belum ada potensi'}</p>
                  <p className="font-semibold mt-2">ANALISIS TEMUAN :</p>
                  <p>{data?.satpamtemuananalisis !== null ? data?.satpamtemuananalisis : 'Belum ada analisis'}</p>
                </td>
                <td colSpan="3" className="border border-gray-300 p-2 text-left align-top">
                  <p className="font-semibold">KESIMPULAN TEMUAN :</p>
                  <p>{data?.satpamtemuankesimpulan !== null ? data?.satpamtemuankesimpulan : 'Belum ada kesimpulan'}</p>
                  <p className="font-semibold mt-2">SARAN DAN REKOMENDASI :</p>
                  <p>{data?.satpamtemuanrekomendasi !== null ? data?.satpamtemuanrekomendasi : 'Belum ada rekomendasi'}</p>
                </td>
                <td className="border border-gray-300 px-2 py-2 text-center align-middle">
                  <div>Tanda Tangan</div>
                  <div className="border mt-16 mb-5 mx-5" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="text-center w-full my-2 text-black">
          <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
          <br />
          <b>{process.env.REACT_APP_COMPANY}</b>
        </div>
      </div>
    </div>
  );
};

export default TableCetakTemuanPerTem;
