import React, { useState, useEffect } from 'react';
import { Modal } from '@mantine/core';
import * as api from '../../redux/api';
import moment from 'moment/moment';
import { getDuration } from '../../utils/dateFormat';

export default function ModalTimeSheetDetail({ kode, openModalDetail, setOpenModalDetail }) {
  const [dataJadwal, setDataJadwal] = useState({});
  const [dataKehadiran, setDataKehadiran] = useState([]);

  useEffect(() => {
    if (kode !== '') {
      const getDataJadwalByKode = async () => {
        await api.jadwalDetail(kode).then((res) => {
          setDataJadwal(res.data.data[0]);
        });
      };

      getDataJadwalByKode();
      const getDataKehadiranByKodeJadwal = async () => {
        await api.kehadiranDetail(kode).then((res) => {
          setDataKehadiran(res.data.data);
        });
      };

      getDataKehadiranByKodeJadwal();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="lg"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Timesheet</div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 text-sm mb-2">
            <div className="md:w-3/5 w-full font-semibold">Nama Personil</div>
            <div className="w-full">{dataJadwal?.satpamnamalengkap}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 text-sm mb-2">
            <div className="md:w-3/5 w-full font-semibold">Jabatan Personil</div>
            <div className="w-full">{dataJadwal?.satpamjabatan}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 text-sm mb-2">
            <div className="md:w-3/5 w-full font-semibold">Status</div>
            <div className="w-full">
              {moment(dataJadwal.satpamjadwalkeluartanggal).isSameOrBefore(new Date()) === false &&
              dataJadwal.satpamjadwalstatus === '0'
                ? 'Belum Absen'
                : moment(dataJadwal.satpamjadwalkeluartanggal).isSameOrBefore(new Date()) ===
                    true && dataJadwal.satpamjadwalstatus === '0'
                ? 'Alfa'
                : dataJadwal.satpamjadwalstatus === '1'
                ? 'Sudah Clock In'
                : dataJadwal.satpamjadwalstatus === '2'
                ? 'Sudah Clock Out'
                : dataJadwal.satpamjadwalstatus === '3'
                ? 'Izin'
                : dataJadwal.satpamjadwalstatus === '4'
                ? 'Sakit'
                : 'Off'}
            </div>
          </div>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-dark ">
              <thead className="text-xs text-darkGray bg-gray-50 ">
                <tr>
                  <th scope="col" colSpan="2" className="py-2 px-2 border text-center">
                    Tanggal Masuk
                  </th>
                  <th scope="col" colSpan="2" className="py-2 px-2 border text-center">
                    Tanggal Keluar
                  </th>
                </tr>
                <tr>
                  <th scope="col" className="w-1/4 py-2 px-2 border text-center">
                    Jadwal
                  </th>
                  <th scope="col" className="w-1/4 py-2 px-2 border text-center">
                    Kehadiran
                  </th>
                  <th scope="col" className="w-1/4 py-2 px-2 border text-center">
                    Jadwal
                  </th>
                  <th scope="col" className="w-1/4 py-2 px-2 border text-center">
                    Kehadiran
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b">
                  {dataKehadiran?.length > 0 ? (
                    <>
                      <td className="py-2 px-2 border text-xs text-center">
                        {moment(dataJadwal.satpamjadwalmasuktanggal).format('DD MMM YYYY HH:mm') ||
                          '-'}
                      </td>
                      {dataKehadiran.filter(
                        (datafilter) => datafilter.satpamkehadiranjenis === 'Clock In'
                      ).length > 0 ? (
                        <td className="py-2 px-2 border text-xs text-center">
                          {dataKehadiran
                            .filter((datafilter) => datafilter.satpamkehadiranjenis === 'Clock In')
                            .map((data) => (
                              <div key={data.satpamkehadirankode}>
                                {moment(data.satpamkehadirantanggal).format('DD MMM YYYY HH:mm')}
                              </div>
                            ))}
                        </td>
                      ) : (
                        <td className="py-2 px-2 border text-xs text-center">-</td>
                      )}
                      <td className="py-2 px-2 border text-xs text-center">
                        {moment(dataJadwal.satpamjadwalkeluartanggal).format('DD MMM YYYY HH:mm') ||
                          '-'}
                      </td>
                      {dataKehadiran.filter(
                        (datafilter) => datafilter.satpamkehadiranjenis === 'Clock Out'
                      ).length > 0 ? (
                        <td className="py-2 px-2 border text-xs text-center">
                          {dataKehadiran
                            .filter((datafilter) => datafilter.satpamkehadiranjenis === 'Clock Out')
                            .map((data) => (
                              <div key={data.satpamkehadirankode}>
                                {moment(data.satpamkehadirantanggal).format('DD MMM YYYY HH:mm')}
                              </div>
                            ))}
                        </td>
                      ) : (
                        <td className="py-2 px-2 border text-xs text-center">-</td>
                      )}
                    </>
                  ) : (
                    <>
                      <td className="py-2 px-2 border text-xs text-center">
                        {dataJadwal.satpamjadwalmasuktanggal !== ''
                          ? moment(dataJadwal.satpamjadwalmasuktanggal).format('DD MMM YYYY HH:mm')
                          : '-'}
                      </td>
                      <td className="py-2 px-2 border text-xs text-center">-</td>
                      <td className="py-2 px-2 border text-xs text-center">
                        {dataJadwal.satpamjadwalkeluartanggal !== ''
                          ? moment(dataJadwal.satpamjadwalkeluartanggal).format('DD MMM YYYY HH:mm')
                          : '-'}
                      </td>
                      <td className="py-2 px-2 border text-xs text-center">-</td>
                    </>
                  )}
                </tr>
                <tr className="bg-white border-b">
                  {dataKehadiran.filter(
                    (datafilter) => datafilter.satpamkehadiranjenis === 'Clock In'
                  ).length > 0 ? (
                    <td colSpan="2" className="py-2 px-2 border text-xs text-center">
                      {dataKehadiran
                        .filter((datafilter) => datafilter.satpamkehadiranjenis === 'Clock In')
                        .map((data) => (
                          <div key={data.satpamkehadirantanggal}>
                            {moment(dataJadwal.satpamjadwalmasuktanggal).format(
                              'YYYY-MM-DD HH:mm'
                            ) === moment(data.satpamkehadirantanggal).format('YYYY-MM-DD HH:mm')
                              ? ''
                              : dataJadwal.satpamjadwalmasuktanggal > data.satpamkehadirantanggal
                              ? 'Lebih cepat '
                              : 'Terlambat '}
                            {getDuration(
                              moment(dataJadwal.satpamjadwalmasuktanggal).format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              moment(data.satpamkehadirantanggal).format('YYYY-MM-DD HH:mm')
                            )}
                          </div>
                        ))}
                    </td>
                  ) : (
                    <td colSpan="2" className="py-2 px-2 border text-xs text-center">
                      Belum Clock In
                    </td>
                  )}
                  {dataKehadiran.filter(
                    (datafilter) => datafilter.satpamkehadiranjenis === 'Clock Out'
                  ).length > 0 ? (
                    <td colSpan="2" className="py-2 px-2 border text-xs text-center">
                      {dataKehadiran
                        .filter((datafilter) => datafilter.satpamkehadiranjenis === 'Clock Out')
                        .map((data) => (
                          <div key={data.satpamkehadirantanggal}>
                            {moment(dataJadwal.satpamjadwalkeluartanggal).format(
                              'YYYY-MM-DD HH:mm'
                            ) === moment(data.satpamkehadirantanggal).format('YYYY-MM-DD HH:mm')
                              ? ''
                              : dataJadwal.satpamjadwalkeluartanggal > data.satpamkehadirantanggal
                              ? 'Lebih cepat '
                              : 'Terlambat '}
                            {getDuration(
                              moment(dataJadwal.satpamjadwalkeluartanggal).format(
                                'YYYY-MM-DD HH:mm'
                              ),
                              moment(data.satpamkehadirantanggal).format('YYYY-MM-DD HH:mm')
                            )}
                          </div>
                        ))}
                    </td>
                  ) : (
                    <td colSpan="2" className="py-2 px-2 border text-xs text-center">
                      Belum Clock Out
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-5">
            <div
              className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer"
              onClick={() => setOpenModalDetail(false)}>
              Kembali
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
