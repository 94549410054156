function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Juni',
  'Juli',
  'Agt',
  'Sep',
  'Okt',
  'Nov',
  'Des'
];

export const formatDateIndo = (date) =>
  padTo2Digits(date.getDate()) +
  ' ' +
  monthNames[date.getMonth()] +
  ' ' +
  date.getFullYear() +
  ' ' +
  padTo2Digits(date.getHours() + ':' + padTo2Digits(date.getMinutes()));

export function formatDateForValue(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function getDuration(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

  let hh = Math.floor(timeDiff / 1000 / 60 / 60);
  // hh = ('0' + hh).slice(-2);

  timeDiff -= hh * 1000 * 60 * 60;
  let mm = Math.floor(timeDiff / 1000 / 60);
  // mm = ('0' + mm).slice(-2);

  timeDiff -= mm * 1000 * 60;
  // let ss = Math.floor(timeDiff / 1000);
  // ss = ('0' + ss).slice(-2);

  if (hh === 0 && mm !== 0) {
    return mm + ' menit';
  } else if (mm === 0) {
    return 'Tepat waktu';
  } else {
    return hh + ' jam ' + mm + ' menit';
  }
}
