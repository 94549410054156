import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { AiFillPrinter } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { renderToString } from 'react-dom/server';
import { RiMailSendFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
// Import files
import KegiatanHarianCetak from '../../components/kegiatan-harian/KegiatanHarianCetak';
import ModalSendLaporan from '../../components/general/ModalSendLaporan';
import TableCetakKegiatan from '../../components/kegiatan-harian/TableCetakKegiatan';
import TableCetakKegiatanMail from '../../components/kegiatan-harian/TableCetakKegiatanMail';

export default function KegiatanHarianPrint() {
  const [openModalSend, setOpenModalSend] = useState(false);
  const [htmlString, setHtmlString] = useState('');
  const [data, setData] = useState([]);
  const [date, setDate] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();

  useEffect(() => {
    if (location.state) {
      setData(location.state.data);
      setDate(location.state.date);
    }
    setHtmlString(renderToString(<TableCetakKegiatanMail data={data} />));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <div className="absolute top-0 right-0 bottom-0 z-20 h-screen w-screen overflow-y-auto bg-white text-dark">
        <div className="flex p-8 justify-center items-center flex-col text-center">
          <div className="font-bold text-xl">Preview Cetak Laporan Harian</div>
          <h4 className="text-md p-2">
            <span className="font-bold">Periode : </span>
            {moment(date.startDate).format('DD MMM YYYY')} s.d{' '}
            {moment(date.endDate).format('DD MMM YYYY')}
          </h4>
          <div className="flex gap-3 mb-3">
            <ReactToPrint
              trigger={() => (
                <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
                  <AiFillPrinter size={18} color="white" />
                </button>
              )}
              content={() => componentRef.current}
            />
            <div className="hidden">
              <KegiatanHarianCetak refPropWithAnotherName={componentRef} data={data} date={date} />
            </div>
            <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
              <RiMailSendFill size={18} color="white" onClick={() => setOpenModalSend(true)} />
            </button>
            <button
              className="btnAksi h-fit py-[7px] mt-1 text-[13px]"
              onClick={() => navigate(-1)}>
              Kembali
            </button>
          </div>
          <div className="w-full border-4 border-primary p-3">
            <TableCetakKegiatan data={data} />
          </div>
          <ModalSendLaporan
            htmlString={htmlString}
            openModalSend={openModalSend}
            setOpenModalSend={setOpenModalSend}
          />
        </div>
      </div>
    </>
  );
}
