import React from 'react';
import TableCetakTemuan from './TableCetakTemuan';

export default function TemuanCetak({ refPropWithAnotherName, data, date }) {
  return (
    <div className="w-full bg-white text-[12px]" ref={refPropWithAnotherName}>
      <TableCetakTemuan data={data} date={date} />
    </div>
  );
}
