import React from 'react';
import * as api from '../../redux/api';
import { useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { TbFileImport } from 'react-icons/tb';
import { Modal, Tooltip } from '@mantine/core';
import excel from '../../images/format_import.xlsx';
import Swal from 'sweetalert2';

const ModalImportSatpam = ({ getDataSatpam }) => {
  const [showModalImport, setShowModalImport] = useState(false);
  const [file, setFile] = useState('');

  const handleCloseImpor = (e) => {
    setShowModalImport(false);
    setFile('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await api.imporSatpam({ file });
      if (res.data.status) {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: res.data.message,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            getDataSatpam();
          }
        });
      }
      // Swal.fire("Good job!", res.data.message, "success");
    } catch (error) {
      if (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      }
    }
  };

  return (
    <>
      <Tooltip label="Impor data">
        <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80" onClick={() => setShowModalImport(!showModalImport)}>
          <TbFileImport size={24} className="text-gray-700" />
        </div>
      </Tooltip>
      <Modal opened={showModalImport} centered onClose={() => setShowModalImport(false)}>
        <div className="text-center text-lg font-bold mb-5">Impor Personil Data</div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center justify-center gap-3 mb-5">
            <input type="file" className="mt-3 mb-2 ml-10 text-xs" name="file" onChange={(e) => setFile(e.target.files[0])} />
          </div>
          {file && (
            <div className="flex justify-between items-center w-full py-5 px-10 mt-5 mb-2">
              <button type="submit" className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Impor data
              </button>
              <button className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80" onClick={handleCloseImpor}>
                Kembali
              </button>
            </div>
          )}
        </form>
        <div className="flex justify-center">
          {!file && (
            <a href={excel} download="template_impor_personil">
              <button className="btnAksi text-xs py-2">
                <CgFileDocument /> &nbsp; Download template excel
              </button>
            </a>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalImportSatpam;
