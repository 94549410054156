import React from 'react';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import 'react-datepicker/dist/react-datepicker.css';

export const InputTime = ({ value, selected, label, onchange, interval, ...props }) => {
  return (
    <div className="flex flex-col gap-2 w-full">
      <p className="block font-semibold">{label}</p>
      <div className="relative block w-full">
        <DatePicker
          selected={selected}
          className="w-full text-sm cursor-pointer inputForm"
          value={value}
          onChange={onchange}
          locale={id}
          placeholderText="HH:mm"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={interval}
          timeCaption="Time"
          dateFormat="HH:mm"
          required
          {...props}
        />
        <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
          <i className="far fa-calendar"></i>
        </div>
      </div>
    </div>
  );
};

export const InputDate = ({ value, selected, className, label, onchange, ...props }) => {
  return (
    <div className="flex flex-col gap-2 w-full">
      <p className="block font-semibold">{label}</p>
      <div className="relative block w-full">
        <DatePicker
          selected={selected}
          className={className}
          value={value}
          onChange={onchange}
          locale={id}
          dateFormat="dd/MM/yyyy"
          placeholderText="dd/MM/yyyy"
          required
          {...props}
        />
        <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
          <i className="far fa-calendar"></i>
        </div>
      </div>
    </div>
  );
};
