// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { AiOutlineFileAdd } from 'react-icons/ai';
import moment from 'moment';
import { HiOutlinePrinter } from 'react-icons/hi';
import TimesheetSatpam from '../../components/timesheet/TimesheetSatpam';
import ModalPrintTimeSheet from '../../components/timesheet/ModalTimeSheetPrint';

export default function TimeSheet() {
  const [loading, setLoading] = useState(false);
  const [dataJadwal, setDataJadwal] = useState([]);
  const [headerDate, setHeaderDate] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [openModalPrint, setOpenModalPrint] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]); // Date
  const [date, setDate] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  });

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((res) => {
        res.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [
            ...prev,
            { value: data.satpamkode, label: data.satpamnamalengkap }
          ]);
        });
      });
    };
    getDataSatpam();
  }, []);

  useEffect(() => {
    setLoading(true);

    const getDataJadwal = async (formData) => {
      await api
        .kehadiranDataByPersonil(formData)
        .then((res) => {
          setDataJadwal(res.data);
          setLoading(false);
        })
        .catch((err) => {});
    };

    getDataJadwal({
      startdate: date.startDate,
      enddate: date.endDate,
      satpamkode: satpamKode
    });

    let start = moment(date.startDate, 'YYYY-MM-DD');
    let end = moment(date.endDate, 'YYYY-MM-DD');

    let data = [];
    for (let m = start; m.isSameOrBefore(end); m.add(1, 'days')) {
      let arr = {};
      arr.value = moment(m).format('DD');
      arr.fullDate = moment(m).format('YYYY-MM-DD');
      data.push(arr);
    }
    setHeaderDate(data);
  }, [date, satpamKode]);

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="mb-5 w-full min-h-screen justify-center bg-gray-50 rounded-lg shadowAll">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
              {/* <FilterDate
                date={date}
                defaultType="this month"
                setDate={setDate}
                showDate={showDate}
                setShowDate={setShowDate}
              /> */}
              <div className="px-5">
                Nama Personil :{' '}
                <span className="font-semibold">
                  {satpamDataOptions.find((option) => option.value === satpamKode)?.label}
                </span>
              </div>
            </div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Tambah Jadwal">
                  <Link to="/personil/jadwal/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
                <Tooltip label="Cetak">
                  <div
                    className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700"
                    onClick={() => setOpenModalPrint(true)}>
                    <HiOutlinePrinter size={24} />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Table Component */}
          <TimesheetSatpam
            loading={loading}
            dataJadwal={dataJadwal}
            headerDate={headerDate}
            satpamDataOptions={satpamDataOptions}
            satpamKode={satpamKode}
            setSatpamKode={setSatpamKode}
            date={date}
            setDate={setDate}
          />

          <ModalPrintTimeSheet
            openModalPrint={openModalPrint}
            setOpenModalPrint={setOpenModalPrint}
            satpamDataOptions={satpamDataOptions}
            date={date}
          />
        </div>
      </div>
    </div>
  );
}
