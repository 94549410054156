import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Popover, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
// Import files
import * as api from '../../redux/api';
import Cards from '../../components/general/Cards';
import LoadingPage from '../../components/general/LoadingPage';
import ModalImportSatpam from '../../components/satpam/ModalImportSatpam';
import ModalSatpamCetak from '../../components/satpam/ModalSatpamCetak';
import ModalSatpamDetail from '../../components/satpam/ModalSatpamDetail';
import Table from '../../components/general/Table';
import fotoSatpam from '../../images/satpam.jpg';

export default function SatpamData({ satpamData, satpamJumlah }) {
  const [data, setData] = useState(['']);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [query, setQuery] = useState('');
  const [satpamDetailId, setSatpamDetailId] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  useEffect(() => {
    const getData = async () => {
      await api.satpamData().then((response) => {
        setData(response.data.data);
        setDatas(response.data);
      });
    };

    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(({ satpamkode, satpamstatus, satpamnamalengkap, satpamtipe, satpamjabatan, satpamnohp }) => {
        if (
          debouncedQuery !== '' &&
          !`${satpamkode} ${satpamstatus} ${satpamnamalengkap} ${satpamtipe} ${satpamjabatan} ${satpamnohp}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  const getData = async () => {
    await api.satpamData().then((response) => {
      setData(response.data?.data);
      setDatas(response.data);
    });
  };

  const handleDelete = (formData) => {
    Swal.fire({
      text: 'Do you want to delete this personil?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .satpamDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  const handleReset = (formData) => {
    Swal.fire({
      text: 'Do you want to reset this personil?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const resetData = async () => {
          const res = await api.satpamReset(formData);
          if (res.data.status) {
            // window.location.reload();
            Swal.fire('Good job!', res.data.message, 'success');
          } else
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.data.Message
            });
        };
        resetData();
      }
    });
  };

  const handleDetail = (id) => {
    setSatpamDetailId(id);
    setOpenModalDetail(true);
  };

  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamid',
      width: 100,
      render: ({ satpamid }) => (
        <>
          <Popover width={110} position="right" withArrow shadow="lg">
            <Popover.Target>
              <div className="btnAksi py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div className="popOverItem" onClick={() => handleDetail(satpamid)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/personil/update/${satpamid}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen-to-square"></i> Update
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleReset({ satpamid: satpamid })}>
                <i className="fa-solid fa-power-off"></i> Reset
              </div>
              <div className="popOverItem" onClick={() => handleDelete({ satpamid: satpamid })}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </>
      )
    },
    // {
    //   title: 'Kode',
    //   accessor: 'satpamkode',
    //   width: 150
    // },
    {
      title: 'Nama Lengkap',
      accessor: 'satpamnamalengkap',
      width: 200
    },
    // { title: 'Tipe', accessor: 'satpamtipe', width: 125 },
    {
      title: 'Jabatan',
      accessor: 'satpamjabatan',
      width: 150
    },
    { title: 'No. Hp', accessor: 'satpamnohp', width: 150 },
    {
      title: 'Foto Personil',
      accessor: 'satpamfoto',
      width: 175,
      render: ({ satpamfoto }) => (
        <>
          <img
            src={satpamfoto}
            alt="Satpam Foto"
            className="h-[125px] w-[125px] object-cover my-2 rounded-lg"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = fotoSatpam;
            }}
          />
        </>
      )
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className="relative px-4 pb-10 md:px-10 mx-auto w-full -mt-8">
      <div className="w-full">
        <Cards
          title="Statistik Data Personil"
          desc="Berikut ini adalah jumlah personil yang terdaftar"
          card1={{ title: 'Utama', value: datas?.satpam_utama || 0 }}
          card2={{ title: 'Cadangan', value: datas?.satpam_cadangan || 0 }}
          card3={{ title: 'Personil', value: datas?.total_satpam || 0 }}
        />

        {/* Table Component */}
        <div className="mt-10 w-full bg-light p-5 rounded-lg shadowAll">
          <div className="flex justify-end mb-3">
            <div className="flex gap-4 w-fit">
              <ModalSatpamCetak />
              {adminType === 'Admin Project' && (
                <>
                  <ModalImportSatpam getData={getData} />
                  <Tooltip label="Tambah data">
                    <Link to="/personil/registrasi">
                      <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                        <AiOutlineFileAdd size={24} />
                      </div>
                    </Link>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {data.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data personil.</div>
            </div>
          )}
        </div>
        <ModalSatpamDetail id={satpamDetailId} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
      </div>
    </div>
  );
}
