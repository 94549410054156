import React from 'react';
import TimelineSatpamProses from '../general/TimelineSatpamProses';
import { parseToNewLine } from '../../utils/parseToNewLine';
import moment from 'moment';
import Headerlaporan from '../general/HeaderLaporan';

const TableCetakKegiatanPerKeg = ({ data }) => {
  return (
    <div className="min-w-1024">
      <div className="flex w-full">
        <Headerlaporan />
        <div className="w-full px-5 bg-dark flex flex-col items-end justify-center text-white">
          <div className="text-gray-100 text-[18px] font-bold">LAPORAN KEGIATAN HARIAN</div>
          <div className="text-gray-100 text-[15px] font-bold">{data.satpamperusahaannama}</div>
        </div>
      </div>
      <div className="m-5">
        <table className="border-gray-50" id="kegiatanHarianPrint">
          <thead className="bg-primary font-bold text-white text-[12px]">
            <tr>
              <th colSpan="3" className="border border-gray-300 px-2 py-2 text-left">
                <p className="font-semibold text-[18px]">#{data?.satpamkegiatankode}</p>
              </th>
            </tr>
          </thead>
          <tbody className="text-black">
            <tr>
              <td className="w-[30%] border border-gray-300 px-2 py-2 text-left text-primary font-semibold">
                {moment(data?.satpamkegiatantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
              </td>
              <td className="border border-gray-300 px-2 py-2 text-left">
                <span className="font-bold">KATEGORI</span> : {data?.satpamkegiatankategorinama}
              </td>
              <td rowSpan="4" className="w-[20%] border border-gray-300 px-2 py-2 text-left align-top">
                <h4 className="font-semibold mb-2">DETAIL PROSES</h4>
                <TimelineSatpamProses data={data.satpam_proses ? data.satpam_proses : ''} />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-2 py-2 text-left">
                PATOKAN LOKASI : {data?.satpamkegiatanalamatpatokan}
                <br /> <br />
                <span className="font-semibold">ALAMAT LENGKAP</span> : {data?.satpamkegiatanalamat}
              </td>
              <td rowSpan="2" className="border border-gray-300 px-2 py-2 text-left align-top">
                <h4 className="font-semibold mb-2">URAIAN KEGIATAN</h4>
                <p className="mb-3">{parseToNewLine(data?.satpamkegiatanuraian)}</p>
                <h4 className="font-semibold mb-2">KETERANGAN</h4>
                {parseToNewLine(data?.satpamkegiatanketerangan)}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-2 py-2 text-left">
                <h4 className="text-md font-bold">
                  PELAPOR <br /> {data?.satpamnamalengkap}
                </h4>
              </td>
            </tr>
            <tr>
              <td colSpan="2" rowSpan="2" className="border border-gray-300 p-5">
                <div className="mx-auto">
                  <div className="flex flex-wrap -m-1 md:-m-2">
                    {data.satpamkegiatanfotosatu?.split('/data-kegiatan/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all rounded-t-md ">
                            <img className="rounded-t-md h-36 object-contain w-full" src={data?.satpamkegiatanfotosatu} alt="" />
                            <div className="p-3">
                              <p className="mb-2">{data?.satpamkegiatanketeranganfotosatu}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.satpamkegiatanfotodua?.split('/data-kegiatan/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img className="rounded-t-md h-36 object-contain w-full" src={data?.satpamkegiatanfotodua} alt="" />
                            <div className="p-3">
                              <p className="mb-2">{data?.satpamkegiatanketeranganfotodua}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.satpamkegiatanfototiga?.split('/data-kegiatan/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img className="rounded-t-md h-36 object-contain w-full" src={data?.satpamkegiatanfototiga} alt="" />
                            <div className="p-3">
                              <p className="mb-2">{data?.satpamkegiatanketeranganfototiga}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.satpamkegiatanfotoempat?.split('/data-kegiatan/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img className="rounded-t-md h-36 object-contain w-full" src={data?.satpamkegiatanfotoempat} alt="" />
                            <div className="p-3">
                              <p className="mb-2">{data?.satpamkegiatanketeranganfotoempat}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.satpamkegiatanfotolima?.split('/data-kegiatan/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img className="rounded-t-md h-36 object-contain w-full" src={data?.satpamkegiatanfotolima} alt="" />
                            <div className="p-3">
                              <p className="mb-2">{data?.satpamkegiatanketeranganfotolima}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="w-[20%] border border-gray-300 px-2 py-2 text-center align-middle">
                <div>Tanda Tangan</div>
                <div className="border mt-28 mb-10 mx-5" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-center w-full my-2 text-black">
        <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
        <br />
        <b>{process.env.REACT_APP_COMPANY}</b>
      </div>
      <div className="page-break" />
    </div>
  );
};

export default TableCetakKegiatanPerKeg;
