import React from 'react';
import TableCetakKegiatanPerKeg from './TableCetakKegiatanPerKeg';

export default function KegiatanHarianCetak({ refPropWithAnotherName, data }) {
  // console.log(data);
  return (
    <div
      className="w-full bg-white text-[12px]"
      id="kegiatanHarianPrint"
      ref={refPropWithAnotherName}>
      <TableCetakKegiatanPerKeg data={data} />
    </div>
  );
}
