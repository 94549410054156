import moment from 'moment';
import React from 'react';
import Headerlaporan from '../general/HeaderLaporan';
import { Loader } from '@mantine/core';
import RiawayatJalanKaki from './RiwayatPatroliJalankaki';

export default function TableCetakPatroli({ dataPatroli, date, isFethcing, isError, refPropWithAnotherName, id }) {
  const percetageKemajuan = (datatotal, datakemajuan) => {
    let total = 0 || datatotal;
    let kemajuan = 0 || datakemajuan;
    const result = (total / kemajuan) * 100;
    if (kemajuan <= 0) {
      return 0;
    }
    return result;
  };

  return (
    <>
      <div className="w-full" ref={refPropWithAnotherName} id={id}>
        {dataPatroli.map((data) => (
          <div key={data?.satpampatrolikode}>
            {typeof date !== 'undefined' && (
              <h4 className="text-md p-2">
                <span className="font-bold">Periode : </span>
                {moment(date.startDate).format('DD MMM YYYY')} s.d {moment(date.endDate).format('DD MMM YYYY')}
              </h4>
            )}

            <div className="flex w-full">
              <Headerlaporan />
              <div className="w-full px-5 bg-black flex flex-col items-end justify-center text-white">
                <div className="text-[18px] font-bold">LAPORAN PATROLI</div>
                <div className="text-[14px]">{data?.perusahaan_nama}</div>
              </div>
            </div>
            <div className="m-5">
              <table className="border-gray-50 w-full" id="kegiatanHarianPrint">
                <thead className="bg-primary font-bold text-white text-[12px]">
                  <tr>
                    <th colSpan="4" className="border border-gray-300 py-2 px-2 text-left">
                      <div className="flex justify-between px-2 uppercase">
                        <p className="font-semibold text-[18px]">#{data?.satpampatrolikode}</p>
                        <p className="font-semibold text-[18px]">{data?.satpampatrolijenis}</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  <tr>
                    <td colSpan="2" className="!h-[10px] border border-gray-300 px-2 py-2 text-left font-semibold">
                      <div className="flex gap-2 justify-between uppercase">
                        <p>NAMA PERSONIL</p>
                        <p>{data?.satpam_nama}</p>
                      </div>
                    </td>
                    <td colSpan="2" rowSpan={data?.detail?.satpampatrolijenis === 'Patroli Berkendara' ? 6 : 2} className="px-2 border border-gray-300 py-2 text-left">
                      <h4 className="font-semibold mb-2 uppercase">Rute Patroli</h4>
                      <div className="w-full overflow-x-auto">
                        <table className="table-auto leading-normal w-full">
                          <thead>
                            <tr>
                              <th colSpan="3" className="px-2 py-1 border align-middle border-gray-200 bg-primary text-white text-sm font-semibold text-center tracking-wider">
                                {data?.detail?.satpamposnama}
                              </th>
                            </tr>
                            <tr>
                              <th className="px-2 py-1 border align-middle border-gray-200 text-sm font-semibold text-center tracking-wider">CHECKPOINT</th>
                              <th className="px-2 py-1 border align-middle border-gray-200 text-sm font-semibold text-center tracking-wider">TARGET</th>
                              <th className="px-2 py-1 border align-middle border-gray-200 text-sm font-semibold text-center tracking-wider">KEMAJUAN</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.detail?.length > 0 &&
                              data?.detail?.map((rute_patroli) => (
                                <tr key={rute_patroli?.satpampatrolidetailid}>
                                  <td className="px-2 py-1 border border-gray-200 bg-white text-sm">
                                    <p className="text-center">{rute_patroli?.checkpoint?.satpamtitiknama}</p>
                                  </td>
                                  <td className="px-2 py-1 border border-gray-200 bg-white text-sm">
                                    <p className="text-center">{rute_patroli.satpampatrolidetailtarget}</p>
                                  </td>
                                  <td className="px-2 py-1 border border-gray-200 bg-white text-sm">
                                    <p className="text-center">{percetageKemajuan(rute_patroli.satpampatrolidetailtarget, rute_patroli.satpampatrolidetailkemajuan)}%</p>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/4 border border-gray-300 px-2 py-2 text-left font-semibold align-top whitespace-nowrap">
                      <div className="flex gap-2 justify-between">
                        <p>WAKTU MULAI</p>
                        <p className="text-primary">{data.satpammulaitanggal !== null ? moment(data.satpammulaitanggal).format('DD MMM YYYY HH:mm') : '-'}</p>
                      </div>
                      <div className="flex gap-2 justify-between">
                        <p>WAKTU AKHIR</p>
                        <p className="text-primary">
                          {data?.satpampatroliselesaitanggal !== null ? moment(data?.satpampatroliselesaitanggal).format('DD MMM YYYY HH:mm') : '-'}
                        </p>
                      </div>
                      <div className="flex gap-2 justify-between">
                        <p>WAKTU PATROLI</p>
                        {/* <p>{calculateTimePatrol(data?.satpampatrolimulaitanggal, data?.satpampatroliselesaitanggal)}</p> */}
                      </div>
                    </td>
                    <td className="w-1/5 border border-gray-300 px-2 py-2 text-left font-semibold align-top" height="5px">
                      <div className="flex gap-2 justify-between">
                        <p>TARGET</p>
                        <p>{data?.detail?.target_kumulatif}X</p>
                      </div>
                      <div className="flex gap-2 justify-between">
                        <p>KEMAJUAN</p>
                        <p>{data?.satpampatrolikemajuan}%</p>
                      </div>
                    </td>
                  </tr>
                  {data?.detail?.satpampatrolijenis === 'Patroli Berkendara' && (
                    <>
                      <tr>
                        <td colSpan="2" className="w-1/5 border border-gray-300 px-2 py-2 text-left font-semibold" height="5px">
                          <div className="flex gap-2 justify-between">
                            <p>JENIS KENDARAAN</p>
                            <p>{data?.detail?.satpampatrolikendaraanjenis}</p>
                          </div>
                          <div className="flex gap-2 justify-between">
                            <p>NAMA KENDARAAN</p>
                            <p>{data?.detail?.satpampatrolikendaraannama}</p>
                          </div>
                          <div className="flex gap-2 justify-between">
                            <p>NOMOR KENDARAAN</p>
                            <p>{data?.detail?.satpampatrolikendaraannomor}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/4 border border-gray-300 px-2 py-2 text-left font-semibold" height="5px">
                          <p className="text-center">KILOMETER AWAL</p>
                        </td>
                        <td className="w-1/5 border border-gray-300 px-2 py-2 text-left font-semibold" height="5px">
                          <p className="text-center">KILOMETER AKHIR</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/4 border border-gray-300 px-2 py-2 text-left font-semibold" height="5px">
                          <div className="flex gap-2 justify-arround items-center px-4">
                            <div className="w-1/2">{data?.detail?.satpampatrolikilometerawalangka}</div>
                            {data?.detail?.satpampatrolikilometerawalfoto.split('/data-patroli/')[1] && (
                              <div className="flex w-1/2">
                                <div className="w-full">
                                  <div className="h-24 w-full bg-white rounded-md border border-gray-200 shadow-md break-all">
                                    <img
                                      className="rounded h-24 object-cover w-full"
                                      srcSet="small-image.jpg 300w, medium-image.jpg 600w, large-image.jpg 900w"
                                      src={data?.detail?.satpampatrolikilometerawalfoto}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="w-1/4 border border-gray-300 px-2 py-2 text-left font-semibold" height="5px">
                          <div className="flex gap-2 justify-arround items-center px-4">
                            <div className="w-1/2">{data?.detail?.satpampatrolikilometerakhirangka}</div>
                            {data?.detail?.satpampatrolikilometerakhirfoto.split('/data-patroli/')[1] && (
                              <div className="flex w-1/2">
                                <div className="w-full">
                                  <div className="h-24 w-full bg-white rounded-md border border-gray-200 shadow-md break-all">
                                    <img className="rounded h-24 object-cover w-full" src={data?.detail?.satpampatrolikilometerakhirfoto} alt="" />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" className="w-1/5 border border-gray-300 px-2 py-2 text-left font-semibold" height="5px">
                          <div className="flex gap-2 justify-between">
                            <p>JARAK (KM AWAL - KM AKHIR)</p>
                            <p>{data?.detail?.satpampatrolikilometerakhirangka - data?.detail?.satpampatrolikilometerawalangka} KM</p>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td colSpan="4" className="min-w-full border border-gray-300 px-2 py-2 text-left" height="5px">
                      <h4 className="font-semibold my-2 text-center">RIWAYAT PATROLI</h4>
                      <RiawayatJalanKaki rutePatroli={data?.detail} />
                      {/* <div className="page-break" /> */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="border border-gray-300 px-2 py-2 text-center align-top"></td>
                    <td className="border border-gray-300 px-2 py-2 text-center align-middle">
                      <div>Tanda Tangan</div>
                      <div className="border mt-16 mb-5 mx-5" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-center w-full my-2 text-black">
                <b>Copyright&copy;{process.env.REACT_APP_YEAR}</b>
                <br />
                <b>{process.env.REACT_APP_COMPANY}</b>
              </div>
            </div>
            <div className="page-break" />
          </div>
        ))}
      </div>
      {isFethcing && <Loader color="blue" className="mx-auto my-20" />}
      {isError && <h1 className="text-red-500 text-lg text-justify">Error{isError.message}</h1>}
    </>
  );
}
