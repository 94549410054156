import React, { useState } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { keluhanSchema } from '../../utils/schemaValidation';
import Swal from 'sweetalert2';

export default function ModalKeluhanCreate({
  getDataKeluhan,
  dataKategori,
  openModalKeluhanCreate,
  setOpenModalKeluhanCreate
}) {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const namaAdmin = profile?.user?.adminpetugasnamalengkap;

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [tanggalKeluhan, setTanggalKeluhan] = useState(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(keluhanSchema)
  });

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    data.satpamkeluhantanggal = moment(tanggalKeluhan).format('YYYY-MM-DDTHH:mm');

    await api
      .createKeluhan(data)
      .then((res) => {
        getDataKeluhan();
        setOpenModalKeluhanCreate(false);
        reset();
        setTanggalKeluhan(new Date());
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoadingSubmit(false);
      })
      .catch((error) => {
        setLoadingSubmit(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <Modal
      opened={openModalKeluhanCreate}
      size="lg"
      centered
      closeOnClickOutside
      onClose={() => {
        setOpenModalKeluhanCreate(false);
        reset();
      }}>
      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="text-center text-lg font-bold mb-1">Tambah Keluhan</div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col p-5">
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamkeluhantanggal" className="block font-semibold mb-1 md:mb-0">
                Tanggal
              </label>
            </div>
            <div className="md:w-2/3">
              <DatePicker
                selected={tanggalKeluhan}
                className={`w-full text-sm cursor-pointer inputForm`}
                onChange={(date) => setTanggalKeluhan(date)}
                locale={id}
                showTimeInput
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                placeholderText="dd/MM/yyyy HH:mm"
                required
              />
            </div>
          </div>
          <div className="md:flex items-center mb-2">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Nama Admin</label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                className={`w-full ${errors?.satpamkeluhanadminnama ? 'invalidForm' : 'inputForm'}`}
                defaultValue={namaAdmin}
                {...register('satpamkeluhanadminnama')}
              />
              <p className="text-errorText text-xs">{errors.satpamkeluhanadminnama?.message}</p>
            </div>
          </div>
          <div className="md:flex items-center mb-2">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Kategori</label>
            </div>
            <div className="md:w-2/3">
              <select
                className={`w-full ${
                  errors?.satpamkeluhansatpamkeluhankategorikode ? 'invalidForm' : 'inputForm'
                }`}
                {...register('satpamkeluhansatpamkeluhankategorikode')}>
                <option value="">Pilih kategori</option>
                {dataKategori?.map((kategori) => (
                  <option
                    key={kategori.satpamkeluhankategorikode}
                    value={kategori.satpamkeluhankategorikode}>
                    {kategori.satpamkeluhankategorinama}
                  </option>
                ))}
              </select>
              <p className="text-errorText text-xs">
                {errors.satpamkeluhansatpamkeluhankategorikode?.message}
              </p>
            </div>
          </div>
          <div className="md:flex mb-2">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Status</label>
            </div>
            <div className="md:w-2/3">
              <select
                className={`w-full ${errors?.satpamkeluhanstatus ? 'invalidForm' : 'inputForm'}`}
                {...register('satpamkeluhanstatus')}>
                <option value="">Pilih status</option>
                <option value="0">Belum selesai</option>
                <option value="1">Selesai</option>
              </select>
              <p className="text-errorText text-xs">{errors.satpamkeluhanstatus?.message}</p>
            </div>
          </div>
          <div className="md:flex mb-2">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Isi</label>
            </div>
            <div className="md:w-2/3">
              <textarea
                rows={3}
                className={`w-full ${errors?.satpamkeluhanisi ? 'invalidForm' : 'inputForm'}`}
                {...register('satpamkeluhanisi')}
              />
              <p className="text-errorText text-xs">{errors.satpamkeluhanisi?.message}</p>
            </div>
          </div>

          <div className="flex justify-center gap-5 mt-5 items-center w-full">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loadingSubmit === true ? true : false}>
              {loadingSubmit ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <button
              type="button"
              className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              onClick={() => {
                setOpenModalKeluhanCreate(false);
                reset();
              }}>
              Kembali
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
