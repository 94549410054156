import React from 'react';
import TableCetakKegiatan from './TableCetakKejadian';

export default function KejadianCetak({ refPropWithAnotherName, data, date }) {
  return (
    <div
      className="w-full bg-white text-[12px]"
      id="kegiatanHarianPrint"
      ref={refPropWithAnotherName}>
      <TableCetakKegiatan data={data} date={date} />
    </div>
  );
}
