import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
// Import files
import * as api from '../../redux/api';
import BtnAdd from '../../components/general/BtnAdd';
import Cards from '../../components/general/Cards';
import CardDashboard from '../../components/dashboard/CardDashboard';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import LoadingPage from '../../components/general/LoadingPage';
import ModalKegiatanHarianDetail from '../../components/kegiatan-harian/ModalKegiatanHarianDetail';
import ReadMore from '../../components/general/ReadMore';
import ShowModalImage from '../../components/general/ShowModalImage';
import ShowTable from '../../components/general/ShowTable';

export default function KegiatanHarian({ isAdmin }) {
  const [data, setData] = useState(['']);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [imageData, setImageData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [kategori, setKategori] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [kegiatanDetailKode, setKegiatanDetailKode] = useState('');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [tipeLaporan, setTipeLaporan] = useState('');
  const [kategoriCount, setKategoriCount] = useState(['']);
  const location = useLocation();
  const { perusahaanKode } = useSelector((state) => state.perusahaan);

  // Date
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  // Get data
  const getData = async (formData) => {
    await api.kegiatanHarian(formData).then((res) => {
      setData(res.data.data);
      setDatas(res.data);
      setLoading(false);
    });
  };

  const getCount = async (formData) => {
    await api.countHarian(formData).then((res) => {
      setKategoriCount(res.data.data);
    });
  };

  useEffect(() => {
    if (location?.state?.date) {
      setDate(location?.state?.date);
      setKategori(location?.state?.kategori);
      setTipeLaporan(location?.state?.tipeLaporan);
    }
  }, [location?.state]);

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpamkegiatansatpamkegiatankategorikode: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    setLoading(true);

    getData(formDate);
    getCount(formDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, kategori, perusahaanKode]);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({
          satpamkegiatantanggalmelaporkan,
          satpamkegiatankode,
          satpamkegiatankategorinama,
          satpamnamalengkap,
          satpamkegiatanuraian,
          satpamkegiatanketerangan,
          satpamkegiatanalamat
        }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamkegiatantanggalmelaporkan} ${satpamkegiatankode} ${satpamkegiatankategorinama} ${satpamnamalengkap} ${satpamkegiatanuraian} ${satpamkegiatanketerangan} ${satpamkegiatanalamat}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  const handleImageData = ({
    satpamkegiatanfotosatu,
    satpamkegiatanketeranganfotosatu,
    satpamkegiatanfotodua,
    satpamkegiatanketeranganfotodua,
    satpamkegiatanfototiga,
    satpamkegiatanketeranganfototiga,
    satpamkegiatanfotoempat,
    satpamkegiatanketeranganfotoempat,
    satpamkegiatanfotolima,
    satpamkegiatanketeranganfotolima
  }) => {
    setImageData([
      {
        image: satpamkegiatanfotosatu?.split('/data-kegiatan/')[1] && satpamkegiatanfotosatu,
        keterangan: satpamkegiatanketeranganfotosatu
      },
      {
        image: satpamkegiatanfotodua?.split('/data-kegiatan/')[1] && satpamkegiatanfotodua,
        keterangan: satpamkegiatanketeranganfotodua
      },
      {
        image: satpamkegiatanfototiga?.split('/data-kegiatan/')[1] && satpamkegiatanfototiga,
        keterangan: satpamkegiatanketeranganfototiga
      },
      {
        image: satpamkegiatanfotoempat?.split('/data-kegiatan/')[1] && satpamkegiatanfotoempat,
        keterangan: satpamkegiatanketeranganfotoempat
      },
      {
        image: satpamkegiatanfotolima?.split('/data-kegiatan/')[1] && satpamkegiatanfotolima,
        keterangan: satpamkegiatanketeranganfotolima
      }
    ]);
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (kegiatanKode) => {
    setKegiatanDetailKode(kegiatanKode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate
    };

    setShowPopover('');

    Swal.fire({
      text: 'Do you want to delete this kegiatan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .kegiatanHarianDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData(formDate);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkegiatankode',
      width: 100,
      render: ({ satpamkegiatankode }) => (
        <div className="flex items-center justify-center gap-1">
          {/* Button Aksi */}
          <Popover
            opened={showPopover === satpamkegiatankode}
            onClick={() => setShowPopover(showPopover ? '' : satpamkegiatankode)}
            width={110}
            position="right"
            withArrow
            shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(satpamkegiatankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              {!isAdmin && (
                <>
                  <Link to={`/kegiatan-harian/update/${satpamkegiatankode}`}>
                    <div className="popOverItem">
                      <i className="fa-solid fa-pen"></i> Edit
                    </div>
                  </Link>
                  <div
                    className="popOverItem"
                    onClick={() =>
                      handleDelete({
                        satpamkegiatankode: satpamkegiatankode
                      })
                    }>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                </>
              )}
              <Link to={`/kegiatan-harian-printperkeg/${satpamkegiatankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Kegiatan',
      accessor: 'satpamkegiatanfotosatu',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({
        satpamkegiatanfotosatu,
        satpamkegiatanketeranganfotosatu,
        satpamkegiatanfotodua,
        satpamkegiatanketeranganfotodua,
        satpamkegiatanfototiga,
        satpamkegiatanketeranganfototiga,
        satpamkegiatanfotoempat,
        satpamkegiatanketeranganfotoempat,
        satpamkegiatanfotolima,
        satpamkegiatanketeranganfotolima
      }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() =>
            handleImageData({
              satpamkegiatanfotosatu,
              satpamkegiatanketeranganfotosatu,
              satpamkegiatanfotodua,
              satpamkegiatanketeranganfotodua,
              satpamkegiatanfototiga,
              satpamkegiatanketeranganfototiga,
              satpamkegiatanfotoempat,
              satpamkegiatanketeranganfotoempat,
              satpamkegiatanfotolima,
              satpamkegiatanketeranganfotolima
            })
          }>
          <img
            src={
              satpamkegiatanfotosatu?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotosatu
                : satpamkegiatanfotodua?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotodua
                : satpamkegiatanfototiga?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfototiga
                : satpamkegiatanfotoempat?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotoempat
                : satpamkegiatanfotolima?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotolima
                : ''
            }
            alt=""
            className="h-full object-contain rounded-lg"
          />
        </div>
      )
    },
    {
      title: 'Status, Kode, dan Tanggal',
      accessor: 'satpamkegiatantanggalmelaporkan,',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkegiatantanggalmelaporkan, satpamkegiatankode, satpamkegiatanstatus }) => (
        <div>
          <div className="py-0 my-0">
            <div className="py-0 my-0">{satpamkegiatankode} &nbsp;</div>
            <div className="py-0 my-0">{typeof satpamkegiatantanggalmelaporkan !== 'undefined' && moment(satpamkegiatantanggalmelaporkan).format('DD MMM YYYY HH:mm')}</div>
            {Number(satpamkegiatanstatus) === 0 ? (
              <div className="border-green-700 border-2 text-sm text-green-700 btnStatus block w-full rounded-full text-center mb-2">Open</div>
            ) : (
              <div className="border-red-700 text-sm border-2 text-red-700 btnStatus block w-full rounded-full text-center mb-2">Closed</div>
            )}
          </div>
        </div>
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamkegiatankategorinama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Uraian',
      accessor: 'satpamkegiatanuraian',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamkegiatanuraian }) => <>{satpamkegiatanuraian !== null && <ReadMore>{satpamkegiatanuraian}</ReadMore>}</>
    },
    {
      title: 'Keterangan',
      accessor: 'satpamkegiatanketerangan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamkegiatanketerangan }) => <>{satpamkegiatanketerangan !== null && <ReadMore>{satpamkegiatanketerangan}</ReadMore>}</>
    },
    {
      title: 'Alamat',
      accessor: 'satpamkegiatanalamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    }
  ];

  if ((data && data[0] === '') || (kategoriCount && kategoriCount[0] === '')) {
    return <LoadingPage />;
  }

  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title="Statistik Kegiatan Harian"
        desc="Berikut ini adalah jumlah kegiatan harian yang telah dilakukan"
        card1={{ title: 'Verifikasi', value: datas?.sudah_verifikasi || 0 }}
        card2={{
          title: 'Belum Verifikasi',
          value: datas?.belum_verifikasi || 0
        }}
        card3={{ title: 'Total Data', value: datas?.jumlah_data || 0 }}
        loading={loading}
      />

      {/* Cards */}
      <div className="flex flex-wrap gap-5 text-white">
        {kategoriCount.map((data) => (
          <CardDashboard data={data} loading={loading} />
        ))}
      </div>

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            {!isAdmin && <FilterDate date={date} setDate={setDate} tipeLaporan={tipeLaporan} />}
            <FilterKategori kategori={kategori} setKategori={setKategori} page="kegiatan" />
          </div>

          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-full">
              <BtnAdd path="/kegiatan-harian/create" />

              <Link to={`/kegiatan-harian-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Show Table */}
        <ShowTable loading={loading} datas={datas} filterData={filterData} columnData={columnData} setQuery={setQuery} />
      </div>

      <div className="flex justify-center mt-5">
        <Link to="/">
          <button className="btnAksi bg-primary py-2 px-20">Dashboard</button>
        </Link>
      </div>

      {/* Modal Detail Kegiatan */}
      <ModalKegiatanHarianDetail kode={kegiatanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

      {/* Modal Image */}
      <ShowModalImage showImage={showImage} setShowImage={setShowImage} imageData={imageData} />
    </div>
  );
}
