import React, { useEffect, useState, useRef } from 'react';
import * as api from '../../redux/api';
import { AiFillPrinter } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import TableCetakPatroli from '../../components/patroli/TableCetakPatroli';
import { Helmet } from 'react-helmet';
import ModalSendLaporan from '../../components/general/ModalSendLaporan';
import { RiMailSendFill } from 'react-icons/ri';

export default function PatroliPrint() {
  const [openModalSend, setOpenModalSend] = useState(false);
  const [dataPatroliPrint, setDataPatroliPrint] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { date, satpampatrolijenis } = location.state;
  const navigate = useNavigate();
  const componentRef = useRef();

  const fetchData = async () => {
    if (!date || loading || !hasMore) return;

    setLoading(true);
    setError(null);

    try {
      const res = await api.patroliPrint({
        startdate: date.startDate,
        enddate: date.endDate,
        kategori: satpampatrolijenis,
        page: page
      });

      const data = res?.data?.data;

      if (data.length === 0 || dataPatroliPrint.length + data.length > res.data.total) {
        setHasMore(false);
      } else {
        setDataPatroliPrint((prevItems) => [...prevItems, ...data]);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment the page when button is clicked
    }
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <div className="absolute top-0 right-0 bottom-0 z-20 h-screen w-screen overflow-y-auto bg-white text-dark">
        <div className="flex p-8 justify-center items-center flex-col text-center">
          <div className="font-bold text-xl">Preview Cetak Patroli</div>
          <h4 className="text-md p-2">
            <span className="font-bold">Periode : </span>
            {moment(date.startDate).format('DD MMM YYYY')} s.d {moment(date.endDate).format('DD MMM YYYY')}
          </h4>
          <div className="flex gap-5 mb-3">
            <ReactToPrint
              trigger={() => (
                <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
                  <AiFillPrinter size={18} color="white" />
                </button>
              )}
              content={() => componentRef.current}
            />
            {/* <div className="hidden">
              <PatroliCetak refPropWithAnotherName={componentRef} date={date} dataPatroli={dataPatroli} ruteDetail={ruteDetail} />
            </div> */}
            <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
              <RiMailSendFill size={18} color="white" onClick={() => setOpenModalSend(true)} />
            </button>
            <button className="btnAksi h-fit py-[7px] mt-1 text-[13px]" onClick={() => navigate(-1)}>
              Kembali
            </button>
          </div>
          <div className="w-full border-4 border-primary p-3">
            <TableCetakPatroli dataPatroli={dataPatroliPrint} isFethcing={loading} isError={error} refPropWithAnotherName={componentRef} />

            {hasMore && (
              <button onClick={handleLoadMore} className="text-white bg-blue-500 p-4 rounded-md">
                Load More
              </button>
            )}
          </div>
          <ModalSendLaporan type={'Patroli'} openModalSend={openModalSend} setOpenModalSend={setOpenModalSend} startDate={date.startDate} endDate={date.endDate} />
        </div>
      </div>
    </>
  );
}
