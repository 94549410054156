import React, { useEffect, useState } from 'react';
import { DataTable } from 'mantine-datatable';

export default function Table({ columnData, data, setQuery, id }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [records, setRecords] = useState(data.slice(0, pageSize));

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(data.slice(from, to));
  }, [data, page, pageSize]);

  return (
    <>
      {/* Show data and search */}
      <div className="flex md:flex-row flex-col justify-between items-center mb-2 w-full">
        <div className="flex justify-between md:mb-0 mb-2 gap-1 md:w-[10%] w-full">
          <div className="flex flex-col text-xs font-semibold w-full">
            <span>Show &nbsp;</span>
            <select className="inputForm w-full" onChange={(e) => setPageSize(e.target.value)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div className="text-xs md:w-1/4 w-full">
          <span className="font-semibold">Search: &nbsp;</span>
          <input className="inputForm w-full" type="search" placeholder="search here..." onChange={(e) => setQuery(e.target.value)} />
        </div>
      </div>
      {/* Table */}
      <div className="w-full h-[80vh] overflow-y-auto overflow-x-auto">
        <DataTable
          styles={{
            header: {
              wordWrap: 'normal'
            }
          }}
          shadow="xs"
          withColumnBorders
          withBorder
          striped
          highlightOnHover
          verticalSpacing="xs"
          columns={columnData}
          records={records}
          totalRecords={data?.length}
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          idAccessor={id}
        />
      </div>
    </>
  );
}
