// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const TimesheetSatpamPrint = React.lazy(() => import('../../components/timesheet/TimesheetSatpamPrint'));

export default function TimeSheetPrint() {
  const [loading, setLoading] = useState(false);
  const [dataJadwal, setDataJadwal] = useState([]);
  const [headerDate, setHeaderDate] = useState([]);
  const [page, setPage] = useState('');

  const { state } = useLocation();

  useEffect(() => {
    setPage(state?.page ? state?.page : '');
    setLoading(true);
    const getDataJadwal = async (formData) => {
      await api
        .kehadiranData(formData)
        .then((res) => {
          setDataJadwal(res.data);
          setLoading(false);
        })
        .catch((err) => {});
    };
    getDataJadwal({
      startdate: state.startDate,
      enddate: state.endDate,
      satpamkode: state.satpamKode
    });

    let start = moment(state.startDate, 'YYYY-MM-DD');
    let end = moment(state.endDate, 'YYYY-MM-DD');

    let data = [];
    for (let m = start; m.isSameOrBefore(end); m.add(1, 'days')) {
      let arr = {};
      arr.value = moment(m).format('DD');
      arr.fullDate = moment(m).format('YYYY-MM-DD');
      data.push(arr);
    }
    setHeaderDate(data);
  }, [state]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <div className="absolute top-0 right-0 bottom-0 z-20 min-h-screen w-screen overflow-y-auto bg-white text-dark">
        <div className="flex p-8 justify-center items-center flex-col w-full">
          <div className="font-bold text-xl text-center">Preview Cetak Timesheet</div>
          {/* Table Component */}
          {loading ? (
            <div className="flex min-h-screen-75 justify-center items-center">
              <ClipLoader color="black" loading size={40} speedMultiplier={0.7} />
            </div>
          ) : (
            <React.Suspense
              fallback={
                <div className="flex min-h-screen-75 justify-center items-center">
                  <ClipLoader color="black" loading size={40} speedMultiplier={0.7} />
                </div>
              }>
              <TimesheetSatpamPrint date={state} headerDate={headerDate} dataJadwal={dataJadwal} page={page} />
            </React.Suspense>
          )}
        </div>
      </div>
    </>
  );
}
