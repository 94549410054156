/* eslint-disable array-callback-return */
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { ClipLoader } from 'react-spinners';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Tooltip } from '@mantine/core';
// Import files
import * as api from '../../redux/api';
import Headerlaporan from '../../components/general/HeaderLaporan';
// import LoadingPage from '../../components/general/LoadingPage';

export default function Analisis() {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [laporan, setLaporan] = useState('');
  const [month, setMonth] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(['']);
  // const [dataKeluhan, setDataKeluhan] = useState([]);
  // const [dataPersonil, setDataPersonil] = useState([]);

  const [totalMinggu1, setTotalMinggu1] = useState({ kejadian: 0, keluhan: 0, personil: 0 });
  const [totalMinggu2, setTotalMinggu2] = useState({ kejadian: 0, keluhan: 0, personil: 0 });
  const [totalMinggu3, setTotalMinggu3] = useState({ kejadian: 0, keluhan: 0, personil: 0 });
  const [totalMinggu4, setTotalMinggu4] = useState({ kejadian: 0, keluhan: 0, personil: 0 });

  const componentRef = useRef();

  useEffect(() => {
    setLoading(true);

    let dataMinggu1Kejadian = [];
    let dataMinggu2Kejadian = [];
    let dataMinggu3Kejadian = [];
    let dataMinggu4Kejadian = [];

    // let dataMinggu1Keluhan = [];
    // let dataMinggu2Keluhan = [];
    // let dataMinggu3Keluhan = [];
    // let dataMinggu4Keluhan = [];

    // let dataMinggu1Personil = [];
    // let dataMinggu2Personil = [];
    // let dataMinggu3Personil = [];
    // let dataMinggu4Personil = [];

    if (month !== '') {
      const getDataKejadian = async (month) => {
        await api
          .analisisKejadian({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setData(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kejadian.push(parseInt(jadwal.minggu1));
                dataMinggu2Kejadian.push(parseInt(jadwal.minggu2));
                dataMinggu3Kejadian.push(parseInt(jadwal.minggu3));
                dataMinggu4Kejadian.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kejadian: dataMinggu1Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kejadian: dataMinggu2Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kejadian: dataMinggu3Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kejadian: dataMinggu4Kejadian.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };

      const getDataHarian = async (month) => {
        await api
          .analisisHarian({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setData(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kejadian.push(parseInt(jadwal.minggu1));
                dataMinggu2Kejadian.push(parseInt(jadwal.minggu2));
                dataMinggu3Kejadian.push(parseInt(jadwal.minggu3));
                dataMinggu4Kejadian.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kejadian: dataMinggu1Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kejadian: dataMinggu2Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kejadian: dataMinggu3Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kejadian: dataMinggu4Kejadian.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };

      const getDataTemuan = async (month) => {
        await api
          .analisisTemuan({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setData(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kejadian.push(parseInt(jadwal.minggu1));
                dataMinggu2Kejadian.push(parseInt(jadwal.minggu2));
                dataMinggu3Kejadian.push(parseInt(jadwal.minggu3));
                dataMinggu4Kejadian.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kejadian: dataMinggu1Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kejadian: dataMinggu2Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kejadian: dataMinggu3Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kejadian: dataMinggu4Kejadian.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };

      // const getDataKeluhan = async (month) => {
      //   await api
      //     .analisisKeluhan({ month: moment(month).format('YYYY-MM') })
      //     .then((res) => {
      //       if (res.data.data.data.length > 0) {
      //         setDataKeluhan(res.data.data.data);
      //         res.data.data.data.map((jadwal) => {
      //           dataMinggu1Keluhan.push(parseInt(jadwal.minggu1));
      //           dataMinggu2Keluhan.push(parseInt(jadwal.minggu2));
      //           dataMinggu3Keluhan.push(parseInt(jadwal.minggu3));
      //           dataMinggu4Keluhan.push(parseInt(jadwal.minggu4));
      //         });
      //         setTotalMinggu1((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu1Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu2((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu2Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu3((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu3Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu4((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu4Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //       }
      //       setLoading(false);
      //     })
      //     .catch((err) => {
      //       setLoading(false);
      //     });
      // };
      // const getDataPersonil = async (month) => {
      //   await api
      //     .analisisSatpam({ month: moment(month).format('YYYY-MM') })
      //     .then((res) => {
      //       if (res.data.data.data.length > 0) {
      //         setDataPersonil(res.data.data.data);
      //         res.data.data.data.map((jadwal) => {
      //           dataMinggu1Personil.push(parseInt(jadwal.sudah_absen));
      //           dataMinggu2Personil.push(parseInt(jadwal.izin));
      //           dataMinggu3Personil.push(parseInt(jadwal.sakit));
      //           dataMinggu4Personil.push(parseInt(jadwal.alfa));
      //         });
      //         setTotalMinggu1((prev) => ({
      //           ...prev,
      //           personil: dataMinggu1Personil.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu2((prev) => ({
      //           ...prev,
      //           personil: dataMinggu2Personil.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu3((prev) => ({
      //           ...prev,
      //           personil: dataMinggu3Personil.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu4((prev) => ({
      //           ...prev,
      //           personil: dataMinggu4Personil.reduce((a, b) => a + b, 0)
      //         }));
      //       }
      //       setLoading(false);
      //     })
      //     .catch((err) => {
      //       setLoading(false);
      //     });
      // };
      // getDataPersonil(month);
      // getDataKeluhan(month);
      laporan === 'kejadian' && getDataKejadian(month);
      laporan === 'harian' && getDataHarian(month);
      laporan === 'temuan' && getDataTemuan(month);
    }
    setLoading(false);
  }, [laporan, month]);

  // if (monthOptions.length === 0) return <LoadingPage />;

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="mb-5 w-full min-h-screen justify-center bg-white rounded-lg shadowAll">
          <div className="pt-5 ml-5 flex flex-col sm:flex-row items-center gap-3 w-fit">
            <div className="font-semibold text-md mb-1 md:mb-0 w-full">Filter laporan</div>
            <select defaultValue="" className="inputForm text-sm cursor-pointer" onChange={(e) => setLaporan(e.target.value)}>
              <option value="" disabled>
                Pilih laporan
              </option>
              <option value="harian">Kegiatan Harian</option>
              <option value="kejadian">Kejadian</option>
              <option value="temuan">Temuan</option>
            </select>
          </div>
          <div className="flex flex-nowrap justify-beetween items-center mb-2">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
              <div className="w-fit flex flex-col sm:items-center sm:flex-row sm:gap-5 md:my-3 my-2 pr-5 md:pr-2 pl-5">
                <div className="font-semibold text-md mr-2 mb-1 md:mb-0">Filter month</div>
                {/* <div className="md:flex md:flex-row gap-2">
                  <div className="flex md:flex-row flex-col justify-center items-center md:mt-0 mt-2">
                    <div className="w-full">
                      <select className="cursor-pointer inputForm text-md w-full" onChange={(e) => setMonth(e.target.value)}>
                        <option value="">Pilih bulan</option>
                        {monthOptions.length > 0 ? (
                          monthOptions.map((month, i) => (
                            <option key={i} value={month.value}>
                              {month.label + ' ' + month.value?.split('-')[0]}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            Belum ada data
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div> */}
                <DatePicker
                  selected={month}
                  className="inputForm text-sm cursor-pointer"
                  onChange={(date) => setMonth(date)}
                  locale={id}
                  timeIntervals={15}
                  dateFormat="MMMM yyyy"
                  placeholderText="MMMM yyyy"
                  showMonthYearPicker
                  required
                />
              </div>
            </div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Cetak laporan">
                  <ReactToPrint
                    trigger={() => (
                      <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                        <HiOutlinePrinter size={24} />
                      </div>
                    )}
                    content={() => componentRef.current}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="px-5">
            <table className="w-full" ref={componentRef}>
              <thead>
                <tr>
                  <th>
                    <div className="flex w-full">
                      <Headerlaporan />
                      <div className="w-full px-5 bg-dark flex flex-col items-end justify-center text-white">
                        <div className="text-gray-100 text-[18px] font-bold">LAPORAN BULANAN</div>
                        {/* <div className="text-gray-100 text-[15px] font-bold">
                  {item.satpamperusahaannama}
                </div> */}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {month === '' ? (
                      <div className="text-center">
                        <div className="mt-10">Belum ada bulan yang dipilih.</div>
                      </div>
                    ) : loading ? (
                      <div className="flex min-h-screen-75 justify-center items-center">
                        <ClipLoader color="#052D5A" loading={true} size={40} speedMultiplier={0.7} />
                      </div>
                    ) : (
                      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                        <div className="block w-full p-5">
                          <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th colSpan="8" className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">A. LAPORAN KEJADIAN</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Tabel Kejadian */}
                              <tr>
                                <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td rowSpan="2" className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Kategori Kejadian</p>
                                </td>
                                <td colSpan="4" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Minggu Ke-</p>
                                </td>
                                <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                {/* <td rowSpan="2" className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td> */}
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">II</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">III</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">IV</p>
                                </td>
                              </tr>
                              {data?.map((kejadian, index) => (
                                <tr key={kejadian.satpamkejadiankategori}>
                                  <td className="border border-customGray px-2 py-2 text-center">{index + 1}.</td>
                                  <td className="border border-customGray px-2 py-2 text-left">{kejadian.kategori_name}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu1}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu2}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu3}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu4}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(kejadian.minggu1) + parseInt(kejadian.minggu2) + parseInt(kejadian.minggu3) + parseInt(kejadian.minggu4)}
                                  </td>
                                  {/* <td className="border border-customGray px-2 py-2 text-left"></td> */}
                                </tr>
                              ))}
                              <tr>
                                <td colSpan="2" className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.kejadian + totalMinggu2.kejadian + totalMinggu3.kejadian + totalMinggu4.kejadian}</p>
                                </td>
                                {/* <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td> */}
                              </tr>
                            </tbody>
                          </table>
                          {/* <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th colSpan="8" className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">B. LAPORAN KELUHAN</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td rowSpan="2" className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Kategori Kejadian</p>
                                </td>
                                <td colSpan="4" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Minggu Ke-</p>
                                </td>
                                <td rowSpan="2" className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td rowSpan="2" className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">II</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">III</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">VI</p>
                                </td>
                              </tr>
                              {dataKeluhan?.map((kejadian, index) => (
                                <tr key={kejadian.satpamkeluhankategori}>
                                  <td className="border border-customGray px-2 py-2 text-center">{index + 1}.</td>
                                  <td className="border border-customGray px-2 py-2 text-left">{kejadian.satpamkeluhankategori}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu1}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu2}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu3}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">{kejadian.minggu4}</td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(kejadian.minggu1) + parseInt(kejadian.minggu2) + parseInt(kejadian.minggu3) + parseInt(kejadian.minggu4)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan="2" className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.keluhan + totalMinggu2.keluhan + totalMinggu3.keluhan + totalMinggu4.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table> */}
                          {/* <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th
                                  colSpan="8"
                                  className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">C. PERSONIL</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Nama Personil</p>
                                </td>
                                <td
                                  colSpan="4"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Status</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">M</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">S</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">A</p>
                                </td>
                              </tr>
                              {dataPersonil?.map((personil, index) => (
                                <tr key={personil.satpamnamalengkap}>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {index + 1}.
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left">
                                    <p className="font-semibold">{personil.satpamnamalengkap}</p>
                                    <p>{personil.satpamjabatan}</p>
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.sudah_absen}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.izin}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.sakit}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.alfa}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(personil.sudah_absen) +
                                      parseInt(personil.izin) +
                                      parseInt(personil.sakit) +
                                      parseInt(personil.alfa)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan="2"
                                  className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">
                                    {totalMinggu1.personil +
                                      totalMinggu2.personil +
                                      totalMinggu3.personil +
                                      totalMinggu4.personil}
                                  </p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table> */}
                          <div className="page-break" />
                          {/* Input Analisis */}
                          <div className="flex md:flex-row flex-col gap-3 text-sm mt-5">
                            <div className="md:w-1/2 w-full py-2 flex flex-col justify-around">
                              <div className="md:flex mb-3">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">Potensi kejadian</label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
                                  <p className="text-primary text-xs"></p>
                                </div>
                              </div>
                              <div className="md:flex">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">Potensi kejadian</label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
                                  <p className="text-primary text-xs"></p>
                                </div>
                              </div>
                            </div>
                            <div className="md:w-1/2 w-full py-2 flex flex-col justify-around">
                              <div className="md:flex mb-3">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">Kesimpulan</label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
                                </div>
                              </div>
                              <div className="md:flex mb-3">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">Saran dan Rekomendasi</label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea className={`w-full inputForm`} rows={3} disabled={adminType !== 'Admin Project' ? true : false} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
