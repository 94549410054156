import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
// Import files
import * as api from '../../redux/api';
import BtnAdd from '../../components/general/BtnAdd';
import Cards from '../../components/general/Cards';
import CardDashboard from '../../components/dashboard/CardDashboard';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import LoadingPage from '../../components/general/LoadingPage';
import ModalTemuanDetail from '../../components/temuan/ModalTemuanDetail';
import ReadMore from '../../components/general/ReadMore';
import ShowModalImage from '../../components/general/ShowModalImage';
import ShowTable from '../../components/general/ShowTable';

export default function Temuan({ isAdmin }) {
  // Get Profile
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showPopover, setShowPopover] = useState('');
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState(['']);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [temuanDetailKode, setTemuanDetailKode] = useState('');
  const [kategori, setKategori] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [tipeLaporan, setTipeLaporan] = useState('');
  const [kategoriCount, setKategoriCount] = useState(['']);
  const location = useLocation();
  const { perusahaanKode } = useSelector((state) => state.perusahaan);

  // Date
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  // Get data
  const getData = async (formDate) => {
    await api.temuan(formDate).then((res) => {
      setData(res.data.Data);
      setDatas(res.data);
      setLoading(false);
    });
  };

  const getCount = async (formData) => {
    await api.countTemuan(formData).then((res) => {
      setKategoriCount(res.data.data);
    });
  };

  useEffect(() => {
    if (location?.state?.date) {
      setDate(location?.state?.date);
      setKategori(location?.state?.kategori);
      setTipeLaporan(location?.state?.tipeLaporan);
    }
  }, [location?.state]);

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    setLoading(true);

    getData(formDate);
    getCount(formDate);
  }, [date, kategori, perusahaanKode]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(
          ({
            satpamtemuankode,
            satpamtemuantanggalmelaporkan,
            satpamtemuananalisis,
            satpamnamalengkap,
            satpamtemuanuraian,
            satpamtemuantindakan,
            satpamtemuanpatokan,
            satpamtemuanalamat
          }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpamtemuankode} ${satpamtemuantanggalmelaporkan} ${satpamtemuananalisis} ${satpamnamalengkap} ${satpamtemuanuraian} ${satpamtemuantindakan} ${satpamtemuanpatokan} ${satpamtemuanalamat}`
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          }
        )
      );
    }
  }, [data, debouncedQuery]);

  const handleImageData = ({
    satpamtemuanfotosatu,
    satpamtemuanketeranganfotosatu,
    satpamtemuanfotodua,
    satpamtemuanketeranganfotodua,
    satpamtemuanfototiga,
    satpamtemuanketeranganfototiga,
    satpamtemuanfotoempat,
    satpamtemuanketeranganfotoempat,
    satpamtemuanfotolima,
    satpamtemuanketeranganfotolima
  }) => {
    setImageData([
      {
        image: satpamtemuanfotosatu?.split('/data-temuan/')[1] && satpamtemuanfotosatu,
        keterangan: satpamtemuanketeranganfotosatu
      },
      {
        image: satpamtemuanfotodua?.split('/data-temuan/')[1] && satpamtemuanfotodua,
        keterangan: satpamtemuanketeranganfotodua
      },
      {
        image: satpamtemuanfototiga?.split('/data-temuan/')[1] && satpamtemuanfototiga,
        keterangan: satpamtemuanketeranganfototiga
      },
      {
        image: satpamtemuanfotoempat?.split('/data-temuan/')[1] && satpamtemuanfotoempat,
        keterangan: satpamtemuanketeranganfotoempat
      },
      {
        image: satpamtemuanfotolima?.split('/data-temuan/')[1] && satpamtemuanfotolima,
        keterangan: satpamtemuanketeranganfotolima
      }
    ]);
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (temuanKode) => {
    setTemuanDetailKode(temuanKode);
    setOpenModalDetail(true);
    setShowPopover('');
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori
    };

    setShowPopover('');

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    const getData = async () => {
      await api.temuan(formDate).then((res) => {
        setData(res.data.Data);
        setDatas(res.data);
      });
    };

    Swal.fire({
      text: 'Do you want to delete this temuan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .temuanDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamtemuankode',
      width: 100,
      render: ({ satpamtemuankode }) => (
        <div className="flex items-center justify-center gap-1">
          {/* Button Aksi */}
          <Popover
            opened={showPopover === satpamtemuankode}
            onClick={() => setShowPopover(showPopover ? '' : satpamtemuankode)}
            width={110}
            position="right"
            withArrow
            shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(satpamtemuankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/temuan-printpertem/${satpamtemuankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
              {adminType === 'Admin Project' && (
                <>
                  <Link to={`/temuan/update/${satpamtemuankode}`}>
                    <div className="popOverItem">
                      <i className="fa-solid fa-pen"></i> Edit
                    </div>
                  </Link>
                  <div
                    className="popOverItem"
                    onClick={() =>
                      handleDelete({
                        satpamtemuankode: satpamtemuankode
                      })
                    }>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                </>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Temuan',
      accessor: 'satpamtemuanfotosatu',
      width: 150,
      render: ({
        satpamtemuanfotosatu,
        satpamtemuanketeranganfotosatu,
        satpamtemuanfotodua,
        satpamtemuanketeranganfotodua,
        satpamtemuanfototiga,
        satpamtemuanketeranganfototiga,
        satpamtemuanfotoempat,
        satpamtemuanketeranganfotoempat,
        satpamtemuanfotolima,
        satpamtemuanketeranganfotolima
      }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() =>
            handleImageData({
              satpamtemuanfotosatu,
              satpamtemuanketeranganfotosatu,
              satpamtemuanfotodua,
              satpamtemuanketeranganfotodua,
              satpamtemuanfototiga,
              satpamtemuanketeranganfototiga,
              satpamtemuanfotoempat,
              satpamtemuanketeranganfotoempat,
              satpamtemuanfotolima,
              satpamtemuanketeranganfotolima
            })
          }>
          <img
            src={
              satpamtemuanfotosatu?.split('/data-temuan/')[1]
                ? satpamtemuanfotosatu
                : satpamtemuanfotodua?.split('/data-temuan/')[1]
                ? satpamtemuanfotodua
                : satpamtemuanfototiga?.split('/data-temuan/')[1]
                ? satpamtemuanfototiga
                : satpamtemuanfotoempat?.split('/data-temuan/')[1]
                ? satpamtemuanfotoempat
                : satpamtemuanfotolima?.split('/data-temuan/')[1]
                ? satpamtemuanfotolima
                : ''
            }
            alt=""
            className="h-full object-contain rounded-lg"
          />
        </div>
      )
    },
    {
      title: 'Status, Kode, dan Tanggal',
      accessor: 'satpamtemuantanggal',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuankode, satpamtemuantanggalmelaporkan, satpamtemuanstatus }) => (
        <div className="py-0 my-0">
          <div className="py-0 my-0">{satpamtemuankode} &nbsp;</div>
          <div className="py-0 my-0">{satpamtemuantanggalmelaporkan !== undefined && moment(satpamtemuantanggalmelaporkan).format('DD MMM YYYY HH:mm')}</div>
          {Number(satpamtemuanstatus) === 0 ? (
            <div className="border-green-700 border-2 text-sm text-green-700 btnStatus block w-full rounded-full text-center mb-2">Open</div>
          ) : (
            <div className="border-red-700 text-sm border-2 text-red-700 btnStatus block w-full rounded-full text-center mb-2">Closed</div>
          )}
        </div>
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamtemuankategorinama',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuankategorinama }) => <div className="py-0 my-0">{satpamtemuankategorinama ? satpamtemuankategorinama : 'Belum ada kategori'}</div>
    },
    {
      title: 'Analisis',
      accessor: 'satpamtemuananalisis',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuananalisis }) => <div className="py-0 my-0">{satpamtemuananalisis ? satpamtemuananalisis : 'Belum ada analisis'}</div>
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Uraian Temuan',
      accessor: 'satpamtemuanuraian',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuanuraian }) => <>{satpamtemuanuraian !== '' && <ReadMore>{satpamtemuanuraian}</ReadMore>}</>
    },
    {
      title: 'Uraian Tindakan',
      accessor: 'satpamtemuantindakan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuantindakan }) => <>{satpamtemuantindakan !== '' && <ReadMore>{satpamtemuantindakan}</ReadMore>}</>
    },
    {
      title: 'Hasil Tindakan',
      accessor: 'satpamtemuanhasil',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuanhasil }) => <>{satpamtemuanhasil !== '' && <ReadMore>{satpamtemuanhasil}</ReadMore>}</>
    },
    {
      title: 'Patokan lokasi',
      accessor: 'satpamtemuanalamatpatokan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Alamat',
      accessor: 'satpamtemuanalamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    }
  ];

  if ((data && data[0] === '') || (kategoriCount && kategoriCount[0] === '')) {
    return <LoadingPage />;
  }

  return (
    <div className={`relative w-full min-h-screen p-5 ${isAdmin ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title="Statistik Temuan"
        desc="Berikut ini adalah jumlah temuan yang ditemukan"
        card1={{ title: 'Open', value: datas?.Total_Open || 0 }}
        card2={{ title: 'Close', value: datas?.Total_Close || 0 }}
        card3={{ title: 'Total', value: datas?.Total_Data || 0 }}
        loading={loading}
      />

      {/* Cards */}
      <div className="flex flex-wrap gap-5 text-white">
        {kategoriCount.map((data) => (
          <CardDashboard data={data} loading={loading} />
        ))}
      </div>

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            {!isAdmin && <FilterDate date={date} setDate={setDate} tipeLaporan={tipeLaporan} />}
            <FilterKategori kategori={kategori} setKategori={setKategori} page="temuan" />
          </div>
          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-full">
              <BtnAdd path="/temuan/create" />

              <Link to={`/temuan-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Show Table */}
        <ShowTable loading={loading} datas={datas} filterData={filterData} columnData={columnData} setQuery={setQuery} />
      </div>

      <div className="flex justify-center mt-5">
        <Link to="/">
          <button className="btnAksi bg-primary py-2 px-20">Dashboard</button>
        </Link>
      </div>

      {/* Modal Detail temuan */}
      <ModalTemuanDetail kode={temuanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

      {/* Modal Image */}
      <ShowModalImage showImage={showImage} setShowImage={setShowImage} imageData={imageData} />
    </div>
  );
}
