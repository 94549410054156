import React, { useEffect, useState, useRef } from 'react';
import * as api from '../../redux/api';
import { AiFillPrinter } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import TableCetakTemuan from '../../components/temuan/TableCetakTemuan';
import TemuanCetak from '../../components/temuan/TemuanCetak';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { renderToString } from 'react-dom/server';
import ModalSendLaporan from '../../components/general/ModalSendLaporan';
import TableCetakTemuanMail from '../../components/temuan/TableCetakTemuanMail';
import { RiMailSendFill } from 'react-icons/ri';

export default function TemuanPrint() {
  const [dataTemuan, setDataTemuan] = useState([]);
  const [openModalSend, setOpenModalSend] = useState(false);
  const [htmlString, setHtmlString] = useState('');

  const location = useLocation();
  const { data, date } = location.state;
  const navigate = useNavigate();

  const componentRef = useRef();

  useEffect(() => {
    const getSatpamProses = async () => {
      for (let i = 0; i < data.length; i++) {
        await api
          .detailProses({ kode_kegiatan: data[i].satpamtemuankode })
          .then((res) => {
            let newData = [...data];
            res.data.Data.map((proses) => {
              const indexDataTemuan = newData
                .map((d) => d.satpamtemuankode)
                .indexOf(proses.satpamproseskode);

              return (newData[indexDataTemuan]['satpam_proses'] = res.data.Data);
            });
            setDataTemuan(newData);
          })
          .catch((err) => console.log(err.message));
      }
    };

    getSatpamProses();
  }, [data]);

  useEffect(() => {
    setHtmlString(renderToString(<TableCetakTemuanMail data={dataTemuan} />));
  }, [dataTemuan]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <div className="absolute top-0 right-0 bottom-0 z-20 h-screen w-screen overflow-y-auto bg-white text-dark">
        <div className="flex p-8 justify-center items-center flex-col text-center">
          <div className="font-bold text-xl">Preview Cetak Temuan</div>
          <h4 className="text-md p-2">
            <span className="font-bold">Periode : </span>
            {moment(date.startDate).format('DD MMM YYYY')} s.d{' '}
            {moment(date.endDate).format('DD MMM YYYY')}
          </h4>
          <div className="flex gap-5 mb-3">
            <ReactToPrint
              trigger={() => (
                <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
                  <AiFillPrinter size={18} color="white" />
                </button>
              )}
              content={() => componentRef.current}
            />
            <div className="hidden">
              <TemuanCetak refPropWithAnotherName={componentRef} data={dataTemuan} date={date} />
            </div>
            <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
              <RiMailSendFill size={18} color="white" onClick={() => setOpenModalSend(true)} />
            </button>
            <button
              className="btnAksi h-fit py-[7px] mt-1 text-[13px]"
              onClick={() => navigate(-1)}>
              Kembali
            </button>
          </div>
          <div className="w-full border-4 border-primary p-3">
            <TableCetakTemuan data={dataTemuan} />
          </div>
          <ModalSendLaporan
            htmlString={htmlString}
            openModalSend={openModalSend}
            setOpenModalSend={setOpenModalSend}
          />
        </div>
      </div>
    </>
  );
}
