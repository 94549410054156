import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';

const parse = require('html-react-parser');

export default function ModalInformasiDetail({ kode, openModalDetail, setOpenModalDetail }) {
  const [dataInformasi, setDataInformasi] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getPerusahaanByKode = async () => {
        await api.informasiDetail(kode).then((res) => {
          setDataInformasi(res.data.Data[0]);
        });
      };

      getPerusahaanByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Informasi</div>
      <div className="flex justify-center">
        {dataInformasi.satpaminformasigambar?.split('/data-informasi/')[1].length > 0 ? (
          <img
            src={dataInformasi.satpaminformasigambar}
            alt=""
            className="w-full h-56 object-contain mb-5 rounded-xl"
          />
        ) : (
          <img
            src={require('../../images/logo.png')}
            alt=""
            className="w-full h-56 object-contain mb-5 rounded-xl"
          />
        )}
      </div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Grup</div>
            <div className="w-full">{dataInformasi?.satpaminformasigrup}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Header</div>
            <div className="w-full">{dataInformasi?.satpaminformasiheader}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Detail Header</div>
            <div className="w-full">{dataInformasi?.satpaminformasiheaderdetail}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Judul</div>
            <div className="w-full">{dataInformasi?.satpaminformasijudul}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Isi</div>
            <div className="w-full">
              {dataInformasi?.satpaminformasiisi !== '' &&
                dataInformasi?.satpaminformasiisi !== null &&
                parse(dataInformasi.satpaminformasiisi || '-')}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Link</div>
            <div className="w-full">{dataInformasi?.satpaminformasilink || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Status</div>
            <div className="w-full">{dataInformasi?.satpaminformasistatus}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
