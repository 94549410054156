import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillPrinter } from 'react-icons/ai';
import { Tooltip } from '@mantine/core';
import ReactToPrint from 'react-to-print';
import * as api from '../../redux/api';

const DivisiPrint = () => {
  const [data, setData] = useState([]);
  let componentRef = useRef;

  useEffect(() => {
    const DataDivisi = async () => {
      const res = await api.showDivisi();
      setData(res.data.data);
    };
    DataDivisi();
  }, []);

  return (
    <main className="absolute top-0 right-0 bottom-0 z-20 h-screen w-screen overflow-y-auto bg-white text-dark ">
      <section className="flex flex-col items-center justify-center my-5 text-base md:text- font-semibold">
        <h1>Priview Cetak Divisi Data</h1>

        <div className="flex gap-4 mt-4">
          <ReactToPrint
            trigger={() => (
              <Tooltip label="Cetak pdf">
                <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
                  <AiFillPrinter size={18} color="white" />
                </button>
              </Tooltip>
            )}
            content={() => componentRef}
          />
          <Link to="/personil/divisi">
            <button className="btnAksi h-fit py-[4px] mt-1 text-[13px]">Kembali</button>
          </Link>
        </div>
      </section>
      <section className="mt-2 flex flex-col items-center justify-center max-w-[500px] mx-auto">
        <table style={{ width: '100%' }} ref={(el) => (componentRef = el)}>
          <thead>
            <tr>
              <th style={headerStyle}>No</th>
              <th style={headerStyle}>Nama Divisi</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td style={cellStyle} className="max-w-full">
                  {index + 1}
                </td>
                <td style={cellStyle} className="capitalize ">
                  {row.nama}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </main>
  );
};

const headerStyle = {
  border: '2px solid #ddd',
  padding: '8px',
  textAlign: 'left'
};

const cellStyle = {
  border: '2px solid #ddd',
  padding: '8px',
  textAlign: 'left',
  maxWidth: '120px'
};

export default DivisiPrint;
