import React, { useState, useEffect } from 'react';
import { Popover, Tooltip } from '@mantine/core';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Table from '../../components/general/Table';
import * as api from '../../redux/api';
import { useDebouncedValue } from '@mantine/hooks';
import { BsPrinter } from 'react-icons/bs';

export default function DivisiData() {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const [filterData, setFilterData] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // show data
  const getDivisi = async () => {
    await api.showDivisi().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getDivisi();
  }, []);

  useEffect(() => {
    if (data) {
      setFilterData(
        data?.filter(({ nama }) => {
          if (debouncedQuery !== '' && !`${nama}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) {
            return false;
          }
          return true;
        })
      );
    }
  }, [data, debouncedQuery]);

  const handleDelete = (id) => {
    Swal.fire({
      text: 'Apakah anda ingin menghapus data ini?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deleteDivisi(id)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getDivisi();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData(id);
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'id',
      width: 100,
      cellsStyle: {
        verticalAlign: 'top'
      },

      render: ({ id }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              {/* <div size="sm" className="popOverItem">
                <i className="fa-solid fa-circle-info"></i> Detail
              </div> */}
              <Link to={`/divisi/update/${id}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(id)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },

    {
      title: 'Nama Divisi',
      accessor: 'nama',

      cellsStyle: {
        verticalAlign: 'center'
      }
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-between items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Cetak Divisi">
                  <Link to="/divisi/print">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <BsPrinter size={24} />
                    </div>
                  </Link>
                </Tooltip>
                <Tooltip label="Tambah Divisi">
                  <Link to="/divisi/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>

          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5 capitalize">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data informasi.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
