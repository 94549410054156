import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function FilterDateNew({ date, setDate, defaultType, setTipeLaporan }) {
  const [type, setType] = useState(defaultType || 'this week');
  const [formValue, setFormValue] = useState({
    startDate: date.startDate || moment().subtract().add(-6, 'days').format('YYYY-MM-DD'),
    endDate: date.endDate || moment().format('YYYY-MM-DD')
  });

  useEffect(() => {
    if (type !== 'custom') {
      if (type === 'this week') {
        setDate({
          startDate: moment().subtract().add(-6, 'days').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        });
      }

      if (type === 'this month') {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        setDate({
          startDate: moment(startOfMonth).format('YYYY-MM-DD'),
          endDate: moment(endOfMonth).format('YYYY-MM-DD')
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    name === 'startDate' &&
      setFormValue({
        startDate: moment(value).format('YYYY-MM-DD'),
        endDate: moment(value).endOf('month').format('YYYY-MM-DD')
      });

    name === 'endDate' && setFormValue((prev) => ({ ...prev, endDate: moment(value).format('YYYY-MM-DD') }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDate({
      startDate: formValue.startDate,
      endDate: formValue.endDate
    });
  };

  const handleType = (e) => {
    setType(e.target.value);
    setTipeLaporan(e.target.value);
    localStorage.setItem('tipeLaporan', e.target.value);
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="">
        <div className="flex flex-col justify-center xl:items-center">
          <select className="inputForm w-full" name="type" value={type} onChange={handleType}>
            <option value="" disabled className="text-white bg-gray-500">
              Pilih rentang waktu
            </option>
            <option value="this week">Minggu ini</option>
            <option value="this month">Bulan ini</option>
            <option value="custom">Rentang tanggal</option>
          </select>
        </div>
        {type === 'custom' && (
          <>
            <form onSubmit={handleSubmit} className="flex flex-col xl:flex-row justify-center items-center md:mt-0 mt-2">
              <div className="w-full flex flex-col justify-center items-center mt-5 xl:mt-0">
                <input type="date" id="startDate" className="text-sm inputForm w-full" name="startDate" value={formValue.startDate} onChange={handleChangeDate} />
              </div>
              <div className="mx-2 text-center">sampai</div>
              <div className="w-full flex flex-col justify-center items-center">
                <input
                  type="date"
                  id="endDate"
                  className="text-sm cursor-pointer inputForm w-full"
                  name="endDate"
                  value={formValue.endDate}
                  min={formValue.startDate}
                  max={moment(formValue.startDate).add(30, 'day').format('YYYY-MM-DD')}
                  onChange={handleChangeDate}
                />
              </div>
              <button
                type="submit"
                className={`md:ml-2 ml-0 btnAksi ${
                  moment(formValue.startDate).diff(moment(formValue.endDate)) > 0 && 'bg-gray-400'
                } w-full flex justify-center text-sm py-2 px-5 rounded`}
                disabled={moment(formValue.startDate).diff(moment(formValue.endDate)) > 0 ? true : false}>
                Filter
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
