import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useImagePreview from '../../hooks/useImagePreview';
import { kegiatanHarianSchema } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';

export default function KegiatanHarianCreate() {
  // Get kode perusahaan
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const kodePerusahaan = profile.user.adminpetugasperusahaankode;

  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [kategoriDataOptions, setKategoriDataOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [satpamKode, setSatpamKode] = useState('');
  const [tanggalMelaporkan, setTanggalMelaporkan] = useState(new Date());
  const [tanggalAktifitas, setTanggalAktifitas] = useState(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(kegiatanHarianSchema)
  });

  const [fileDataURL] = useImagePreview(watch('foto_kegiatan1'));
  const [fileDataURL2] = useImagePreview(watch('foto_kegiatan2'));
  const [fileDataURL3] = useImagePreview(watch('foto_kegiatan3'));
  const [fileDataURL4] = useImagePreview(watch('foto_kegiatan4'));
  const [fileDataURL5] = useImagePreview(watch('foto_kegiatan5'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((response) => {
        response.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [
            ...prev,
            { value: data.satpamkode, label: data.satpamnamalengkap }
          ]);
        });
      });
    };
    const getDataKategori = async () => {
      await api.kegiatanHarianKategori().then((response) => {
        setKategoriDataOptions(response.data.data);
      });
    };
    getDataKategori();
    getDataSatpam();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append('perusahaankode', kodePerusahaan);
    formData.append('satpamkode', satpamKode);
    formData.append('tanggal', moment(tanggalMelaporkan).format('YYYY-MM-DDTHH:mm'));
    formData.append('tanggal_aktifitas', moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm'));

    if (data.foto_kegiatan1.length > 0) {
      const compressedFile = await imageCompression(data.foto_kegiatan1[0], compressOptions);
      formData.append('foto_kegiatan1', compressedFile, compressedFile.name);
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    }

    if (data.foto_kegiatan2.length > 0) {
      const compressedFile = await imageCompression(data.foto_kegiatan2[0], compressOptions);
      formData.append('foto_kegiatan2', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan3.length > 0) {
      const compressedFile = await imageCompression(data.foto_kegiatan3[0], compressOptions);
      formData.append('foto_kegiatan3', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan4.length > 0) {
      const compressedFile = await imageCompression(data.foto_kegiatan4[0], compressOptions);
      formData.append('foto_kegiatan4', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan5.length > 0) {
      const compressedFile = await imageCompression(data.foto_kegiatan5[0], compressOptions);
      formData.append('foto_kegiatan5', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    setLoading(true);

    await api
      .createKegiatan(formData)
      .then(async (res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        setSatpamKode('');
        reset({
          uraian: '',
          keterangan: '',
          alamat: '',
          status: '',
          latitude: '',
          longitude: '',
          patokan: '',
          keterangan_foto1: '',
          keterangan_foto2: '',
          keterangan_foto3: '',
          keterangan_foto4: '',
          keterangan_foto5: '',
          foto_kegiatan1: '',
          foto_kegiatan2: '',
          foto_kegiatan3: '',
          foto_kegiatan4: '',
          foto_kegiatan5: ''
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">
          Tambah Kegiatan Harian
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      defaultValue={satpamKode}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      name="satpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Kategori</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full ${
                        errors.satpamkegiatansatpamkegiatankategorikode
                          ? 'invalidForm'
                          : 'inputForm'
                      }`}
                      {...register('satpamkegiatansatpamkegiatankategorikode')}>
                      <option value="">Pilih kategori</option>
                      {kategoriDataOptions.length > 0 &&
                        kategoriDataOptions.map((kategori) => (
                          <option
                            key={kategori.satpamkegiatankategorikode}
                            value={kategori.satpamkegiatankategorikode}>
                            {kategori.satpamkegiatankategorinama}
                          </option>
                        ))}
                    </select>
                    <p className="text-errorText text-xs">
                      {errors.satpamkegiatansatpamkegiatankategorikode?.message}
                    </p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Uraian kegiatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      className={`w-full ${errors.uraian ? 'invalidForm' : 'inputForm'}`}
                      rows={3}
                      {...register('uraian')}
                    />
                    <p className="text-errorText text-xs">{errors.uraian?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Keterangan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      className={`w-full ${errors.keterangan ? 'invalidForm' : 'inputForm'}`}
                      rows={3}
                      {...register('keterangan')}
                    />
                    <p className="text-errorText text-xs">{errors.keterangan?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-5">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block font-semibold mb-1 md:mb-0">
                      Tanggal kegiatan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalAktifitas}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAktifitas(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Tanggal melaporkan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMelaporkan}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMelaporkan(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Status kegiatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full flex flex-col font-normal ${
                        errors.status ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('status')}>
                      <option disabled value="">
                        Pilih status kegiatan
                      </option>
                      <option value={0}>Open</option>
                      <option value={1}>Close</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.status?.message}</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Patokan lokasi</label>
                  </div>
                  <div className="md:w-2/3">
                    <input type="text" className="w-full inputForm" {...register('patokan')} />
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${errors.alamat ? 'invalidForm' : 'inputForm'}`}
                      {...register('alamat')}
                    />
                    <p className="text-errorText text-xs">{errors.alamat?.message}</p>
                  </div>
                </div>
                <div className="hidden md:flex items-center">
                  <div className="hidden md:w-1/3">
                    <label className="hidden font-semibold mb-1 md:mb-0">Latitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input
                      type="text"
                      className="hidden w-full inputForm"
                      {...register('latitude')}
                    />
                  </div>
                </div>
                <div className="hidden md:flex items-center">
                  <div className="hidden md:w-1/3">
                    <label className="hidden font-semibold mb-1 md:mb-0">Longtitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input
                      type="text"
                      className="hidden w-full inputForm"
                      {...register('longtitude')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-gray-50 p-5 rounded-lg shadowAll">
            <div className="mx-auto">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan1')}
                            accept="image/*"
                          />
                        </label>
                        <p className="text-errorText text-xs">{errors.foto_kegiatan1?.message}</p>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 1</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('keterangan_foto1')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL2 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL2}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan2')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 2</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('keterangan_foto2')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL3 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL3}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan3')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 3</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('keterangan_foto3')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL4 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL4}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan4')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 4</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('keterangan_foto4')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm">Pilih foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          {fileDataURL5 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL5}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Select a photo
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan5')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm">Keterangan Foto 5</label>
                        <textarea
                          className="w-full inputForm"
                          rows={2}
                          {...register('keterangan_foto5')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/kegiatan-harian">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
