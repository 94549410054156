import React from 'react';
import { ScaleLoader } from 'react-spinners';

export default function LoadingPage() {
  return (
    <div className="flex flex-col gap-2 justify-center items-center h-[calc(100vh-300px)] bg-white">
      <ScaleLoader color="#0077B6" loading={true} size={20} speedMultiplier={0.7} />
      <span>Loading data...</span>
    </div>
  );
}
