import moment from 'moment/moment';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Modal } from '@mantine/core';
import { Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
// Import files
import * as api from '../../redux/api';
import BtnAdd from '../../components/general/BtnAdd';
import LoadingPage from '../../components/general/LoadingPage';

import Table from '../../components/general/Table';
import ModalPromoDetail from '../../components/informasi-promo/ModalPromoDetail';

const parse = require('html-react-parser');

export default function InformasiPromo() {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [promoDetail, setPromoDetail] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.showPromo().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(({ kategori_name, content, title, date, perusahaan_name, client_name }) => {
          if (
            debouncedQuery !== '' &&
            !` ${kategori_name} ${content} ${title} ${date}${perusahaan_name}${client_name} `.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        })
      );
    }
  }, [data, debouncedQuery]);

  const handleImageData = ({ image }) => {
    setImageData(image?.split('/data-informasi/')[1] ? image : require('../../images/logo.png'));
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (id) => {
    setPromoDetail(id);
    setOpenModalDetail(true);
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: 'Do you want to delete this information?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deletePromo(id)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'code',
      width: 100,
      cellsStyle: {
        verticalAlign: 'center'
      },
      render: ({ code, id }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(id)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/promo/update/${id}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(id)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Gambar',
      accessor: 'image',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ image }) => (
        <div className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80" onClick={() => handleImageData({ image })}>
          {image?.split('/data-informasi/')[1] ? (
            <img src={image} alt="" className="h-full object-contain rounded" />
          ) : (
            <img src={require('../../images/logo.png')} alt="" className="h-full object-contain rounded" />
          )}
        </div>
      )
    },
    {
      title: 'Tanggal, Status',
      accessor: 'status',
      width: 180,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ date, status }) => (
        <div>
          <div className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer">{moment(date).format('DD MMM YYYY')}</div>
          {status === 1 ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">Aktif</div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">Tidak Aktif</div>
          )}
        </div>
      )
    },
    {
      title: 'Perusahaan',
      accessor: 'perusahaan_name',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 180
    },
    {
      title: 'Klien',
      accessor: 'client_name',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 180
    },
    {
      title: 'Kategori',
      accessor: 'kategori_name',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Judul',
      accessor: 'title',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Konten',
      accessor: 'content',
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ content }) => <>{content !== '' && content !== null && parse(content?.toString())}</>,
      width: 300
    },
    {
      title: 'Tautan',
      accessor: 'link',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    }
  ];

  if (data && data[0] === '') {
    return <LoadingPage />;
  }

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <BtnAdd path="/promo/create" />
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data informasi.</div>
            </div>
          )}
        </div>
        {/* Modal Detail  */}
        <ModalPromoDetail id={promoDetail} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />

        {/* Modal Image */}
        <Modal centered size="md" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && <img src={imageData} alt="" className="w-full object-contain rounded-t-lg flex items-center justify-center" />}
        </Modal>
      </div>
    </div>
  );
}
