import React, { useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import { ClipLoader } from 'react-spinners';
import InputMultipleMail from './InputMultipleMail';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';

export default function ModalSendLaporan({ htmlString, openModalSend, setOpenModalSend, type, startDate, endDate }) {
  const [emails, setEmails] = useState(['']);
  const [subject, setSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (emails.length === 0) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [emails, subject]);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const form = { email: emails, type: type, star_date: startDate, end_date: endDate };
    // console.log(form);
    setLoading(true);
    await api
      .sendEmailLaporan({
        email: emails,
        type: type,
        start_date: startDate,
        end_date: endDate
      })
      .then(() => {
        setLoading(false);
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setEmails([]);
        setSubject('');
        setOpenModalSend(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err.message);
      });
  };

  return (
    <Modal
      opened={openModalSend}
      size="lg"
      centered
      closeOnClickOutside
      onClose={() => {
        setEmails([]);
        setOpenModalSend(false);
      }}>
      <div className="w-full flex justify-center items-center text-center text-lg font-semibold">Kirim Laporan</div>
      <div className="w-full">
        <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} className="w-full flex flex-col p-5">
          <div className="flex flex-col mb-3">
            <label htmlFor="email" className="block font-semibold mb-1 md:mb-0">
              Email Tujuan
            </label>
            <InputMultipleMail emails={emails} setEmails={setEmails} />
            <p className="text-sm">Tekan spasi atau enter untuk menambahkan email</p>
          </div>
          {/* <div className="flex flex-col mb-3">
            <label htmlFor="subject" className="block font-semibold mb-1 md:mb-0">
              Subject
            </label>
            <input type="text" value={subject} id="subject" onChange={(e) => setSubject(e.target.value)} className="w-full inputForm" placeholder="Subjek email" />
          </div> */}

          <div className="flex justify-center items-center w-full gap-5 mt-10">
            <button
              type="submit"
              className={`py-2 px-5 rounded-md text-sm text-gray-50 ${submitDisabled ? 'bg-customGray' : 'bg-dark'} hover:opacity-80`}
              disabled={submitDisabled}>
              {loading ? <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} /> : 'Kirim'}
            </button>

            <div
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80 cursor-pointer"
              onClick={() => {
                setEmails([]);
                setSubject('');
                setOpenModalSend(false);
              }}>
              Kembali
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
