// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Tooltip } from '@mantine/core';
import { Popover, Modal } from '@mantine/core';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import ModalKeluhanDetail from '../../components/keluhan/ModalKeluhanDetail';
import ModalKeluhanCreate from '../../components/keluhan/ModalKeluhanCreate';
import ModalKeluhanUpdate from '../../components/keluhan/ModalKeluhanUpdate';
import { useDebouncedValue } from '@mantine/hooks';

export default function KeluhanData() {
  const [data, setData] = useState([]);
  const [dataKategori, setDataKategori] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalKeluhanCreate, setOpenModalKeluhanCreate] = useState(false);
  const [openModalKeluhanUpdate, setOpenModalKeluhanUpdate] = useState(false);
  const [keluhanDetailKode, setKeluhanDetailKode] = useState('');
  const [imageData, setImageData] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.keluhanData().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    const getDataKategori = async () => {
      await api.kategoriKeluhanData().then((res) => {
        setDataKategori(res.data.data);
      });
    };
    getDataKategori();
    getData();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(({ satpamkeluhantanggal, satpamkeluhankategorinama, satpamkeluhankode, satpamkeluhanisi }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamkeluhantanggal} ${satpamkeluhankategorinama} ${satpamkeluhankode} ${satpamkeluhanisi} `.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        })
      );
    }
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (kode) => {
    setKeluhanDetailKode(kode);
    setOpenModalDetail(true);
  };

  const handleOpenModalUpdate = (kode) => {
    setKeluhanDetailKode(kode);
    setOpenModalKeluhanUpdate(true);
  };
  const handleImageData = ({ satpamkeluhangambar }) => {
    setImageData(satpamkeluhangambar || '/not-found.jpeg');
    setShowImage(!showImage);
  };

  const handleDelete = (kode) => {
    Swal.fire({
      text: 'Do you want to delete this Keluhan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .keluhanDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkeluhankode',
      width: 100,
      render: ({ satpamkeluhankode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={90} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(satpamkeluhankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              {/* <div size="sm" className="popOverItem" onClick={() => handleOpenModalUpdate(satpamkeluhankode)}>
                <i className="fa-solid fa-pen"></i> Update
              </div>
              <div className="popOverItem" onClick={() => handleDelete(satpamkeluhankode)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div> */}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },

    {
      title: 'Tanggal',
      accessor: 'satpamkeluhantanggal',
      width: 180,

      render: ({ satpamkeluhantanggal }) => (
        <div>
          <div className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer">{moment(satpamkeluhantanggal).format('DD MMM YYYY HH:mm')}</div>
        </div>
      )
    },
    {
      title: 'Status',
      accessor: 'satpamkeluhanstatus',
      width: 120,
      render: ({ satpamkeluhanstatus }) => (
        <div>
          <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">
            {' '}
            {satpamkeluhanstatus === 'Close' ? 'Selesai' : satpamkeluhanstatus}
          </div>
        </div>
      )
    },
    {
      title: 'Gambar',
      accessor: 'satpamkeluhangambar',
      width: 150,
      render: ({ satpamkeluhangambar }) => (
        <div>
          <img
            src={satpamkeluhangambar}
            alt="image"
            width={120}
            height={120}
            onClick={() => handleImageData({ satpamkeluhangambar })}
            className="rounded-md h-full object-contain rounded-lg"
            onError={(e) => (e.target.onerror = null)((e.target.src = '/not-found.jpg'))}
          />
        </div>
      )
    },
    {
      title: 'Isi',
      accessor: 'satpamkeluhanisi',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                {/* <Tooltip label="Tambah Keluhan">
                  <div
                    className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80"
                    onClick={() => setOpenModalKeluhanCreate(true)}>
                    <AiOutlineFileAdd size={24} />
                  </div>
                </Tooltip> */}
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data Keluhan.</div>
            </div>
          )}
        </div>
        {/* Modal Detail Keluhan */}
        <ModalKeluhanDetail kode={keluhanDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} refreshData={getData} />
        {/* Modal Create Keluhan */}
        <ModalKeluhanCreate getData={getData} data={dataKategori} openModalKeluhanCreate={openModalKeluhanCreate} setOpenModalKeluhanCreate={setOpenModalKeluhanCreate} />
        {/* Modal Update Keluhan */}
        <ModalKeluhanUpdate
          kode={keluhanDetailKode}
          getData={getData}
          data={dataKategori}
          openModalKeluhanUpdate={openModalKeluhanUpdate}
          setOpenModalKeluhanUpdate={setOpenModalKeluhanUpdate}
        />
        {/* Modal Image */}
        <Modal centered size="md" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && <img src={imageData} alt="" className="w-full object-contain rounded-t-lg flex items-center justify-center" />}
        </Modal>
      </div>
    </div>
  );
}
