import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as api from '../../redux/api.js';
import UserDropdown from '../dropdowns/UserDropdown';

export default function Navbar({ profile, handleLogout }) {
  const [childMenuData, setChildMenuData] = useState([]);

  const location = useLocation();

  const [activeMainMenu, setActiveMainMenu] = useState({
    adminmenuutama: 'Dashboard',
    adminmenulink: '/'
  });

  useEffect(() => {
    const getChildMenu = async () => {
      await api.childMenu().then((res) => {
        setChildMenuData(res.data.data);
      });
    };
    getChildMenu();
  }, []);

  useEffect(() => {
    const filteredChildMenu = childMenuData?.filter((childmenu) => childmenu.adminmenulink === location.pathname);

    location.pathname === '/'
      ? setActiveMainMenu({
          adminmenuutama: 'Dashboard',
          adminmenulink: '/'
        })
      : filteredChildMenu.map((childmenu) => setActiveMainMenu(childmenu));
  }, [childMenuData, location]);

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          {activeMainMenu !== '' && (
            <Link to={activeMainMenu.adminmenulink} className="text-white text-lg uppercase hidden md:inline-block font-bold">
              {activeMainMenu.adminmenuutama}
            </Link>
          )}
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown profile={profile} handleLogout={handleLogout} />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
      {/* Header */}
      <div className="relative bg-primary md:pt-3 sm:h-20 md:h-28 lg:h-64 pb-0 pt-3 mb-4">{/* eslint-disable-next-line jsx-a11y/alt-text */}</div>
    </>
  );
}
