import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Modal, Popover, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
// Import files
import * as api from '../../redux/api';
import ModalPetugasChangePassword from '../../components/petugas/ModalPetugasChangePassword';
import ModalPetugasDetail from '../../components/petugas/ModalPetugasDetail';
import Table from '../../components/general/Table';

export default function PetugasData() {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [kodePerusahaan, setKodePerusahaan] = useState('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalPassword, setOpenModalPassword] = useState(false);
  const [petugasDetailKode, setPetugasDetailKode] = useState('');
  const [query, setQuery] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const [perusahaanOptions, setPerusahaanOptions] = useState([{ value: '', label: 'Pilih perusahaan' }]);

  // Get data
  const getAllPetugasByCompany = async (kodeperusahaan) => {
    await api.getAllPetugasByCompany(kodeperusahaan).then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    const getAllPerusahaan = async () => {
      await api.getAllPerusahaan().then((res) =>
        res.data.Data.map((data) => {
          return setPerusahaanOptions((prev) => [...prev, { value: data.satpamperusahaankode, label: data.satpamperusahaannama }]);
        })
      );
    };
    getAllPerusahaan();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(({ adminpetugasjabatan, adminpetugasnamalengkap, adminpetugasusername }) => {
        if (
          debouncedQuery !== '' &&
          !`${adminpetugasjabatan} ${adminpetugasnamalengkap} ${adminpetugasusername}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      })
    );
  }, [data, debouncedQuery]);

  useEffect(() => {
    if (kodePerusahaan) getAllPetugasByCompany(kodePerusahaan);
  }, [kodePerusahaan]);

  const handleImageData = (params) => {
    setImageData(params.data.adminpetugasfoto?.split('/data-admin/')[1] ? params.data.adminpetugasfoto : require('../../images/satpam.jpg'));
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (petugaskode) => {
    setPetugasDetailKode(petugaskode);
    setOpenModalDetail(true);
  };

  const handleOpenModalPassword = (petugaskode) => {
    setPetugasDetailKode(petugaskode);
    setOpenModalPassword(true);
  };

  const handleDelete = (kodepetugas) => {
    Swal.fire({
      text: 'Apakah anda ingin menghapus data ini?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deletePetugas(kodepetugas)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getAllPetugasByCompany(kodePerusahaan);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'adminpetugaskode',
      width: 90,
      render: ({ adminpetugaskode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width="auto" position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div size="sm" className="popOverItem" onClick={() => handleOpenModalDetail(adminpetugaskode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/petugas/update/${adminpetugaskode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleOpenModalPassword(adminpetugaskode)}>
                <i className="fa-solid fa-key"></i> Change Password
              </div>
              <div className="popOverItem" onClick={() => handleDelete(adminpetugaskode)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto petugas',
      accessor: 'adminpetugasfoto',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ adminpetugasfoto }) => (
        <div className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80" onClick={() => handleImageData({ adminpetugasfoto })}>
          {adminpetugasfoto?.split('/data-admin/')[1] ? (
            <img src={adminpetugasfoto} alt="" className="h-full object-contain rounded" />
          ) : (
            <img src={require('../../images/satpam.jpg')} alt="" className="h-full object-contain rounded" />
          )}
        </div>
      )
    },
    {
      title: 'Kode dan Status',
      accessor: 'adminpetugasstatus',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ adminpetugaskode, adminpetugasstatus }) => (
        <div>
          <div className="py-0 my-0 mb-2">{adminpetugaskode} &nbsp;</div>
          {adminpetugasstatus === 'Aktif' ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">Aktif</div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">Tidak Aktif</div>
          )}
        </div>
      )
    },
    {
      title: 'Jabatan',
      accessor: 'adminpetugasjabatan',

      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Nama Petugas',
      accessor: 'adminpetugasnamalengkap',

      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Username',
      accessor: 'adminpetugasusername',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    }
  ];

  return (
    <div className="relative px-4 md:px-10 pb-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full">
        <div className="w-full justify-center bg-gray-50 min-h-screen rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-between items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
              <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
                <div className="font-semibold text-xs mr-2 mb-1 md:mb-0">Filter perusahaan</div>
                <div className="md:flex md:flex-row gap-2 z-3">
                  <Select
                    placeholder="Pilih perusahaan"
                    options={perusahaanOptions}
                    className="w-full text-xs"
                    isSearchable={true}
                    defaultValue={kodePerusahaan}
                    value={perusahaanOptions?.filter((option) => option.value === kodePerusahaan)}
                    name="kodePerusahaan"
                    onChange={(e) => setKodePerusahaan(e.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Tambah Petugas">
                  <Link to="/petugas/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length <= 0 ? (
            <div className="text-center">
              <div className="mt-10">Tidak ada data petugas.</div>
              <div>Silahkan pilih perusahaan terlebih dahulu.</div>
            </div>
          ) : (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          )}
        </div>
        {/* Modal Detail Petugas */}
        <ModalPetugasDetail kode={petugasDetailKode} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
        {/* Modal Reset Password */}
        <ModalPetugasChangePassword kode={petugasDetailKode} openModalPassword={openModalPassword} setOpenModalPassword={setOpenModalPassword} />

        {/* Modal Image */}
        <Modal centered size="md" opened={showImage} onClose={() => setShowImage(false)} className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && <img src={imageData} alt="" className="w-full object-contain rounded-t-lg flex items-center justify-center" />}
        </Modal>
      </div>
    </div>
  );
}
