import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';

export default function DivisiCreate() {
  const [formData, setFormData] = useState({ nama: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.createDivisi(formData);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Divisi created successfully!'
      });

      setFormData({
        nama: ''
      });
    } catch (error) {
      console.error('Error creating divisi:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to create divisi. Please try again.'
      });
    }
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">Tambah Divisi</div>
        <form onSubmit={HandleSubmit} className="flex flex-col justify-between  mt-5 md:mt-7">
          <div className="bg-gray-50 rounded-md  p-2 md:p-5 shadowAll flex gap-5 w-full flex-col">
            <div className="flex flex-col md:flex-row gap-x-2">
              <p className="my-auto w-full">Masukan Divisi</p>
              <input name="nama" type="text" className="p-2 w-full border rounded-md outline-none" value={formData.nama} onChange={handleInputChange} required />
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg mt-5">
            <button type="submit" className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80 capitalize">
              simpan
            </button>

            <Link to="/personil/divisi">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">Kembali</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
