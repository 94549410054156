import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';

const ModalSatpamDetail = ({ id, openModalDetail, setOpenModalDetail }) => {
  const [dataSatpam, setDataSatpam] = useState({});
  const [satpamKode, setSatpamKode] = useState('');
  const [satpamMenuMaster, setSatpamMenuMaster] = useState([]);
  const [satpamMenu, setSatpamMenu] = useState('');

  useEffect(() => {
    if (id !== '') {
      const getDataSatpamById = async () => {
        await api.satpamById(id).then((res) => {
          setDataSatpam(res.data.data[0]);
          setSatpamKode(res.data.data[0].satpamkode);
        });
      };

      const getSatpamMenuMaster = async () => {
        await api.satpamMenuMaster().then((res) => {
          setSatpamMenuMaster(res.data.data);
        });
      };

      getSatpamMenuMaster();
      getDataSatpamById();
    }
  }, [id]);

  useEffect(() => {
    if (satpamKode !== '') {
      const getMenuSatpam = async () => {
        await api
          .satpamMenu(satpamKode)
          .then((res) => {
            setSatpamMenu(res.data.data);
          })
          .catch((err) => {
            console.error(err.response.data.message);
          });
      };

      getMenuSatpam();
    }
  }, [satpamKode]);

  return (
    <Modal opened={openModalDetail} withCloseButton size="auto" onClose={() => setOpenModalDetail(false)}>
      <div className="text-dark scrollbar-thin px-5">
        <div className="flex flex-col justify-center items-center">
          <h3 className="font-bold text-center mb-5">Personil Detail</h3>
          <div className="flex justify-center">
            {dataSatpam.satpamfoto !== null && dataSatpam.satpamfoto !== '' ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/assets/upload/data-foto/${dataSatpam.satpamfoto}`}
                alt=""
                className="w-[150px] h-[150px] object-cover mb-5 rounded-xl"
              />
            ) : (
              <img src={require('../../images/satpam.jpg')} alt="" className="w-[150px] h-[150px] object-cover mb-5 rounded-xl" />
            )}
          </div>
          <div className="flex gap-10 text-sm">
            <div>
              <p className="font-semibold">NIP</p>
              <p className="font-semibold">Nama</p>
              <p className="font-semibold">Nomor hp</p>
              <p className="font-semibold">Email</p>
              {/* <p className="font-semibold">Kode satpam</p> */}
              <p className="font-semibold">Divisi</p>
              <p className="font-semibold">Jabatan</p>
              {/* <p className="font-semibold">Tipe satpam</p> */}
              <p className="font-semibold">Jenis kelamin</p>
              <p className="font-semibold">Status</p>
              {/* <p className="font-semibold">Tanggal masuk</p> */}
              <p className="font-semibold">Menu mobile</p>
            </div>
            <div>
              <p>: {dataSatpam?.satpamnip ? dataSatpam?.satpamnip : '-'}</p>
              <p>: {dataSatpam?.satpamnamalengkap}</p>
              <p>: {dataSatpam?.satpamnohp}</p>
              <p>: {dataSatpam?.satpamemail}</p>
              <p>: {dataSatpam?.divisi_name ? dataSatpam?.divisi_name : '-'}</p>
              <p>: {dataSatpam?.satpamjabatan}</p>
              {/* <p>: {dataSatpam?.satpamtipe ? dataSatpam?.satpamtipe : '-'}</p> */}
              <p>: {dataSatpam?.satpamjeniskelamin ? dataSatpam?.satpamjeniskelamin : '-'}</p>
              <p>: {dataSatpam?.satpamstatus}</p>
              {/* <p>: {dataSatpam?.satpamtanggalmasuk ? dataSatpam?.satpamtanggalmasuk : '-'}</p> */}

              {satpamMenu.length > 0
                ? satpamMenuMaster?.map((menumaster) => (
                    <div key={menumaster.satpammenukode} className="flex flex-row w-full text-sm">
                      <div className="flex gap-2 pr-2">
                        <input
                          type="checkbox"
                          id={menumaster.satpammenukode}
                          value={menumaster.satpammenukode}
                          checked={satpamMenu.filter((menu) => menu.satpammenukode === menumaster.satpammenukode).length > 0 ? true : false}
                        />
                        <label htmlFor={menumaster.satpammenukode} className=" cursor-pointer">
                          {menumaster.satpammenunama?.replace('<br>', ' ')}
                        </label>
                      </div>
                    </div>
                  ))
                : satpamMenuMaster?.map((menumaster) => (
                    <div key={menumaster.satpammenukode} className="flex flex-row flex-wrap p-1.5 text-sm">
                      <div className="flex gap-2 pr-2">
                        <input type="checkbox" id={menumaster.satpammenukode} value={menumaster.satpammenukode} />
                        <label htmlFor={menumaster.satpammenukode} className=" cursor-pointer">
                          {menumaster.satpammenunama?.replace('<br>', ' ')}
                        </label>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <div className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer" onClick={() => setOpenModalDetail(false)}>
            Kembali
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSatpamDetail;
