import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import { Modal } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { izinSatpamSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';
import imageCompression from 'browser-image-compression';

export default function ModalCreateIzin({
  getDataIzin,
  openModalCreate,
  setOpenModalCreate,
  setDetailKode,
  setOpenModalDetail
}) {
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [jadwalDataOptions, setJadwalDataOptions] = useState([]);
  const [jadwalKode, setJadwalKode] = useState('');
  const [tanggalIzin, setTanggalIzin] = useState(moment().format('YYYY-MM-DD HH:mm'));

  const [errorMsg, setErrorMsg] = useState({ bulan: '', personil: '', jadwal: '', tanggal: '' });
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(izinSatpamSchema)
  });

  const [fileDataURL] = useImagePreview(watch('satpamizinfoto'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((res) => {
        res.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [
            ...prev,
            { value: data.satpamkode, label: data.satpamnamalengkap }
          ]);
        });
      });
    };
    getDataSatpam();
  }, []);

  useEffect(() => {
    setJadwalDataOptions([]);
    const formData = {
      bulan: month,
      satpamjadwalsatpamkode: satpamKode
    };

    if (satpamKode !== '' && satpamKode !== '') {
      const getDataJadwal = async (formData) => {
        await api.jadwalDataByPersonil(formData).then((res) => {
          if (res.data.status) {
            res.data.data
              .filter((datafilter) => datafilter.satpamjadwalstatus === '0')
              .map((data) => {
                return setJadwalDataOptions((prev) => [
                  ...prev,
                  {
                    value: data.satpamjadwalkode,
                    label:
                      moment(data.satpamjadwalmasuktanggal).format('DD MMM YYYY HH:ss') +
                      ' - ' +
                      moment(data.satpamjadwalkeluartanggal).format('HH:ss')
                  }
                ]);
              });
          }
        });
      };
      getDataJadwal(formData);
    }
  }, [month, satpamKode]);

  useEffect(() => {
    setErrorMsg((prev) => ({ ...prev, bulan: '' }));
  }, [month]);

  useEffect(() => {
    setErrorMsg((prev) => ({ ...prev, personil: '' }));
  }, [satpamKode]);

  useEffect(() => {
    setErrorMsg((prev) => ({ ...prev, jadwal: '' }));
  }, [jadwalKode]);

  useEffect(() => {
    setErrorMsg((prev) => ({ ...prev, tanggal: '' }));
  }, [tanggalIzin]);

  const onSubmit = async (data) => {
    setLoading(true);
    data.satpamizinsatpamkode = satpamKode;
    data.satpamizinsatpamjadwalkode = jadwalKode;
    data.satpamizintanggal = tanggalIzin;
    data.satpamizinstatus = '0';

    const formData = new FormData();

    if (data.satpamizinfoto.length > 0) {
      const compressedFile = await imageCompression(data.satpamizinfoto[0], compressOptions);
      formData.append('satpamizinfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    if (month === '') {
      setLoading(false);
      setErrorMsg((prev) => ({ ...prev, bulan: 'Bulan harus dipilih' }));
    } else if (satpamKode === '') {
      setLoading(false);
      setErrorMsg((prev) => ({ ...prev, personil: 'Personil harus diisi' }));
    } else if (jadwalKode === '') {
      setLoading(false);
      setErrorMsg((prev) => ({ ...prev, jadwal: 'Jadwal harus diisi' }));
    } else if (tanggalIzin === '') {
      setLoading(false);
      setErrorMsg((prev) => ({ ...prev, tanggal: 'Tanggal harus diisi' }));
    } else {
      setErrorMsg({ bulan: '', personil: '', jadwal: '', tanggal: '' });
      await api
        .createIzin(formData)
        .then((res) => {
          if (res.data.status) {
            Swal.fire('Good job!', 'You successfully submit the form!', 'success');
            reset();
            reset({ satpamizinfoto: '' });
            setOpenModalCreate(false);
            getDataIzin();
            setTanggalIzin(moment().format('YYYY-MM-DD HH:mm'));
            setSatpamKode('');
            setMonth([]);
            setJadwalKode('');
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.message === 'Data Izin sudah terdaftar') {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              showDenyButton: true,
              confirmButtonText: 'Lihat status',
              denyButtonText: `Tutup`,
              text: 'Sudah pernah mengajukan izin pada tanggal ini'
            }).then((result) => {
              if (result.isConfirmed) {
                setDetailKode(error.response.data.Data[0].satpamizinkode);
                setOpenModalDetail(true);
                setOpenModalCreate(false);
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message
            });
          }
        });
    }
  };

  return (
    <Modal
      opened={openModalCreate}
      size="xl"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalCreate(false)}>
      <div className="w-full flex justify-center items-center text-center text-lg font-semibold">
        Ajukan Izin
      </div>
      <div className="w-full">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col p-5">
          <div className="md:flex items-center mb-3">
            <div className="md:w-1/3">
              <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                Pilih bulan
              </label>
            </div>
            <div className="md:w-2/3">
              <div className="relative block">
                <DatePicker
                  className="w-full inputForm cursor-pointer"
                  value={month}
                  onChange={(date) => setMonth(moment(date).format('YYYY-MM'))}
                  placeholderText="YYYY-MM"
                  dateFormat="YYYY-MM"
                  showMonthYearPicker
                />
                <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                  <i className="far fa-calendar"></i>
                </div>
              </div>
              <p className="text-errorText text-xs">{errorMsg?.bulan}</p>
            </div>
          </div>
          <div className="md:flex items-center mb-3">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Pilih personil</label>
            </div>
            <div className="md:w-2/3">
              <Select
                options={satpamDataOptions}
                className={`w-full ${errorMsg?.jadwal === '' ? '' : 'invalidForm'} cursor-pointer`}
                placeholder="Pilih personil"
                isSearchable={true}
                value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                defaultValue={satpamKode}
                required
                onChange={(e) => setSatpamKode(e.value)}
              />
              <p className="text-errorText text-xs">{errorMsg?.personil}</p>
            </div>
          </div>
          <div className="md:flex items-center mb-3">
            <div className="md:w-1/3">
              <label className="block font-semibold mb-1 md:mb-0">Pilih jadwal</label>
            </div>
            <div className="md:w-2/3">
              <Select
                options={jadwalDataOptions}
                className={`w-full ${errorMsg?.jadwal === '' ? '' : 'invalidForm'} cursor-pointer`}
                placeholder="Pilih jadwal"
                value={jadwalDataOptions.filter((option) => option.value === jadwalKode)}
                defaultValue={jadwalKode}
                onChange={(e) => setJadwalKode(e.value)}
                required
              />
              <p className="text-errorText text-xs">{errorMsg?.jadwal}</p>
            </div>
          </div>
          <div className="md:flex items-center mb-3">
            <div className="md:w-1/3">
              <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                Tanggal Pengajuan
              </label>
            </div>
            <div className="md:w-2/3">
              <div className="relative block">
                <DatePicker
                  locale={id}
                  className={`w-full ${
                    errorMsg?.tanggal === '' ? 'inputForm' : 'invalidForm'
                  } cursor-pointer`}
                  value={tanggalIzin}
                  onChange={(date) => setTanggalIzin(moment(date).format('YYYY-MM-DD HH:mm'))}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="YYYY-MM-DD HH:mm"
                  showTimeInput
                />
                <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                  <i className="far fa-calendar"></i>
                </div>
                <p className="text-errorText text-xs">{errorMsg?.tanggal}</p>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-3">
            <div className="md:w-1/3">
              <label htmlFor="jenis" className="block font-semibold mb-1 md:mb-0">
                Jenis
              </label>
            </div>
            <div className="md:w-2/3">
              <select
                className={`w-full ${errors.satpamizinjenis ? 'invalidForm' : 'inputForm'}`}
                {...register('satpamizinjenis')}>
                <option value="">Pilih jenis</option>
                <option value="Izin">Izin</option>
                <option value="Sakit">Sakit</option>
              </select>
              <p className="text-errorText text-xs">{errors.satpamizinjenis?.message}</p>
            </div>
          </div>
          <div className="md:flex mb-3">
            <div className="md:w-1/3">
              <label htmlFor="keterangan" className="block font-semibold mb-1 md:mb-0">
                Keterangan
              </label>
            </div>
            <div className="md:w-2/3">
              <textarea
                rows="2"
                className={`w-full ${errors.satpamizinketerangan ? 'invalidForm' : 'inputForm'}`}
                {...register('satpamizinketerangan')}
              />
              <p className="text-errorText text-xs">{errors.satpamizinketerangan?.message}</p>
            </div>
          </div>
          <div className="md:flex mb-3">
            <div className="md:w-1/3">
              <label htmlFor="foto" className="block font-semibold mb-1 md:mb-0">
                Foto
              </label>
            </div>
            <div className="md:w-2/3">
              <div className={`flex w-full ${errors.satpamizinketerangan ? 'invalidForm' : ''}`}>
                <div className="w-full">
                  <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                    <div className="flex flex-col w-full">
                      <label className="p-2 flex flex-col w-full h-36 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                        {fileDataURL ? (
                          <div className="flex flex-col items-center justify-center">
                            <img
                              src={fileDataURL}
                              className="w-full h-32 object-contain"
                              alt="preview"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col items-center justify-center pt-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                              viewBox="0 0 20 20"
                              fill="currentColor">
                              <path
                                fillRule="evenodd"
                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                              Select a photo
                            </p>
                          </div>
                        )}
                        <input
                          type="file"
                          className="opacity-0"
                          {...register('satpamizinfoto')}
                          accept="image/*"
                        />
                      </label>
                    </div>
                    <p className="text-errorText text-xs">{errors.satpamizinfoto?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center w-full gap-5 mt-10">
            <button
              type="submit"
              className={`py-2 px-5 rounded-md text-sm text-gray-50 bg-dark hover:opacity-80`}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <div
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80 cursor-pointer"
              onClick={() => {
                setTanggalIzin(moment().format('YYYY-MM-DD HH:mm'));
                setSatpamKode('');
                setMonth([]);
                setJadwalKode('');
                reset();
                reset({ satpamizinfoto: '' });
                setOpenModalCreate(false);
              }}>
              Kembali
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
